import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import {
  Fab, CircularProgress, FormControl, FormLabel, FormGroup, FormControlLabel, FormHelperText, Checkbox, Grid
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field, ErrorMessage
} from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete } from 'formik-material-ui-lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
// TODO: Implement @react-br-forms/cpf-cnpj-mask

import WrapperSimple from '../../layout-components/ExampleWrapperSimple';
import { Create as Values } from '../../models/interfaces/customer';
import UseStyles from '../../components/styles/loadingButton';
import { setAddressByCEP } from '../../services/geolocateService';
import { CepMask, PhoneMask } from '../../components/mask';
import Dictionary from '../../models/interfaces/dictionary';
import subsidiaryService from '../../services/subsidiaryService';
import customerService from '../../services/customerService';
import { getErrorMessage } from '../../config/firebase';
import { CategoryDriverList } from '../../models/enums/categoryDriver';
import { PaymentTypeList } from '../../models/enums/paymentType';
import { useAuth } from '../../contexts/auth';

export default function page() {
  const history = useHistory();
  const classes = UseStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const [subsidiaries, setSubsidiaries] = React.useState<Dictionary[]>();
  const { subsidiaryId } = useAuth();

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  useEffect(() => {
    if (!subsidiaryId) {
      // TODO: Implement infinite scroll
      subsidiaryService.getByPaginationAsync(1, 200).then((result) => {
        if (!result.data.length) throw new Error('Nenhuma filial encontrada');

        setSubsidiaries(result.data.map((x: any) => ({ key: x.id, value: x.description })));
      });
    }
  }, []);

  const onBlurCep = (ev: any, setFieldValue: any): void => {
    setAddressByCEP(ev, setFieldValue).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'warning' });
    });
  };

  return (
    <WrapperSimple sectionHeading="Novo cliente">
      <Formik
        initialValues={{
          name: '',
          businessName: '',
          email: '',
          registryNumber: '',
          responsible: '',
          contract: '',
          subsidiary: null,
          address: {
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: null,
            ibge: null,
            location: null
          },
          phoneNumber: '',
          categories: [],
          paymentTypes: [],
          observationsToDriver: '',
          observations: ''
        }}
        validationSchema={Yup.object().shape({
          subsidiary: Yup.lazy(() => {
            if (subsidiaryId) return Yup.object().nullable();
            return Yup.object().nullable().required();
          }),
          name: Yup.string().required(),
          businessName: Yup.string().required(),
          observations: Yup.string(),
          observationsToDriver: Yup.string(),
          email: Yup.string().email().required(),
          registryNumber: Yup.string().required(),
          responsible: Yup.string().required(),
          contract: Yup.string().required(),
          phoneNumber: Yup.string().required()
            .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Telefone inválido'),
          categories: Yup.array().min(1, 'É necessário escolher pelo menos uma opção'),
          paymentTypes: Yup.array().min(1, 'É necessário escolher pelo menos uma opção'),
          address: Yup.object().shape({
            zipCode: Yup.string().required(),
            street: Yup.string().required(),
            neighborhood: Yup.string().required(),
            city: Yup.string().required(),
            number: Yup.string().required()
          })
        })}
        onSubmit={async (values: Values, actions) => {
          // TODO: Deixar somente adicionar as categorias que a subsidiaria tem tariffa
          const subId = subsidiaryId ?? values.subsidiary?.key;
          await customerService.create(values, subId).then(() => {
            setSuccess(true);
            history.push('/companies');
          }).catch((e) => {
            setSuccess(false);
            enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<Values>) => {
          const {
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;

          return (
            <Form noValidate autoComplete="off">
              <Field type="hidden" name="address.ibge" value="" />
              <Grid container spacing={3}>
                {subsidiaries?.length && (
                  <Grid item xs={12} sm={12} md={4}>
                    <Field
                      name="subsidiary"
                      component={Autocomplete}
                      options={subsidiaries}
                      getOptionLabel={(option: Dictionary) => option.value}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          variant="outlined"
                          label="Filial"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoFocus
                          error={Boolean(errors.subsidiary && touched.subsidiary)}
                          helperText={errors.subsidiary && touched.subsidiary && errors.subsidiary}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={subsidiaries?.length ? 4 : 8}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Nome fantasia"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.name && touched.name)}
                    helperText={errors.name && touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Razão social"
                    name="businessName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.businessName && touched.businessName)}
                    helperText={errors.businessName && touched.businessName && errors.businessName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Nome do responsável"
                    name="responsible"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.responsible && touched.responsible)}
                    helperText={errors.responsible && touched.responsible && errors.responsible}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Número do contrato"
                    name="contract"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.contract && touched.contract)}
                    helperText={errors.contract && touched.contract && errors.contract}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="CNPJ/CPF"
                    name="registryNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.registryNumber && touched.registryNumber)}
                    helperText={errors.registryNumber && touched.registryNumber && errors.registryNumber}
                    inputProps={{ maxLength: 14 }}
                  // InputProps={{ inputComponent: CnpjMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.email && touched.email)}
                    helperText={errors.email && touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="CEP"
                    name="address.zipCode"
                    onChange={handleChange}
                    onBlur={(e: any) => onBlurCep(e, setFieldValue)}
                    error={Boolean(errors.address?.zipCode && touched.address?.zipCode)}
                    helperText={errors.address?.zipCode && touched.address?.zipCode && errors.address?.zipCode}
                    InputProps={{ inputComponent: CepMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Endereço"
                    name="address.street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.street && touched.address?.street)}
                    helperText={errors.address?.street && touched.address?.street && errors.address?.street}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Bairro"
                    name="address.neighborhood"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.neighborhood && touched.address?.neighborhood)}
                    helperText={errors.address?.neighborhood && touched.address?.neighborhood && errors.address?.neighborhood}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Cidade"
                    name="address.city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.city && touched.address?.city)}
                    helperText={errors.address?.city && touched.address?.city && errors.address?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Nº"
                    name="address.number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.number && touched.address?.number)}
                    helperText={errors.address?.number && touched.address?.number && errors.address?.number}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    label="Complemento"
                    name="address.complement"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.complement && touched.address?.complement)}
                    helperText={errors.address?.complement && touched.address?.complement && errors.address?.complement}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Telefone"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                    helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                    InputProps={{ inputComponent: PhoneMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl required error={Boolean(errors.categories && touched.categories)} component="fieldset">
                    <FormLabel component="legend">Categorias</FormLabel>
                    <FormGroup>
                      {_.sortBy(CategoryDriverList, [(x) => x.value]).map((item: Dictionary) => (
                        <FormControlLabel
                          key={item.key}
                          value={item.key}
                          control={<Checkbox onChange={handleChange} name="categories" />}
                          label={item.value}
                        />
                      ))}
                    </FormGroup>
                    <FormHelperText>
                      <ErrorMessage name="categories" className="invalid-feedback" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <FormControl required error={Boolean(errors.paymentTypes && touched.paymentTypes)} component="fieldset">
                    <FormLabel component="legend">Tipos de pagamentos</FormLabel>
                    <FormGroup>
                      {_.sortBy(PaymentTypeList, [(x) => x.value]).map((item: Dictionary) => (
                        <FormControlLabel
                          key={item.key}
                          value={item.key}
                          control={<Checkbox onChange={handleChange} name="paymentTypes" />}
                          label={item.value}
                        />
                      ))}
                    </FormGroup>
                    <FormHelperText>
                      <ErrorMessage name="paymentTypes" className="invalid-feedback" />
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Observações para o condutor"
                    name="observationsToDriver"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.observationsToDriver && touched.observationsToDriver)}
                    helperText={errors.observationsToDriver && touched.observationsToDriver && errors.observationsToDriver}
                    multiline
                    rows="4"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Informações adicionais"
                    name="observations"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.observations && touched.observations)}
                    helperText={errors.observations && touched.observations && errors.observations}
                    multiline
                    rows="4"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
