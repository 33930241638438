export default {

  capitalize(text: string) {
    if (typeof text !== 'string') return '';
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  },

  capitalizeFrase(text: string) {
    let fullText: string = '';
    if (!text) return fullText;
    const parts = text.split(' ');
    parts.forEach((part) => {
      if (part.length > 2 && fullText.length === 0) {
        fullText = this.capitalize(part);
      } else if (part.length > 2) {
        fullText = `${fullText} ${this.capitalize(part)}`;
      } else if (part.length === 2 && !part.includes('de') && !part.includes('da')) {
        fullText = `${fullText} ${this.capitalize(part)}`;
      } else {
        fullText = `${fullText} ${part.toLowerCase()}`;
      }
    });
    return fullText;
  },

  removeAccent(str: string) {
    const has = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
    const hasno = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
    let newStr = '';
    for (let i = 0; i < str.length; i += 1) {
      let swap = false;
      for (let a = 0; a < has.length; a += 1) {
        if (str.substr(i, 1) === has.substr(a, 1)) {
          newStr += hasno.substr(a, 1);
          swap = true;
          break;
        }
      }
      if (swap === false) {
        newStr += str.substr(i, 1);
      }
    }
    return newStr;
  },

  replaceAll(str: string, find: string, replace: string) {
    if (str) return str.replace(new RegExp(find, 'g'), replace);
    return '';
  },

  isNumber(value: any | null) {
    if (value?.length === 0) return false;
    // console.log(typeof (value));
    // eslint-disable-next-line no-restricted-globals
    return !isNaN(value);
  },

  isPhoneNumber(value: string | null) {
    if (!this.isNumber(value)) return false;
    return value?.length === 10 || value?.length === 11;
  }
};
