/* eslint-disable no-unused-vars */
import React from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { format } from 'date-fns';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

interface NumberFormatCustomProps {
  inputRef: (instance: any | null) => void;
  onChange: (event: { target: { name: string; value: any } }) => void;
  name: string;
}

const phoneNumberMask = ['+', /\d/, /\d/, ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const phoneNumberMaskNoDDI = [/[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const cpfFormatMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const cnpjFormatMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const zipCodeFormatMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export function phoneNumberNoDDI(value: string | undefined | null) {
  if (value?.startsWith('0800')) return value;

  const newValue = value?.replace('+55', '');

  return conformToMask(
    newValue ?? '',
    phoneNumberMaskNoDDI,
    { guide: false }
  ).conformedValue;
}

export function phoneNumber(value: string | undefined | null) {
  if (value?.startsWith('0800')) return value;

  return conformToMask(
    value ?? '',
    phoneNumberMask,
    { guide: false }
  ).conformedValue;
}

export function cpfOrCnpj(value: string | undefined) {
  return conformToMask(
    value ?? '',
    value?.length === 11 ? cpfFormatMask : cnpjFormatMask,
    { guide: false }
  ).conformedValue;
}

export function zipCode(value: string | undefined) {
  return conformToMask(
    value ?? '',
    zipCodeFormatMask,
    { guide: false }
  ).conformedValue;
}

export function PhoneMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => { inputRef(ref ? ref.inputElement : null); }}
      mask={phoneNumberMask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

export function CepMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => { inputRef(ref ? ref.inputElement : null); }}
      mask={zipCodeFormatMask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

export function CpfMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => { inputRef(ref ? ref.inputElement : null); }}
      mask={cpfFormatMask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

export function CnpjMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => { inputRef(ref ? ref.inputElement : null); }}
      mask={cnpjFormatMask}
      placeholderChar={'\u2000'}
      showMask={false}
    />
  );
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        const { name } = props;
        const { floatValue } = values;

        onChange({ target: { name, value: floatValue } });
      }}
      thousandSeparator="."
      prefix="R$ "
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator=","
    />
  );
}

export function toBrazilDate(date: Date | undefined | null) {
  if (!date) return '';
  return format(date, 'dd/MM/yyyy');
}

export function toBrazilTime(date: Date | undefined | null) {
  if (!date) return '';
  return format(date, 'HH:mm:ss');
}

export function toBrazilDateTime(date: Date | undefined | null) {
  if (!date) return '';
  return format(date, 'dd/MM/yyyy HH:mm:ss');
}
