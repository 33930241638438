import React from 'react';
import { Card, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import processImg from '../assets/images/illustrations/process.svg';

interface OwnProps {
  message: string;
}

export default function page(props: OwnProps) {
  return (
    <Grid item xs={12}>
      <Card className="card-box p-4 mb-4">
        <div className="d-flex justify-content-between">
          <div className="pr-5">
            <div className="bg-first text-center text-white font-size-xl d-50 rounded-circle mb-4 mt-1">
              <FontAwesomeIcon icon={['far', 'bell']} />
            </div>
            <h4 className="font-size-xl font-weight-bold mb-2">
              {props.message}
            </h4>
          </div>
          <div className="w-25 d-flex align-items-center">
            <img alt="" className="d-block img-fluid" src={processImg} />
          </div>
        </div>
      </Card>
    </Grid>
  );
}
