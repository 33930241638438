import React, { useCallback, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';
import { Avatar, Skeleton } from '@mui/material';

import Table from '../../components/table';
import customerService from '../../services/customerService';
import { useAuth } from '../../contexts/auth';
import { phoneNumberNoDDI } from '../../components/mask';
import subsidiaryService from '../../services/subsidiaryService';

interface OwnProps {
  subsidiaryId: string;
  title: string;
}

export default function page(props: OwnProps) {
  const history = useHistory();
  const [total, setTotal] = React.useState<number>(0);
  const [items, setItems] = React.useState<any>([]);
  const [enableSearch, setEnableSearch] = React.useState<boolean>(false);

  let { subsidiaryId } = useAuth();
  const subsidiaryFilter = subsidiaryId ? `subsidiary:${subsidiaryId}` : null;
  if (props.subsidiaryId) subsidiaryId = props.subsidiaryId;

  const [itemsPag, setItemsPag] = React.useState<any>([]);
  const [current, setCurrent] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [currentTotal, setCurrentTotal] = React.useState<number>(0);
  const [isStart, setIsStart] = React.useState<boolean | undefined>();
  const [isEnd, setIsEnd] = React.useState<boolean | undefined>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const searchCustomers = async (userSearching: string, limit: number, filters: string | null = null, subsidiaries: any | null) => {
    const params = userSearching?.length ? userSearching : '';
    const resp = await customerService.search(params, limit, currentPage, filters, subsidiaries);
    return resp;
  };

  const getPrev = async () => {
    if (!isStart) {
      const newCurrentPage = (currentPage - 1);
      setCurrentPage(newCurrentPage);
      setList(subsidiaryFilter, 20, null);
    }
  };

  const getNext = async () => {
    if (!isEnd) {
      const newCurrentPage = (currentPage + 1);
      setCurrentPage(newCurrentPage);
      setList(subsidiaryFilter, 20, null);
    }
  };

  // eslint-disable-next-line consistent-return
  const getList = async (filters: string | null = null, limitPerPage: number, subsidiaries: any) => {
    try {
      const resp: any = await searchCustomers('', limitPerPage, filters, subsidiaries);
      const customers = resp.items;
      setIsLoading(false);
      return {
        pagination: resp,
        customers
      };
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  const setList = async (filters: string | null = null, limitPerPage: number, subsidiaries: any) => {
    // setIsLoading(true);
    try {
      const subs = subsidiaries ?? null;
      const resp = await getList(filters, limitPerPage, subs);
      setCurrent(((resp?.pagination?.hitsPerPage * (resp?.pagination?.currentPage + 1)) - (resp?.pagination?.hitsPerPage - 1)));
      setCurrentTotal(resp?.pagination?.hitsPerPage * (resp?.pagination?.currentPage + 1));
      setIsStart(resp?.pagination?.isStart);
      setIsEnd(resp?.pagination?.isEnd);
      setTotal(resp?.pagination?.totalHits);
      setItemsPag(resp?.customers);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    subsidiaryService.getCities().then(async (subsidiaries: any) => {
      setList(subsidiaryFilter, 20, subsidiaries).then(() => {
        setIsLoading(false);
      });
    });
  }, [isLoading]);

  const searchItem = useCallback(async (params: string) => {
    setIsLoading(false);
    if (params?.length < 4) return;
    const subsidiaries = await subsidiaryService.getCities();
    searchCustomers(params, 300, subsidiaryFilter, subsidiaries).then(async (resp: any) => {
      const customers = resp.items;
      setItems(customers);
      setTotal(resp.totalHits);
      setEnableSearch(true);
      setIsLoading(false);
    });
  }, []);

  const columns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'photoURL',
      options: { display: false }
    },
    {
      name: 'address',
      options: { display: false }
    },
    {
      name: 'email',
      options: { display: false }
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                <Avatar alt="" className="mr-2" src={tableMeta.rowData[1]}>
                  {value.substr(0, 1)}
                </Avatar>
                <div>
                  {ReactHtmlParser(value)}
                  {/* <span className="text-black-50 d-block">
                    {ReactHtmlParser(tableMeta.rowData[2]) }
                  </span> */}
                  <span className="text-black-50 d-block">
                    {ReactHtmlParser(subsidiaryId === undefined ? tableMeta.rowData[7] : null)}
                  </span>
                </div>
              </div>
            );
          }
          return <Skeleton />;
        }
      }
    },
    {
      name: 'phoneNumber',
      label: 'Celular/Email',
      options: {
        filter: false,
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div>
                {ReactHtmlParser(phoneNumberNoDDI(value))}
                <span className="text-black-50 d-block">
                  {ReactHtmlParser(tableMeta.rowData[3])}
                </span>
              </div>
            );
          }
          return (
            <div className="text-dark">Celular não cadastrado</div>
          );
        }
      }
    },
    {
      name: 'createdAt',
      label: 'Data de cadastro',
      options: {
        customBodyRender: (value: Date) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                {value.toLocaleString('pt-BR')}
              </div>
            );
          }
          return <Skeleton />;
        }
      }
    },
    {
      name: 'subsidiaryName',
      label: 'Franquia',
      options: { display: false, filter: false }
    }
  ];

  const onRowClick = (rowData: string[]) => {
    const id = rowData[0];
    if (id) {
      history.push(`/companies/${id}`);
    }
  };

  const onTableChange = useCallback((action: string, state: any) => {
    if (action === 'search') {
      if (state.searchText !== null) {
        searchItem(state.searchText);
      }
    }

    if (action === 'onSearchClose') {
      setEnableSearch(false);
      subsidiaryService.getCities().then(async (subsidiaries: any) => {
        setList(subsidiaryFilter, 20, subsidiaries).then(() => {
          setIsLoading(false);
        });
      });
      // getTotal();
    }
  }, []);

  return (
    <Table
      title={props.title ?? 'Clientes'}
      createUrl="companies/create"
      data={enableSearch ? items : itemsPag}
      columns={columns}
      filter={false}
      serverSide
      isLoading={isLoading}
      onRowClick={onRowClick}
      onTableChange={onTableChange}
      pagination={false}
      getPrev={getPrev}
      getNext={getNext}
      current={current}
      currentTotal={currentTotal}
      isStart={isStart}
      isEnd={isEnd}
      enableSearch={enableSearch}
      count={total}
    />
  );
}
