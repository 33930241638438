/* eslint-disable no-unused-vars */
import { EnumDictionary } from '../interfaces/enumDictionary';
import Dictionary from '../interfaces/dictionary';

// eslint-disable-next-line no-shadow
enum StatusType {
  Active = 1,
  Inactive = 0,
  Schedule = 2
}

const StatusTypeText: EnumDictionary<StatusType, string> = {
  [StatusType.Active]: 'Ativado',
  [StatusType.Inactive]: 'Desativado',
  [StatusType.Schedule]: 'Agendado'
};

const StatusTypeList: Dictionary[] = [
  { key: 1, value: 'Ativado' },
  { key: 0, value: 'Desativado' },
  { key: 2, value: 'Agendado' }
];

const ActiveBoleanList: Dictionary[] = [
  { key: true, value: 'Sim' },
  { key: false, value: 'Não' }
];

export {
  StatusType, StatusTypeText, StatusTypeList, ActiveBoleanList
};
