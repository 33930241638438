import _ from 'lodash';
import { validate as uuidValidate } from 'uuid';

import { getCountFromServer } from 'firebase/firestore';
import {
  doc, getDateNow, getDoc, getDocs, query, tariffCollection, updateDoc, where, addDoc, subsidiaryCollection
} from '../config/firebase';
import CalculationMethod from '../models/enums/calculationMethod';
import { CreateOrUpdate } from '../models/interfaces/tariff';
import { titleCase } from '../utils/stringHelper';
import customerService from './customerService';
import subsidiaryService from './subsidiaryService';

export default {
  async getAllAsync(subId: string | undefined | null) {
    let subsidiaryId = subId;
    if (subsidiaryId && uuidValidate(subsidiaryId)) {
      const newSub = await subsidiaryService.getByIdAsync(subsidiaryId);
      const subsidiariesByCNPJ = await getDocs(query(subsidiaryCollection, where('cnpj', '==', newSub.cnpj)));
      if (subsidiariesByCNPJ.size === 1) {
        subsidiaryId = subsidiariesByCNPJ.docs[0].id;
      }
    }
    let customQuery = query(tariffCollection, where('subsidiary', '!=', null));
    if (subsidiaryId) {
      customQuery = query(tariffCollection, where('subsidiary', '==', subsidiaryId));
    }

    return getDocs(customQuery).then(async (querySnapshot) => {
      let result: any[] = [];
      const promisses = querySnapshot.docs.map(async (snap) => {
        const {
          company, description, disabledAt, categoryDriver, calculationMethod
        } = snap.data();
        let desc = description;
        if (company) {
          // TODO: Remove this because it's too slow
          const companyObj = await customerService.getById(company);
          desc = `${description} / ${companyObj.name}`;
        }

        return {
          id: snap.id,
          description: desc,
          disabledAt: disabledAt?.toDate(),
          categoryDriver,
          calculationMethod
        };
      });

      await Promise.all(promisses).then((resultDocs) => {
        result = resultDocs;
      });

      return _.sortBy(result, [(x) => x.description.toLowerCase()]);
    });
  },

  async getById(id: string) {
    const result = await getDoc(doc(tariffCollection, id));
    if (!result.exists()) throw new Error('Tariff not found');

    const model = result.data();
    if (model?.company) {
      model.company = await customerService.getById(model.company);
    }
    return model;
  },

  async getAllCount(id: string | null | undefined) {
    const subsidiaryId = id;
    let total;
    const q = query(tariffCollection);
    const c = query(q);

    if (subsidiaryId) {
      total = await getCountFromServer(query(c, where('subsidiary', '==', subsidiaryId)));
    } else {
      total = await getCountFromServer(c);
    }

    return { total: total?.data().count };
  },

  async createAsync(model: CreateOrUpdate, subId: string, pCustomerId: string | null) {
    let subsidiaryId = subId;
    let customerId = pCustomerId;
    if (customerId === '') customerId = null;
    this.validFields(model);

    if (uuidValidate(subsidiaryId)) {
      const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
      const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
      if (!snap.empty) {
        subsidiaryId = snap.docs[0].id;
      }
    }

    const q = query(tariffCollection,
      where('subsidiary', '==', subsidiaryId),
      where('categoryDriver', '==', model.categoryDriver),
      where('calculationMethod', '==', model.calculationMethod),
      where('company', '==', customerId));

    const checkEqualTariff = await getDocs(q);
    if (!checkEqualTariff.empty) {
      throw new Error('Já existe uma tarifa com essa categoria.');
    }
    let customer = null;
    if (customerId) {
      customer = await customerService.getById(customerId);
    }

    const newDoc = {
      ...model,
      description: titleCase(model.description),
      subsidiary: subsidiaryId,
      company: customerId,
      companyName: customer?.businessName ? customer?.businessName : null,
      createdAt: getDateNow()
    };

    return addDoc(tariffCollection, newDoc);
  },

  async update(model: CreateOrUpdate, id: string) {
    this.validFields(model);

    const modelRef = doc(tariffCollection, id);
    return updateDoc(modelRef, {
      ...model,
      description: titleCase(model.description)
    });
  },

  async changeStatus(id: string, isDisabled: boolean) {
    const model = await this.getById(id);
    if (!model) throw new Error('Tariff not found');

    const modelRef = doc(tariffCollection, id);
    return updateDoc(modelRef, {
      disabledAt: isDisabled ? getDateNow() : null
    });
  },

  validFields(pModel: CreateOrUpdate) {
    const model = pModel;
    if (model.calculationMethod === CalculationMethod.MileageStopped || model.calculationMethod === CalculationMethod.Ifood) {
      model.valuePerAdditionalStop = 0;
      model.valueFlag = 0;
      model.valueByKm = 0;
      model.valueByStop = 0;
      model.valueMinimum = 0;

      if (!model.fixedValues.every((x) => x.value !== undefined && x.valueBack !== undefined)) {
        throw new Error('É necessário informar o valor para todas as distâncias.');
      }
    } else {
      model.fixedValues = [];

      let hasError = false;
      if (model.valuePerAdditionalStop === undefined) hasError = true;
      else if (model.valueFlag === undefined) hasError = true;
      else if (model.valueByKm === undefined) hasError = true;
      else if (model.valueByStop === undefined) hasError = true;
      else if (model.valueMinimum === undefined) hasError = true;

      if (hasError) throw new Error('Todos os campos são obrigatórios');
    }
  }
};
