import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Deliveries from '../../deliveries';
import DeliveriesReport from '../../deliveries/report';
import Map from '../../dashboard/map';
import DeliveriesMap from '../../deliveries/map';

const Dashboard = lazy(() => import('../../dashboard'));
const Chart = lazy(() => import('../../dashboard/chart'));
const Profile = lazy(() => import('../../auth/profile'));

const ManagerRoutes = () => (
  <Switch>
    <Route exact path="/profile" component={Profile} />

    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/chart" component={Chart} />
    <Route exact path="/deliveries" component={Deliveries} />
    <Route exact path="/deliveries-report" component={DeliveriesReport} />
    <Route exact path="/map" component={Map} />

    <Route exact path="/deliveries/:id/map" component={DeliveriesMap} />
  </Switch>
);

export default ManagerRoutes;
