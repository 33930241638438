/* eslint-disable no-unused-vars */
import { EnumDictionary } from '../interfaces/enumDictionary';
import Dictionary from '../interfaces/dictionary';

// eslint-disable-next-line no-shadow
enum DynamicType {
  FixedValue = 0,
  Multiplier = 1
}

const DynamicTypeText: EnumDictionary<DynamicType, string> = {
  [DynamicType.FixedValue]: 'Valor Fixo',
  [DynamicType.Multiplier]: 'Multiplicador'
};

const DynamicTypeList: Dictionary[] = [
  { key: 0, value: 'Valor Fixo' },
  { key: 1, value: 'Multiplicador' }
];

export { DynamicType, DynamicTypeText, DynamicTypeList };
