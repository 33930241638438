import React, { useContext, useEffect, createContext } from 'react';
import _ from 'lodash';

import UserAuth from '../models/userAuth';
import subsidiaryService from '../services/subsidiaryService';
import userService from '../services/userService';
import authService from '../services/authService';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from '../config/firebase';
import IUserAuth from '../models/interfaces/userAuth';

export interface SubsidiaryDropdown {
  key: string | null;
  guid: string | null;
  value: any;
}
export type ContextProps = {
  user: UserAuth | null;
  isLoading: boolean;
  subsidiaryId: string | null;
  subsidiaryGuid: string | null;
  subsidiaries: SubsidiaryDropdown[];
  isReadOnly: boolean;
  setSubsidiary: any;
  signIn: any;
};

const AuthContext = createContext<Partial<ContextProps>>({});

export function useAuth() {
  return useContext(AuthContext);
}

const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [user, setUser] = React.useState<UserAuth | null>();
  const [subsidiaryId, setSubsidiaryId] = React.useState<string | null>();
  const [subsidiaryGuid, setSubsidiaryGuid] = React.useState<string | null>();
  const [subsidiaries, setSubsidiaries] = React.useState<SubsidiaryDropdown[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isReadOnly, setIsReadOnly] = React.useState(false);
  const usersReadOnly = ['qnGbiC4rtXW5iQ0sfNoL3LXc1KF2', 'RyvwxliSkgTIMTP95NNSPFEltM62']; // Id do usuário com permissão somente de leitura

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async (userAuth) => {
      if (userAuth === null) {
        setUser(null);
        setSubsidiaries([]);
        setSubsidiary(null, null);
        setIsLoading(false);
        return;
      }

      if (!userAuth.email) throw new Error('Email is null');
      const uuser = await userService.getByIdAsync(userAuth.uid);
      if (uuser === null) return;

      if (uuser.claims?.admin) {
        setSubsidiary(undefined, undefined);
      } else if (uuser.claims?.manager) {
        const subs = await subsidiaryService.getByOwnerId(userAuth.uid);
        setSubsidiary(undefined, undefined);
        if (subs.length) {
          const subsDictionary = _.sortBy(subs.map((x) => ({ key: x.id, guid: x.id, value: x.data().description })), [(x) => x.value.toLowerCase()]);

          // Se for usuário somente leitura
          if (usersReadOnly.includes(uuser.uid)) {
            setIsReadOnly(true);
            subsDictionary.unshift({ key: null, guid: null, value: 'Administrativo' });
          }

          setSubsidiaries(subsDictionary);
          if (subsDictionary.length === 1) {
            setSubsidiary(subsDictionary[0].key, subsDictionary[0].guid);
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('Gerente sem filial vinculada');
          await authService.signOut();
          throw new Error('Gerente sem filial vinculada');
        }
      } else {
        if (!uuser.subsidiaryId) throw new Error('User not allowed');

        const subs = await subsidiaryService.getByIdAsync(uuser.subsidiaryId);
        setSubsidiary(subs.uid, uuser.subsidiaryId);
      }

      const customUserAuth: IUserAuth = {
        address: uuser.address,
        claims: uuser.claims,
        disabled: uuser.disabled,
        displayName: uuser.displayName,
        email: uuser.email,
        emailVerified: uuser.emailVerified,
        metadata: {
          creationTime: uuser.metadata.creationTime.toString(),
          lastSignInTime: uuser.metadata.lastSignInTime?.toString() ?? null
        },
        name: uuser.name,
        phoneNumber: uuser.phoneNumber,
        photoURL: uuser.photoURL,
        uid: uuser.uid
      };
      setUser(new UserAuth(userAuth, customUserAuth));
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async (email: string, password: string) => signInWithEmailAndPassword(getAuth(), email, password)
    .then(async (firebaseResult) => {
      const currentUser = await userService.getByIdAsync(firebaseResult.user?.uid!);
      if (currentUser) {
        const subsidiaryByUser = currentUser?.subsidiaryId;
        if (subsidiaryByUser) {
          const subsidiary = await subsidiaryService.getByIdAsync(subsidiaryByUser);
          if (subsidiary?.disabledAt) {
            await authService.signOut();
            throw new Error('Filial está inativa');
          }
        }
      } else {
        await authService.signOut();
        throw new Error('Usuário sem permissão');
      }
    });

  const setSubsidiary = (uid: string | undefined | null, guid: string | undefined | null) => {
    setSubsidiaryId(uid);
    setSubsidiaryGuid(guid);
  };

  return (
    <AuthContext.Provider value={{
      user,
      isLoading,
      subsidiaryId,
      subsidiaryGuid,
      subsidiaries,
      isReadOnly,
      setSubsidiary,
      signIn
    }}
    >
      {!isLoading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
