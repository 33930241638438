import React from 'react';
import clsx from 'clsx';
import { connect, ConnectedProps } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { SnackbarProvider } from 'notistack';

import { Sidebar, Header, Footer } from '../../layout-components';
import { SubsidiaryDropdown, useAuth } from '../../contexts/auth';
import SimpleDialog from './subsidiariesDialog';

const mapState = (state: any) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,

  showSnackbar: state.Admin.showSnackbar,
  messageSnackbar: state.Admin.messageSnackbar
});

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  children: any
};

const LeftSidebar = (props: Props) => {
  const {
    subsidiaryId, setSubsidiary, subsidiaries, isLoading
  } = useAuth();

  const handleClose = (item: SubsidiaryDropdown) => { setSubsidiary(item.key, item.guid); };

  const {
    children,
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground
  } = props;

  if (isLoading) {
    return (
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ScaleLoader color="var(--primary)" loading />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Carregando suas informações...
        </div>
      </div>
    );
  }

  if (subsidiaries!.length > 1 && subsidiaryId === undefined) {
    return (<SimpleDialog open items={subsidiaries} onClose={handleClose} />);
  }

  return (
    <div className={clsx('app-wrapper', contentBackground)}>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} hideIconVariant={false}>
        <Header />
        <div className={clsx('app-main', { 'app-main-sidebar-static': !sidebarFixed })}>
          <Sidebar selectSubsidiary={handleClose} />
          <div className={clsx('app-content', {
            'app-content-sidebar-collapsed': sidebarToggle,
            'app-content-sidebar-fixed': sidebarFixed,
            'app-content-footer-fixed': footerFixed
          })}
          >
            <div className="app-content--inner">
              <div className="app-content--inner__wrapper">
                {children}
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </SnackbarProvider>
    </div>
  );
};

export default connector(LeftSidebar);
