/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import {
  MapContainer, TileLayer, Marker
} from 'react-leaflet';
// import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';

import { useAuth } from '../../contexts/auth';
// import customerService from '../../services/customerService';
import subsidiaryService from '../../services/subsidiaryService';
import Loading from '../../components/loading';
// import customerMarker from '../../assets/images/customer-marker.png';
import subsidiaryMarker from '../../assets/images/subsidiary-marker.png';
import subsidiaryMarkerInactive from '../../assets/images/subsidiary-marker-inactive.png';

interface Row {
  id: string;
  description: string;
  html: string;
  active: boolean;
  lat: number | null;
  lng: number | null;
  photoURL: string;
  phoneNumber: string | null;
}
const Page = () => {
  const { subsidiaryId } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);
  const [subsidiaries, setSubsidiaries] = React.useState<Row[]>([]);
  // const [customers, setCustomers] = React.useState<Row[]>([]);
  const [zoom, setZoom] = React.useState(4.5);
  const [center, setCenter] = React.useState({ lat: -11.840096772124648, lng: -51.95084271385832 });

  // const customerIcon = L.icon({
  //   iconUrl: customerMarker,
  //   iconSize: [32, 32],
  //   iconAnchor: [15, 31],
  //   popupAnchor: [0, -35]
  // });
  const subsidiaryIcon = L.icon({
    iconUrl: subsidiaryMarker,
    iconSize: [64, 64],
    iconAnchor: [31, 63]
  });

  const subsidiaryIconInactive = L.icon({
    iconUrl: subsidiaryMarkerInactive,
    iconSize: [64, 64],
    iconAnchor: [31, 63]
  });

  const getSubsidiaries = () => {
    let result;
    if (subsidiaryId) {
      result = subsidiaryService.getByIdAsync(subsidiaryId!).then((data) => {
        if (data.address.location) {
          const { latitude, longitude } = data.address.location;
          setCenter({ lat: latitude, lng: longitude });
          setSubsidiaries([{
            id: data.id,
            description: data.description,
            html: '',
            active: true,
            lat: latitude,
            lng: longitude,
            photoURL: '',
            phoneNumber: data.phoneNumber
          }]);

          setZoom(13);
        }
      });
    } else {
      // TODO: Implement infinite scroll
      result = subsidiaryService.getByPaginationAsync(1, 200).then((subs) => {
        const actives: Row[] = subs.data.filter((x: any) => x.active).map((w) => ({
          id: w.id,
          description: w.description,
          html: '',
          active: w.active,
          lat: w.lat,
          lng: w.lng,
          photoURL: '',
          phoneNumber: null
        }));
        setSubsidiaries(actives);
      });
    }

    return result;
  };

  // const getCustomers = async () => customerService.getAll(subsidiaryId).then((customersList) => {
  //   if (!customersList.length) {
  //     setCustomers([]);
  //     return;
  //   }

  //   const result = customersList.map((item: any) => ({
  //     id: item.id,
  //     description: item.displayName ?? '?',
  //     html: '',
  //     active: !item.disabled,
  //     lat: item.address?.location?.latitude ?? null,
  //     lng: item.address?.location?.longitude ?? null,
  //     photoURL: item.photoURL,
  //     phoneNumber: item.phoneNumber
  //   }));
  //   setCustomers(result.filter((x: any) => x.active && x.lat && x.lng));
  // });

  useEffect(() => {
    Promise.all([getSubsidiaries()]).then(() => setIsLoading(false));
  }, []);

  return (
    <Card className="card-box mb-4">
      <div className="card-header py-3">
        <div className="card-header--title font-size-lg">
          Localizações
        </div>
      </div>
      <div className="w-100" style={{ height: '600px' }}>
        {isLoading ? <Loading />
          : (
            <MapContainer center={center} scrollWheelZoom={false} zoom={zoom} style={{ height: '600px' }}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {subsidiaries.map((s: Row) => (
                <Marker
                  key={s.id}
                  position={{ lat: s.lat ?? 0, lng: s.lng ?? 0 }}
                  icon={s.active === false ? subsidiaryIconInactive : subsidiaryIcon}
                  title="Filial"
                />
              ))}

              {/* <MarkerClusterGroup>
                {customers.map((x: Row) => (
                  <Marker
                    key={x.id}
                    position={{ lat: x.lat ?? 0, lng: x.lng ?? 0 }}
                    icon={customerIcon}
                    title={x.description}
                  >
                    <Popup>
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper avatar-icon-lg  mr-2">
                          <div className="avatar-icon">
                            <img alt={x.phoneNumber ?? ''} src={x.photoURL} />
                          </div>
                        </div>
                        <div>
                          <span className="font-weight-bold text-black" title="">
                            {x.description}
                          </span>
                          <span className="text-black-50 d-block">
                            <div dangerouslySetInnerHTML={{ __html: x.html }} />
                          </span>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </MarkerClusterGroup> */}
            </MapContainer>
          )}
      </div>
    </Card>
  );
};

export default Page;
