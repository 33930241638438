import React from 'react';
import { Card, CardContent } from '@mui/material';

const WrapperSimple = (props: any) => {
  const { sectionHeading, children } = props;

  return (
    <Card className="card-box mb-4-spacing overflow-visible">
      <div className="card-header">
        <div className="card-header--title font-size-md font-weight-bold py-2">
          {sectionHeading}
        </div>
      </div>
      <CardContent className="p-3">{children}</CardContent>
    </Card>
  );
};

export default WrapperSimple;
