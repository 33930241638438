import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconButton, Box, Tooltip } from '@mui/material';
import { connect } from 'react-redux';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import { setSidebarToggleMobile as setSidebarToggleMobileByOptions } from '../../reducers/themeOptions';

import logoCircle from '../../assets/images/logo-icon.png';

const SidebarHeader = (props: any) => {
  const toggleSidebar = () => { setSidebarToggle(!sidebarToggle); };
  const toggleSidebarMobile = () => { setSidebarToggleMobile(!sidebarToggleMobile); };

  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    sidebarHover
  } = props;

  return (
    <div className={clsx('app-sidebar-header', { 'app-sidebar-header-close': sidebarToggle && !sidebarHover })}>
      <Box className="header-logo-wrapper" title={process.env.REACT_APP_ADMIN_NAME}>
        <Link to="/dashboard" className="header-logo-wrapper-link">
          <IconButton color="primary" size="medium" className="header-logo-wrapper-btn">
            <img className="app-sidebar-logo" alt={process.env.REACT_APP_ADMIN_NAME} src={logoCircle} />
          </IconButton>
        </Link>
        <Box className="header-logo-text">{process.env.REACT_APP_ADMIN_SHORT_NAME}</Box>
      </Box>
      <Box className={clsx('app-sidebar-header-btn', { 'app-sidebar-header-btn-close': sidebarToggle && !sidebarHover })}>
        <Tooltip title="Toggle Sidebar" placement="right">
          <IconButton color="inherit" onClick={toggleSidebar} size="medium">
            {sidebarToggle ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="app-sidebar-header-btn-mobile">
        <Tooltip title="Toggle Sidebar" placement="right">
          <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
            {sidebarToggleMobile ? (<MenuOpenRoundedIcon />) : (<MenuRoundedIcon />)}
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: any) => dispatch(setSidebarToggleMobileByOptions(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
