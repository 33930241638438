import React from 'react';
import {
  Grid, Dialog, Button, Card, CardContent, Box, Typography,
  Tabs, Tab, Fab, DialogTitle, IconButton, useMediaQuery, Link
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Info, Chat, Timeline, Room, Close, Done
} from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatDistanceStrict, isAfter, subDays } from 'date-fns';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';

import Call, { Notification, History, HistoryBy } from '../../../models/interfaces/call';
import { phoneNumber, toBrazilDateTime } from '../../../components/mask';
import { PaymentTypeText } from '../../../models/enums/paymentType';
import { DeviceTypeText } from '../../../models/enums/deviceType';
import { CategoryDriverText } from '../../../models/enums/categoryDriver';
import callService, { statusAllowedToCompleteCall } from '../../../services/callService';
import DriversModalComponent from './driversModalComponent';

import avatarUrl from '../../../assets/images/avatar.png';
import ifoodIcon from '../../../assets/images/ifood.png';
import anotaaiIcon from '../../../assets/images/anota-ai.png';

import { useAuth } from '../../../contexts/auth';

export interface OwnProps {
  open: boolean;
  data: Call | undefined;
  owner: HistoryBy;
  toggle: () => void;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

// TODO: Add color circular to photo
// http://localhost:3001/carolina-react-admin-dashboard-pro/Cards10

export default function component(props: OwnProps) {
  const { user } = useAuth();
  if (props.data === undefined) return <></>;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();

  const ERRORMESSAGE = 'Ops, aconteceu algo inesperado, por favor tente novamente';

  const TabPanel = (propsT: TabPanelProps) => {
    const {
      children, value, index, ...other
    } = propsT;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleChat = (messages: Notification[] | undefined) => {
    if (messages === undefined || messages.length === 0) return <>Nenhuma informação encontrada.</>;

    const colors = ['bg-primary', 'bg-second', 'bg-first', 'bg-second', 'bg-info'];
    const rowsColors = _.uniq(messages.map((x) => x.senderKey)).map((x, i) => ({ id: x, color: colors[i] }));
    const result = messages.map((x: any) => {
      const item = _.find(rowsColors, (w: any) => w.id === x.senderKey);
      return {
        ...x,
        color: item !== undefined ? item.color : 'bg-dark',
        senderPhotoURL: x.senderPhotoURL ?? avatarUrl
      };
    });

    return (
      <PerfectScrollbar className="chat-wrapper">
        {result.map((x) => (
          <div className="chat-item p-2 mb-2" key={x.createdAt.getTime()}>
            <div className="align-box-row">
              <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
                <div className="avatar-icon rounded border-0">
                  <img alt="" src={x.senderPhotoURL} />
                </div>
              </div>
              <div>
                <div className={`chat-box ${x.color} text-white`}>
                  <p>
                    {x.sender}
                    :
                  </p>
                  <p>{x.message}</p>
                </div>
                <small className="mt-2 d-block text-black-50">
                  <FontAwesomeIcon icon={['far', 'clock']} className="mr-1 opacity-5" />
                  {toBrazilDateTime(x.createdAt)}
                </small>
              </div>
            </div>
          </div>
        ))}

        {/* <div className="chat-item chat-item-reverse p-2 mb-2">
              <div className="align-box-row flex-row-reverse">
                <div className="avatar-icon-wrapper avatar-icon-lg align-self-start">
                  <div className="avatar-icon rounded border-0">
                    <img alt="" src={avatar3} />
                  </div>
                </div>
                <div>
                  <div className="chat-box bg-first text-white">
                    <p>Hey, Kate.</p>
                  </div>
                  <small className="mt-2 d-block text-black-50">
                    <FontAwesomeIcon icon={['far', 'clock']} className="mr-1 opacity-5" />
                    11:01 AM | Yesterday
                  </small>
                </div>
              </div>
            </div> */}
      </PerfectScrollbar>
    );
  };

  const getTimeDifference = (history: History[]) => {
    const count = history.length;
    return formatDistanceStrict(history[count - 2].date, history[count - 1].date);
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-prevent-tab-${index}`,
      'aria-controls': `scrollable-prevent-tabpanel-${index}`
    };
  }

  const cancelCall = async (id: string) => {
    callService.changeStatus(id, props.owner, 'canceled', user?.claims?.admin ? 8 : 1).then(() => {
      enqueueSnackbar('Entrega cancelada com sucesso', { variant: 'success' });
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    }).finally(() => {
      toggleConfirmCancelModal();
      props.toggle();
    });
  };
  const completeCall = async (id: string) => {
    await callService.changeStatus(id, props.owner, 'complete', 1).then(() => {
      enqueueSnackbar('Entrega finalizada com sucesso', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar(ERRORMESSAGE, { variant: 'error' });
    }).finally(() => {
      toggleConfirmCompleteModal();
      props.toggle();
    });
  };

  const BootstrapDialogTitle = (propsD: DialogTitleProps) => {
    const { children, onClose, ...other } = propsD;

    return (
      <DialogTitle {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (themeX) => themeX.palette.grey[500]
            }}
          >
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const [confirmCancelModal, setConfirmCancelModal] = React.useState(false);
  const [confirmCompleteModal, setConfirmCompleteModal] = React.useState(false);
  const toggleConfirmCancelModal = () => setConfirmCancelModal(!confirmCancelModal);
  const toggleConfirmCompleteModal = () => {
    setConfirmCompleteModal(!confirmCompleteModal);
  };
  const dropClick = () => {
    console.log('dropClick');
  };

  const calculateDistance = (list: any[]) => {
    const distanceTotal = _.sumBy(list, (x) => x.distance) / 1000;

    return distanceTotal.toFixed(2);
  };

  return (
    <>
      <Dialog open={confirmCancelModal} onClose={toggleConfirmCancelModal}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3" />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Tem certeza de que deseja cancelar?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            Você não pode desfazer esta operação.
          </p>
          <div className="pt-4">
            <Button onClick={toggleConfirmCancelModal} variant="outlined" color="secondary" className="mx-1">
              <span className="btn-wrapper--label">Não</span>
            </Button>
            <Button onClick={() => cancelCall(props.data!.id)} color="primary" variant="contained" className="mx-1">
              <span className="btn-wrapper--label">Sim</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={confirmCompleteModal} onClose={toggleConfirmCompleteModal} onBackdropClick={dropClick}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-success text-success m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'check']} className="d-flex align-self-center display-3" />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">
            Tem certeza de que deseja finalizar este pedido?
          </h4>
          <p className="mb-0 font-size-lg text-muted">
            Você não pode desfazer esta operação.
          </p>
          <div className="pt-4">
            <Button onClick={toggleConfirmCompleteModal} variant="outlined" color="secondary" className="mx-1">
              <span className="btn-wrapper--label">Não</span>
            </Button>
            <Button onClick={() => completeCall(props.data!.id)} color="primary" variant="contained" className="mx-1">
              <span className="btn-wrapper--label">Sim</span>
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        fullWidth
        scroll="body"
        maxWidth="sm"
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.toggle}
        aria-labelledby="responsive-dialog-title"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={props.toggle}>
          Detalhes da entrega
        </BootstrapDialogTitle>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Tabs value={value} indicatorColor="primary" textColor="primary" variant="fullWidth" onChange={handleChange}>
              <Tab icon={<Info />} aria-label="Informações" {...a11yProps(0)} />
              <Tab icon={<Chat />} aria-label="Chat" {...a11yProps(1)} />
              <Tab icon={<Timeline />} aria-label="Timeline" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <div className="bg-white text-center">
                <span className={`my-2 text-${props.data.statusHtml.color} font-size-md px-4 py-1 h-auto badge badge-neutral-${props.data.statusHtml.color}`}>
                  {props.data.statusHtml.description}
                </span>
                <div className="divider my-4" />
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <span className="opacity-6 pb-2">Forma de pagamento</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-weight-bold font-size-lg">
                        {PaymentTypeText[props.data.payment.type]}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <span className="opacity-6 pb-2">Origem</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-size-lg">
                        {props.data.salesChannel?.name === 'iFood' && (
                          <img src={ifoodIcon} alt="Logo do iFood" width={50} height={25} />
                        )}
                        {props.data.salesChannel?.name === 'anotaai' && (
                          <img src={anotaaiIcon} alt="Logo do anota Ai" width={60} height={60} />
                        )}
                        {props.data.salesChannel?.name !== '' && props.data.device === 5 && (
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="font-weight-bold font-size-lg">
                            <span className="font-size-md">{props.data.salesChannel?.name}</span>
                          </span>
                        </div>
                        )}
                        {props.data.salesChannel == null && props.data.device === 1 && (
                        <div className="d-flex align-items-center justify-content-center">
                          <span className="font-weight-bold font-size-lg">
                            <span className="font-size-md">Gestor Web</span>
                          </span>
                        </div>
                        )}
                        {props.data.salesChannel == null && props.data.device === 2 && (
                          <span className="font-size-md">App Parceiro</span>
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <span className="opacity-6 pb-2">Valor</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-weight-bold font-size-lg">
                        <small className="opacity-6 pr-1">R$</small>
                        <NumberFormat value={props.data.payment.value} displayType="text" thousandSeparator="." decimalSeparator="," decimalScale={2} fixedDecimalScale />
                      </span>
                    </div>
                  </Grid>
                </Grid>
                <div className="divider my-4" />
              </div>
              <Card className="card-box bg-secondary mb-4">
                <CardContent className="p-3">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-black-50 d-block">Cliente</span>
                          <span className="font-weight-bold text-black" title="">{props.data.clientName}</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-black-50 d-block">Solicitação via</span>
                          <span className="font-weight-bold text-black" title="">{DeviceTypeText[props.data.device]}</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-black-50 d-block">Data</span>
                          <span className="font-weight-bold text-black" title="">{toBrazilDateTime(props.data.createdAt)}</span>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="d-flex align-items-center">
                        <div>
                          <span className="text-black-50 d-block">Categoria</span>
                          <span className="font-weight-bold text-black" title="">{CategoryDriverText[props.data.tariff.categoryDriver]}</span>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div>
                        <span className="text-black-50 d-block">Tarifa</span>
                        <span className="font-weight-bold text-black" title="">{props.data.tariff.description}</span>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div>
                        <span className="text-black-50 d-block">Entrega com retorno</span>
                        <span className="font-weight-bold text-black" title="">{props.data.hasBack ? 'Sim' : 'Não'}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div>
                        <span className="text-black-50 d-block">Distância total</span>
                        <span className="font-weight-bold text-black" title="">{`${calculateDistance(props.data.destinations)} km`}</span>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              {/* <Card className="card-box bg-secondary mb-4">
                <CardContent className="p-3">
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-black-50 d-block">Momento do aceite</span>
                      <span className="font-weight-bold text-black" title="">[?]</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-black-50 d-block">Distância até o local de coleta</span>
                      <span className="font-weight-bold text-black" title="">[?]</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div>
                      <span className="text-black-50 d-block">Tempo até o local de coleta</span>
                      <span className="font-weight-bold text-black" title="">[?]</span>
                    </div>
                  </div>
                </CardContent>
              </Card> */}
              <DriversModalComponent
                callId={props.data?.id}
                pickupLocation={props.data?.origin.address.location}
                driver={props.data?.driver}
                subsidiaryId={props.data?.subsidiary}
                status={props.data?.status}
                isEnabled={!props.data?.complete}
              />
              <Grid item sm={12}>
                <div className="timeline-list mb-4">
                  <div className="timeline-item timeline-item-icon">
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon-wrapper rounded-pill bg-primary text-white d-flex align-items-center justify-content-center">
                        <Room />
                      </div>
                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                        Local de coleta
                      </h4>
                      <p>{props.data?.clientAddress}</p>
                    </div>
                  </div>
                  {props.data?.destinations.map((x, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="timeline-item timeline-item-icon" key={i}>
                      <div className="timeline-item--content">
                        <div className="timeline-item--icon-wrapper rounded-pill bg-dark text-white">
                          <FontAwesomeIcon icon={['fas', 'people-carry']} />
                        </div>
                        <h4 className="timeline-item--label mb-2 font-weight-bold">
                          {`${x.receiverName} / ${x.receiverPhone !== null ? phoneNumber(x.receiverPhone) : 'Telefone não informado'}`}
                        </h4>
                        <p>{`${x.address} ${x.complement ?? ''}`}</p>
                        <i className="text-primary">{x.comments}</i>
                      </div>
                    </div>
                  ))}
                </div>
              </Grid>
              <div className="text-center">
                {isAfter(props.data?.createdAt, subDays(new Date(), user?.claims?.admin ? 8 : 1)) && (
                  <Fab variant="extended" size="medium" color="default" aria-label="add" className="m-2" onClick={toggleConfirmCancelModal}>
                    <Close />
                    Cancelar
                  </Fab>
                )}
                {!props.data?.complete && statusAllowedToCompleteCall.includes(props.data?.status) && (
                  <Fab variant="extended" size="medium" color="primary" aria-label="add" className="m-2" onClick={toggleConfirmCompleteModal}>
                    <Done />
                    Finalizar
                  </Fab>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {handleChat(props.data?.notifications)}
              {/* <p>{props.data?.complete}</p>
                <p>{props.data.deliveryDriverValue}</p>
                <p>{props.data.deliveryTime}</p>
                <p>{props.data.deliveryTotalCost}</p>
                <p>{props.data.destinations}</p>
                <p>{props.data.hasBack}</p>
                <p>{props.data.imageUrl}</p>
                <p>{props.data.notifications}</p>
                <p>{props.data.statusHtml}</p> */}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {props.data?.statusHistory.length > 1 && (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Card className="card-box bg-secondary mb-4">
                      <div className="card-indicator bg-warning" />
                      <CardContent className="px-4 py-3">
                        <div className="pb-3 d-flex justify-content-between">
                          <a href="#/" onClick={(e) => e.preventDefault()}>
                            Tempo aguardando alguma interacão
                          </a>
                          <Link color="secondary" target="_blank" href={`/deliveries/${props.data?.id}/map`}>
                            <FontAwesomeIcon icon={['far', 'map']} className="mr-1" />
                            Visualizar mapa
                          </Link>
                        </div>
                        <div className="d-flex align-items-center justify-content-start">
                          <div className="font-size-sm px-2">
                            <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                            {getTimeDifference(props.data?.statusHistory)}
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              )}
              <div className="timeline-list">
                {props.data?.statusHistory.map((x) => (
                  <div className="timeline-item" key={x.date.getTime()}>
                    <div className="timeline-item--content">
                      <div className="timeline-item--icon" />
                      <h4 className="timeline-item--label mb-2 font-weight-bold">
                        {toBrazilDateTime(x.date)}
                        <span className={`ml-2 badge badge-${x.statusHtml.color}`}>
                          {x.statusHtml.description}
                        </span>
                      </h4>
                      <p>{x.by.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </TabPanel>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
