/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum TypesEnum {
  SetError
}

export const setError = (error: any) => ({
  type: TypesEnum.SetError,
  error
});
