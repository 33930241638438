/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum CategoryDriver {
  Undefined = 0,
  Car = 1,
  Bikeboy = 2,
  Motorcycle = 3,
  MotorcycleWithBox = 4,
  MotorcycleWithReel = 5
}

const CategoryDriverText: EnumDictionary<CategoryDriver, string> = {
  [CategoryDriver.Undefined]: 'Desconhecida',
  [CategoryDriver.Car]: 'Carro',
  [CategoryDriver.Bikeboy]: 'Bike',
  [CategoryDriver.Motorcycle]: 'Moto com bag',
  [CategoryDriver.MotorcycleWithBox]: 'Moto com baú',
  [CategoryDriver.MotorcycleWithReel]: 'Moto com carretinha'
};

const CategoryDriverList: Dictionary[] = [
  { key: 1, value: 'Carro' },
  { key: 2, value: 'Bikeboy' },
  { key: 3, value: 'Moto com bag' },
  { key: 4, value: 'Moto com baú' },
  { key: 5, value: 'Moto com carretinha' }
];

export { CategoryDriver as default, CategoryDriverText, CategoryDriverList };
