import axios from 'axios';

import { getAuth, sendPasswordResetEmail } from '../config/firebase';
import api from '../config/functionsApi';
import { UserFirebase } from '../models/interfaces/userFirebase';
import { titleCase } from '../utils/stringHelper';

export default {
  async getByIdOrEmailAsync(idOrEmail: string) {
    // Avoid warning "Can’t perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application"
    const { CancelToken } = axios;
    const source = CancelToken.source();

    const result = await api.get<UserFirebase | null>(`users/${idOrEmail}`, { cancelToken: source.token });
    return result.data;
  },

  async create(email: string, displayName: string) {
    const newUser = {
      email,
      // phoneNumber: phoneNumber ? `+${phoneNumber?.replace(/[^0-9]/g, '')}` : null,  Users created here do not use the linked phone number
      password: 'senha$123',
      displayName: titleCase(displayName)
    };

    return api.post('users', newUser).then(async (result) => {
      await this.sendPasswordResetEmail(newUser.email);
      return result;
    });
  },

  async sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(getAuth(), email);
  },

  async signOut() {
    localStorage.removeItem('cities');
    return getAuth().signOut();
  },

  async updateAsync(email: string, displayName: string, phoneNumber: string, id: string) {
    return api.put(`users/${id}`, {
      email,
      displayName,
      phoneNumber: `+${phoneNumber?.replace(/[^0-9]/g, '')}`
    });
  },

  async updatePhoneNumber(phoneNumber: string, id: string) {
    return api.put(`users/${id}`, {
      phoneNumber: `+${phoneNumber?.replace(/[^0-9]/g, '')}`
    });
  },

  async updateEmailPhoneNumber(email: string, phoneNumber: string, id: string) {
    return api.put(`users/${id}`, {
      email,
      phoneNumber: `+${phoneNumber?.replace(/[^0-9]/g, '')}`
    });
  },

  async updatePhoneEmail(email: string, id: string) {
    return api.put(`users/${id}`, {
      email
    });
  },

  async changePhoto(photoURL: string, id: string) {
    return api.put(`users/${id}`, { photoURL });
  },

  async disableAsync(disabled: boolean, id: string) {
    return api.put(`users/${id}`, { disabled });
  }
};
