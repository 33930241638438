import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconButton, Box } from '@mui/material';
import { connect } from 'react-redux';

import logoCircle from '../../assets/images/logo-icon.png';

const HeaderLogo = (props: any) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <div className={clsx('app-header-logo', {
      'app-header-logo-close': sidebarToggle,
      'app-header-logo-open': sidebarHover
    })}
    >
      <Box className="header-logo-wrapper" title={process.env.REACT_APP_ADMIN_NAME}>
        <Link to="/dashboard" className="header-logo-wrapper-link">
          <IconButton color="primary" size="medium" className="header-logo-wrapper-btn">
            <img className="app-header-logo-img" alt={process.env.REACT_APP_ADMIN_NAME} src={logoCircle} />
          </IconButton>
        </Link>
        <Box className="header-logo-text">{process.env.REACT_APP_ADMIN_SHORT_NAME}</Box>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
