import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import Table from '../../../../components/table';
import placesService from '../../../../services/placesService';
import { useAuth } from '../../../../contexts/auth';

interface Row {
  id: string;
  name: string;
  city: string;
  createdAt: Date;
}

interface OwnProps {
  subsidiaryId: string;
  title: string;
}

export default function Page(props: OwnProps) {
  const history = useHistory();
  let { subsidiaryId } = useAuth();
  const [rows, setRows] = React.useState<Row[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // eslint-disable-next-line react/destructuring-assignment
  if (props.subsidiaryId) subsidiaryId = props.subsidiaryId;

  useEffect(() => {
    const subsidiary = subsidiaryId ?? null;
    placesService.read(subsidiary, null, 10).then((result: any) => {
      setRows(
        result.data.map((item: any) => ({
          id: item.placeid,
          name: item.name,
          city: `${item.city} - ${item.state}`,
          state: item.state,
          createdAt: new Date(item.createdat)
        }))
      );
    });
  }, []);

  const columns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'city',
      options: { display: false }
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                <div>
                  {value}
                  <span className="text-black-50 d-block">{tableMeta.rowData[1]}</span>
                </div>
              </div>
            );
          }
          return <Skeleton />;
        }
      }
    },
    {
      name: 'createdAt',
      label: 'Data de cadastro',
      options: {
        customBodyRender: (value: Date) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">{value.toLocaleString('pt-BR')}</div>
            );
          }
          return <Skeleton />;
        }
      }
    }
  ];

  const onRowClick = (rowData: string[]) => {
    const id = rowData[0];
    if (id) {
      history.push(`/settings/places/${id}/edit`);
    }
  };

  const changePage = (searchText: string) => {
    setIsLoading(true);
    const subsidiary = subsidiaryId ?? null;
    placesService.read(subsidiary, searchText, 10).then((result: any) => {
      setRows(
        result.data.map((item: any) => ({
          id: item.placeid,
          name: item.neighborhood,
          city: `${item.city} - ${item.state}`,
          createdAt: new Date(item.createdat)
        }))
      );
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const onTableChange = (action: string, tableState: any) => {
    if (action === 'search') {
      changePage(tableState.searchText);
    }
  };

  return (
    <Table
      // eslint-disable-next-line react/destructuring-assignment
      title={props.title ?? 'Localidades'}
      createUrl="places/create"
      data={rows!}
      columns={columns}
      filter={false}
      isLoading={isLoading}
      onRowClick={onRowClick}
      onTableChange={onTableChange}
    />
  );
}
