import React, { createContext } from 'react';

export type ContextProps = {
  drivers: any[];
  setDrivers: any;
  saveTrackCredential: any;
  trackToken: any
};

export const DataContext = createContext<Partial<ContextProps>>({});
export const DataProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [drivers, setDriversList] = React.useState<any[]>([]);
  const [trackToken, setTrackToken] = React.useState<any>();
  const setDrivers = (list: any[]) => {
    setDriversList(list);
  };
  const saveTrackCredential = (token: any) => {
    setTrackToken(token);
  };
  return (
    <DataContext.Provider value={{
      drivers, setDrivers, trackToken, saveTrackCredential
    }}
    >
      {children}
    </DataContext.Provider>
  );
};
