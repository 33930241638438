import React, { useCallback } from 'react';

import {
  Field, Form, Formik, FormikProps
} from 'formik';

import {
  CircularProgress,
  Fab,
  Grid,
  Switch
} from '@mui/material';
import { TextField } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../../../components/styles/loadingButton';
import { useAuth } from '../../../../contexts/auth';
import placesService from '../../../../services/placesService';
import { getErrorMessage } from '../../../../config/firebase';
import Place from '../../../../models/interfaces/place';

export default function create() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = UseStyles();
  const [success, setSuccess] = React.useState(false);
  const buttonClassname = clsx({ [classes.buttonSuccess]: success });
  const { subsidiaryId } = useAuth();

  const onSave = useCallback(async (values: Place) => {
    const payload: Place = {
      subsidiaryId: subsidiaryId ?? '',
      name: values.name,
      description: values.description,
      disabledat: values.status ? null : new Date(Date.now()),
      status: true
    };

    console.log('payload', payload);

    await placesService.create(payload).then(() => {
      setSuccess(true);
      enqueueSnackbar('Cadastro realizado com sucesso!', { variant: 'success' });
      setTimeout(() => {
        history.push('/settings/places');
      }, 1000);
    })
      .catch((e) => {
        setSuccess(false);
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      });
  }, []);

  return (
    <WrapperSimple sectionHeading="Cadastrar localidade">
      <Formik
        initialValues={{
          subsidiaryId: '',
          name: '',
          description: '',
          status: true,
          disabledat: ''
        }}
        onSubmit={onSave}
      >
        {(props: FormikProps<Place>) => {
          const {
            errors, touched, isSubmitting, values, handleChange
          } = props;
          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    autoFocus
                    variant="outlined"
                    label="Nome"
                    name="name"
                    error={Boolean(errors.name && touched.name)}
                    helperText={
                      errors.name
                      && touched.name
                      && errors.name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    autoFocus
                    variant="outlined"
                    label="Observação"
                    name="description"
                    error={Boolean(errors.description && touched.description)}
                    helperText={
                      errors.description
                      && touched.description
                      && errors.description
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                  <Switch
                    checked={values.status || false}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                    name="status"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress
                          size={68}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
