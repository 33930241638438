import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Fab, CircularProgress, Grid } from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete } from 'formik-material-ui-lab';
import { AutocompleteRenderInputParams } from '@material-ui/lab';
import { useSnackbar } from 'notistack';

import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import { Create as Values } from '../../../../models/interfaces/subsidiary';
import UseStyles from '../../../../components/styles/loadingButton';
import { setAddressByCEP } from '../../../../services/geolocateService';
import { CepMask, CnpjMask, PhoneMask } from '../../../../components/mask';
import Dictionary from '../../../../models/interfaces/dictionary';
import subsidiaryService from '../../../../services/subsidiaryService';
import userService from '../../../../services/userService';

export default function page() {
  const history = useHistory();
  const classes = UseStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const [users, setUsers] = React.useState<Dictionary[]>();

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  useEffect(() => {
    // TODO: Implement infinite scroll on dropdown
    userService.getByPaginationAsync(1, 200, 'manager').then((result) => {
      if (!result.data.length) throw new Error('Nenhuma gerente encontrado');

      setUsers(result.data.map((x: any) => ({
        key: x.id,
        value: x.name
      })));
    });
  }, []);

  const onBlurCep = (ev: any, setFieldValue: any): void => {
    setAddressByCEP(ev, setFieldValue).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'warning' });
    });
  };

  return (
    <WrapperSimple sectionHeading="Nova filial">
      <Formik
        initialValues={{
          description: '',
          email: '',
          cnpj: '',
          owner: null,
          address: {
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: null,
            ibge: null,
            location: null
          },
          phoneNumber: null
        }}
        validationSchema={Yup.object().shape({
          owner: Yup.object().nullable().required(),
          description: Yup.string().required(),
          email: Yup.string().email().required(),
          cnpj: Yup.string().required(),
          phoneNumber: Yup.string().nullable()
            .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Telefone inválido'),
          address: Yup.object().shape({
            zipCode: Yup.string().required(),
            street: Yup.string().required(),
            neighborhood: Yup.string().required(),
            city: Yup.string().required(),
            number: Yup.string().required()
          })
        })}
        onSubmit={async (values: Values, actions) => {
          await subsidiaryService.createAsync(values).then(() => {
            setSuccess(true);
            history.push('/subsidiaries');
          }).catch((e: any) => {
            enqueueSnackbar(e.message, { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<Values>) => {
          const {
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;
          return (
            <Form noValidate autoComplete="off">
              <Field type="hidden" name="address.ibge" value="" />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  {users?.length && (
                    <Field
                      name="owner"
                      component={Autocomplete}
                      options={users}
                      getOptionLabel={(option: Dictionary) => option.value}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          variant="outlined"
                          label="Proprietário"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoFocus
                          error={Boolean(errors.owner && touched.owner)}
                          helperText={errors.owner && touched.owner && errors.owner}
                        />
                      )}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Descrição"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.description && touched.description)}
                    helperText={errors.description && touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="CNPJ"
                    name="cnpj"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.cnpj && touched.cnpj)}
                    helperText={errors.cnpj && touched.cnpj && errors.cnpj}
                    InputProps={{ inputComponent: CnpjMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.email && touched.email)}
                    helperText={errors.email && touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="CEP"
                    name="address.zipCode"
                    onChange={handleChange}
                    onBlur={(e: any) => onBlurCep(e, setFieldValue)}
                    error={Boolean(errors.address?.zipCode && touched.address?.zipCode)}
                    helperText={errors.address?.zipCode && touched.address?.zipCode && errors.address?.zipCode}
                    InputProps={{ inputComponent: CepMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Endereço"
                    name="address.street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.street && touched.address?.street)}
                    helperText={errors.address?.street && touched.address?.street && errors.address?.street}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Bairro"
                    name="address.neighborhood"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.neighborhood && touched.address?.neighborhood)}
                    helperText={errors.address?.neighborhood && touched.address?.neighborhood && errors.address?.neighborhood}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Cidade"
                    name="address.city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.city && touched.address?.city)}
                    helperText={errors.address?.city && touched.address?.city && errors.address?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Nº"
                    name="address.number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.number && touched.address?.number)}
                    helperText={errors.address?.number && touched.address?.number && errors.address?.number}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    label="Complemento"
                    name="address.complement"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.complement && touched.address?.complement)}
                    helperText={errors.address?.complement && touched.address?.complement && errors.address?.complement}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    label="Telefone"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                    helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                    InputProps={{ inputComponent: PhoneMask as any }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress size={68} className={classes.fabProgress} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
