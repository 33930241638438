/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';
import { Paper } from '@mui/material';
import { connect } from 'react-redux';

import { version } from '../../../package.json';
import { toBrazilDateTime } from '../../components/mask';

const Footer = (props: any) => {
  const { footerShadow, sidebarToggle, footerFixed } = props;
  const year = new Date().getFullYear();
  return (
    <Paper
      square
      elevation={footerShadow ? 11 : 2}
      className={clsx('app-footer text-black-50', {
        'app-footer--fixed': footerFixed,
        'app-footer--fixed__collapsed': sidebarToggle
      })}
    >
      <div className="app-footer--inner">
        <div className="app-footer--first" />
        <div
          className="app-footer--second"
          title={process.env.REACT_APP_ADMIN_BUILD_TIME && `Data da publicação: ${toBrazilDateTime(new Date(process.env.REACT_APP_ADMIN_BUILD_TIME))}`}
        >
          {`${version} © ${year} by `}
          <a href="#" title="Desenvolvido por Boaztech">Boaztech</a>
        </div>
      </div>
    </Paper>
  );
};

const mapStateToProps = (state: any) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

export default connect(mapStateToProps)(Footer);
