import Place from '../models/interfaces/place';
import { getPlaceGeocodingPlus } from './geolocateService';
import { titleCase } from '../utils/stringHelper';
import apiPlacesMicroservice, { BoazRemotePlacesService } from '../config/placesMicroserviceApi';
import { doc, getDoc, subsidiaryCollection } from '../config/firebase';

export default {

  async getConfig() {
    const auth: any = await BoazRemotePlacesService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      }
    };
    return config;
  },

  async create(place: Place) {
    if (!place.name?.length) {
      throw new Error('Informe o nome da localidade!');
    }
    const nameNormalize = titleCase(place.name);
    const docRef = doc(subsidiaryCollection, place?.subsidiaryId);
    const result = await getDoc(docRef);
    const firebaseData = result.data();
    const city = firebaseData?.address?.city;
    const state = firebaseData?.address?.state;

    const location: any = await getPlaceGeocodingPlus(nameNormalize, city, state);
    if (!location) {
      throw new Error('Localização inválida');
    }
    const body: any = {
      placeid: location?.placeId,
      subsidiaryid: place.subsidiaryId,
      name: nameNormalize,
      description: place.description,
      city,
      state,
      lat: location?.coords?.latitude,
      lng: location?.coords?.longitude
    };

    const config = await this.getConfig();
    const response = await apiPlacesMicroservice.post('neighborhoods', body, config);
    return response;
  },

  async read(subsidiaryId: string | null, usertyping: string | null, limit: number) {
    const auth: any = await BoazRemotePlacesService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiary: subsidiaryId || null,
        usertyping,
        limit
      }
    };
    const response = await apiPlacesMicroservice.get('neighborhoods', config);
    return response;
  },

  async readOne(placeid: string) {
    const config = await this.getConfig();
    const response = await apiPlacesMicroservice.get(`neighborhoods/${placeid}`, config);
    return response;
  },

  async update(place: Place) {
    const config = await this.getConfig();
    const response = await apiPlacesMicroservice.put('neighborhoods', place, config);
    return response;
  },

  async updateConsulta(place: Place) {
    if (!place.name?.length) {
      throw new Error('Informe o nome da localidade!');
    }
    const nameNormalize = titleCase(place.name);
    const docRef = doc(subsidiaryCollection, place?.subsidiaryId);
    const result = await getDoc(docRef);
    const firebaseData = result.data();
    const city = firebaseData?.address?.city;
    const state = firebaseData?.address?.state;

    const location: any = await getPlaceGeocodingPlus(nameNormalize, city, state);
    if (!location) {
      throw new Error('Localização inválida');
    }
    const body: any = {
      placeid: location?.placeId,
      subsidiaryid: place.subsidiaryId,
      name: nameNormalize,
      description: place.description,
      city,
      state,
      lat: location?.coords?.latitude,
      lng: location?.coords?.longitude,
      disabledat: place.disabledat
    };

    const auth: any = await BoazRemotePlacesService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      }
    };
    const response = await apiPlacesMicroservice.put('neighborhoods', body, config);
    return response;
  },

  async disable(placeId: string) {
    const config = await this.getConfig();
    const response = await apiPlacesMicroservice.put(`neighborhoods/disable/${placeId}`, {}, config);
    return response;
  },

  async enable(placeId: string) {
    const config = await this.getConfig();
    const response = await apiPlacesMicroservice.put(`neighborhoods/enable/${placeId}`, {}, config);
    return response;
  },

  async delete(placeid: string) {
    const config = await this.getConfig();
    const response = await apiPlacesMicroservice.delete(`neighborhoods/${placeid}`, config);
    return response;
  }

};
