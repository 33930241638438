import { TypesEnum } from './actions/admin';

export default function reducer(
  state = {
    error: '...'
  },
  action: any
) {
  switch (action.type) {
    case TypesEnum.SetError:
      return {
        ...state,
        error: action.error
      };
    default:
      break;
  }
  return state;
}
