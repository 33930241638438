import _ from 'lodash';
import { validate as uuidValidate } from 'uuid';

import {
  balanceCollection, getDocs, limit, orderBy, query, subsidiaryCollection, where
} from '../config/firebase';
import api from '../config/functionsApi';
import balanceStatus from '../models/enums/balanceStatus';
import BalanceFirebase from '../models/interfaces/balance';
import subsidiaryService from './subsidiaryService';

export default {
  async getPrePagoReportAsync(id: string | null | undefined, startDate: Date, endDate: Date, customerId: string | null): Promise<BalanceFirebase[]> {
    let subsidiaryId = id;
    if (subsidiaryId) {
      if (uuidValidate(subsidiaryId)) {
        const sub = await subsidiaryService.getByIdAsync(subsidiaryId);
        const snap = await getDocs(query(subsidiaryCollection, where('cnpj', '==', sub.cnpj)));
        if (!snap.empty) {
          subsidiaryId = snap.docs[0].id;
        }
      }
    }

    let q = query(balanceCollection, where('subsidiary', '==', subsidiaryId),
      where('createdAt', '>=', startDate),
      where('createdAt', '<=', endDate),
      orderBy('createdAt', 'desc'));
    if (customerId) {
      q = query(q, where('owner.id', '==', customerId));
    }

    const result = await getDocs(q).then(async (querySnapshot) => querySnapshot.docs.map((snap: any) => {
      const model = snap.data() as BalanceFirebase;

      return {
        ...model,
        id: snap.id,
        value: model.value / 100,
        approvedDate: snap.data().approvedDate?.toDate(),
        udpatedAt: snap.data().udpatedAt?.toDate(),
        createdAt: snap.data().createdAt?.toDate()
      };
    }));

    // const transactionsQuery = query(balanceTransactionsCollection,
    //   where('createdAt', '>=', startDate), where('createdAt', '<=', endDate),
    //   orderBy('createdAt', 'desc'));
    // const transactions = await getDocs(transactionsQuery).then(async (querySnapshot) => querySnapshot.docs.map((snapT: any) => {
    //   const modelT = snapT.data() as BalanceTransactionFirebase;
    //   const model = _.find(balanceList, (x) => x.id === modelT.id);

    //   return {
    //     approvedBy: model?.approvedBy ?? '?',
    //     approvedDate: model?.approvedDate ?? null,
    //     comments: model?.comments ?? null,
    //     currentBalance: model?.currentBalance ?? null,
    //     owner: model?.owner ?? null,
    //     payment: model?.payment ?? null,
    //     previousBalance: model?.previousBalance ?? null,
    //     receiptUrl: model?.receiptUrl ?? null,
    //     status: model?.status ?? null,
    //     subsidiary: model?.subsidiary ?? null,
    //     updatedAt: model?.updatedAt ?? null,
    //     id: modelT.id,
    //     type: modelT.type,
    //     value: modelT.value,
    //     createdAt: snapT.data().createdAt?.toDate()
    //   };
    // }));

    return result;
  },

  async approveAsync(id: string): Promise<BalanceFirebase> {
    return api.post('balance/approve', { balanceId: id }).then((response) => {
      if (!response.data.success) throw new Error('Serviço indisponível');

      return response.data.balance;
    }).catch((e) => {
      throw new Error(e.response?.data?.message ?? 'Ops, aconteceu algo inesperado, por favor tente novamente');
    });
  },

  async getCurrentBalanceAsync(customerId: string): Promise<number> {
    const q = query(balanceCollection, where('owner.id', '==', customerId),
      orderBy('approvedDate', 'desc'), limit(1));

    const result = await getDocs(q).then(async (querySnapshot) => querySnapshot.docs.map((snap: any) => {
      const model = snap.data() as BalanceFirebase;
      debugger;

      return {
        ...model,
        id: snap.id,
        value: model.value / 100,
        currentBalance: model.currentBalance / 100,
        approvedDate: snap.data().approvedDate?.toDate(),
        udpatedAt: snap.data().udpatedAt?.toDate(),
        createdAt: snap.data().createdAt?.toDate()
      };
    }));

    const data = result.filter((x) => x.status === balanceStatus.Approved);

    return _.first(data)?.currentBalance ?? 0;
  }
};
