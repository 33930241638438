import {
  CollectionReference, DocumentData, Query, QuerySnapshot, endAt, getDocs, limit, orderBy, query, startAt, where
} from 'firebase/firestore';
import StringsService from '../services/stringsService';
import CustomSnapShot from '../models/customSnapShot';
import CustomSnapShopDoc from '../models/customSnapShotDoc';

export default {

  tryGetWithCapitalizeFrase(q: Query<DocumentData>, field: string, search: string, max: number | null) {
    const searchCapitalize = StringsService.capitalizeFrase(search);
    let buildQuery = query(q, orderBy(field, 'asc'), startAt(searchCapitalize), endAt(`${searchCapitalize}\uf8ff`));
    if (max) buildQuery = query(buildQuery, limit(max));
    return buildQuery;
  },

  tryGetWithCapitalizeFraseMultiWay(q: Query<DocumentData>, field: string, search: string, max: number | null) {
    const searchCapitalize = StringsService.capitalizeFrase(search);
    console.log('tryGetWithCapitalizeFraseSecondWay');
    console.log(searchCapitalize);
    // const searchUpperCase = search.toLocaleUpperCase();
    // const searchLowerCase = search.toLocaleLowerCase();
    console.log(max);
    const buidlQuery = query(q, where(field, 'in', [searchCapitalize]));
    // if (max) buidlQuery = query(buidlQuery, limit(max));
    return buidlQuery;
  },

  tryGetWithUppderCase(q: Query<DocumentData>, field: string, search: string) {
    const searchUpperCase = search.toLocaleUpperCase();
    return query(q, orderBy(field, 'asc'), startAt(searchUpperCase), endAt(`${searchUpperCase}\uf8ff`));
  },

  tryGetWithLowerCase(q: Query<DocumentData>, field: string, search: string) {
    const searchLowerCase = search.toLocaleLowerCase();
    return query(q, orderBy(field, 'asc'), startAt(searchLowerCase), endAt(`${searchLowerCase}\uf8ff`));
  },

  getTopTen(q: Query<DocumentData>, field: string) {
    return query(q, orderBy(field, 'asc'), limit(10));
  },

  prepareQuery(collection: CollectionReference<DocumentData>, subsidiaryFieldName: string | null = null,
    subsidiaryId: any | null = null, whereFieldName: string | null = null, whereFieldValue: any | null = null) {
    let q = query(collection);
    q = (subsidiaryId && subsidiaryFieldName) ? query(q, where(subsidiaryFieldName, '==', subsidiaryId)) : query(q);
    q = (whereFieldName && whereFieldValue) ? query(q, where(whereFieldName, '==', whereFieldValue)) : query(q);

    return q;
  },

  mergeQuerySnapshots(snapshots: QuerySnapshot<DocumentData>[], fieldIndex: string) {
    const mergedQuerySnapshot: CustomSnapShot = new CustomSnapShot();
    snapshots.forEach((snapshot) => {
      mergedQuerySnapshot.docs.push(...snapshot.docs.map((row) => new CustomSnapShopDoc(
        row.id, {
          ...row.data(),
          fieldIndex: StringsService.capitalizeFrase(row.data()[fieldIndex])
        }
      )));
    });
    mergedQuerySnapshot.docs.sort((a, b) => (a.data().fieldIndex > b.data().fieldIndex ? 1 : -1));
    return mergedQuerySnapshot;
  },

  // eslint-disable-next-line max-len
  async prepareSnapshot(field: string, search: string, collection: CollectionReference<DocumentData>,
    subsidiaryFieldName: string | null = null,
    subsidiaryId: any | null = null, whereFieldName: string | null = null, whereFieldValue: any | null = null) {
    let q = this.prepareQuery(collection, subsidiaryFieldName, subsidiaryId, whereFieldName, whereFieldValue);

    const searchLength = search?.length ? search?.length : 0;

    if (searchLength >= 3) {
      q = this.tryGetWithCapitalizeFrase(q, field, search, null);
      const querySnapshot = await getDocs(q);

      let q1 = this.prepareQuery(collection, subsidiaryFieldName, subsidiaryId, null, null);
      q1 = this.tryGetWithUppderCase(q1, field, search);
      const querySnapshot1 = await getDocs(q1);

      let q2 = this.prepareQuery(collection, subsidiaryFieldName, subsidiaryId, null, null);
      q2 = this.tryGetWithLowerCase(q2, field, search);
      const querySnapshot2 = await getDocs(q2);

      const mergedQuerySnapshot: CustomSnapShot = this.mergeQuerySnapshots([querySnapshot, querySnapshot1, querySnapshot2], field);

      return mergedQuerySnapshot;
    }
    let q2 = this.prepareQuery(collection, subsidiaryFieldName, subsidiaryId, whereFieldName, whereFieldValue);
    q2 = this.getTopTen(q2, field);

    const querySnapshot = await getDocs(q2);
    return querySnapshot;
  }

};
