import React from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Card,
  Button,
  Snackbar,
  CircularProgress
} from '@mui/material';
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';

import hero3 from '../../assets/images/hero-bg/hero-3.jpg';
import authService from '../../services/authService';

interface Values {
  email: string
}
export default function PagesRecoverPassword() {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email enviado com sucesso"
        action={(
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        )}
      />
      <div className="app-wrapper bg-white">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper bg-arielle-smile min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image"
                        style={{ backgroundImage: `url(${hero3})` }}
                      />
                      <div className="bg-composed-wrapper--bg bg-night-sky opacity-5" />
                      <div className="bg-composed-wrapper--content text-center py-5">
                        <Grid
                          item
                          xl={5}
                          lg={6}
                          md={10}
                          sm={12}
                          className="mx-auto text-center text-white"
                        >
                          <h1 className="display-2 mb-3 px-4 font-weight-bold">
                            Recuperação de senha
                          </h1>
                          <h3 className="font-size-lg line-height-sm font-weight-light d-block px-3 mb-5 text-white-50">
                            Digite seu email para receber um link onde poderá criar uma nova senha.
                          </h3>
                          <Card className="p-5 mx-5 text-center">
                            <Formik
                              initialValues={{
                                email: ''
                              }}
                              validationSchema={Yup.object().shape({
                                email: Yup.string().email().required()
                              })}
                              onSubmit={async (values: Values, actions) => {
                                await authService.sendPasswordResetEmail(values.email).finally(() => {
                                  setOpen(true);
                                  actions.setSubmitting(false);
                                });
                              }}
                            >
                              {(props: FormikProps<Values>) => (
                                <Form noValidate autoComplete="off">
                                  <TextField
                                    fullWidth
                                    required
                                    className="mt-0"
                                    margin="dense"
                                    variant="outlined"
                                    name="email"
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    error={Boolean(props.errors.email && props.touched.email)}
                                    helperText={props.errors.email && props.touched.email && props.errors.email}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <MailOutlineTwoToneIcon />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                  <Button
                                    variant="contained"
                                    className="mt-4"
                                    size="large"
                                    color="primary"
                                    type="submit"
                                    disabled={props.isSubmitting}
                                  >
                                    {props.isSubmitting
                                      ? <CircularProgress color="secondary" size={25} /> : (
                                        <span className="btn-wrapper--label">
                                          Enviar
                                        </span>
                                      )}
                                  </Button>
                                </Form>
                              )}
                            </Formik>
                          </Card>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
