import React from 'react';
import { useParams } from 'react-router-dom';

import BillingPage from '../../../manager/financial/billing';

interface ParamTypes {
  id: string
}

const page = () => {
  const { id } = useParams<ParamTypes>();

  return <BillingPage subsidiaryId={id} />;
};

export default page;
