import React from 'react';
import { useParams } from 'react-router-dom';

import DeliveriesPage from '../../../deliveries';

interface ParamTypes {
  id: string
}

const page = () => {
  const { id } = useParams<ParamTypes>();
  return <DeliveriesPage id={id} />;
};

export default page;
