import axios, { AxiosInstance } from 'axios';
import { TimeZone } from '../models/enums/settings';
import ControllCallByPaymentTypes from '../models/interfaces/callByPaymentTypes';
import { doc, limitsByPaymentsTypesCollection } from '../config/firebase';

export default class SupabaseService {
    api: AxiosInstance;

    supabaseKey = '';

    constructor() {
      this.supabaseKey = process.env.REACT_APP_SUPABASE_API_KEY ?? '1';
      this.api = axios.create({ baseURL: 'https://pojlelxjenfrmzeafukf.supabase.co' });
    }

    getConfig() {
      const config = {
        headers: {
          apikey: this.supabaseKey,
          Authorization: `Bearer ${this.supabaseKey}`,
          'Content-Type': 'application/json'
        }
      };
      return config;
    }

    // async getCall(subsidiaryId: string | undefined | null) {
    //   // subsidiaryId: string | undefined | null, timezone: string | null
    //   const startTimestamp = subHours(new Date(), 5).toISOString();
    //   console.log(startTimestamp);
    //   const currentTimestamp = new Date().toISOString();
    //   console.log(currentTimestamp);

    //   let query = this.supabase
    //     .from('calls')
    //     .select('*')
    //     .lte('createdat', startTimestamp)
    //     .gte('createdat', currentTimestamp);
    //   if (subsidiaryId) { query = query.eq('subsidiaryid', subsidiaryId); }
    //   const resp = await query;
    //   return resp;
    // }

    async getCalls7DaysApi(subsidiaryId: string | undefined | null, timezone: string | null) {
      const config = this.getConfig();
      const subsidiary = subsidiaryId ? `subsidiaryid=eq.${subsidiaryId}&` : '';
      const environment = process.env.REACT_APP_ADMIN_PROJECT_ID;
      let viewName = 'vicalls7daysamerica_saopaulo';

      switch (timezone) {
        case TimeZone.AmericaManaus: viewName = 'vicalls7daysamerica_manaus';
          break;
        case TimeZone.AmericaSaoPaulo: viewName = 'vicalls7daysamerica_saopaulo';
          break;
        case TimeZone.AmericaRioBranco: viewName = 'vicalls7daysamerica_riobranco';
          break;
        default: viewName = 'vicalls7daysamerica_saopaulo';
          break;
      }
      const endpoint = `rest/v1/${viewName}?environment=eq.${environment}&${subsidiary}select=*`;
      return this.api.get(endpoint, config).then((response) => response?.data).catch((error) => console.warn(error));
    }

    getTopClients(subsidiaryId: string | undefined | null) {
      const config = this.getConfig();
      const subsidiary = subsidiaryId ? `subsidiaryid=eq.${subsidiaryId}&` : '';
      const environment = process.env.REACT_APP_ADMIN_PROJECT_ID;
      const viewName = 'vitop10weekclients';
      const endpoint = `rest/v1/${viewName}?status=eq.complete&environment=eq.${environment}&${subsidiary}select=*`;
      console.log(endpoint);
      return this.api.get(endpoint, config).then((response) => response?.data).catch((error) => console.warn(error));
    }

    getTopDrivers(subsidiaryId: string | undefined | null) {
      const config = this.getConfig();
      const subsidiary = subsidiaryId ? `subsidiaryid=eq.${subsidiaryId}&` : '';
      const environment = process.env.REACT_APP_ADMIN_PROJECT_ID;
      const viewName = 'vitop10weekdrivers';
      const endpoint = `rest/v1/${viewName}?status=eq.complete&environment=eq.${environment}&${subsidiary}select=*`;
      return this.api.get(endpoint, config).then((response) => response?.data).catch((error) => console.warn(error));
    }

    async getLimitsByPaymentsTypes(subsidiaryId: string | undefined | null) {
      const config = this.getConfig();
      const subsidiary = subsidiaryId ? `subsidiaryid=eq.${subsidiaryId}&` : '';
      const environment = process.env.REACT_APP_ADMIN_PROJECT_ID;
      const viewName = 'limitsByPaymentsTypes';
      const endpoint = `rest/v1/${viewName}?environment=eq.${environment}&${subsidiary}select=*`;
      console.log(endpoint);
      return this.api.get(endpoint, config).then((response) => response?.data).catch((error) => console.warn(error));
    }

    async createLimitsByPaymentsTypes(body: ControllCallByPaymentTypes) {
      const config = this.getConfig();
      // const subsidiary = subsidiaryId ? `subsidiaryid=eq.${subsidiaryId}&` : '';
      const environment = process.env.REACT_APP_ADMIN_PROJECT_ID;
      const viewName = 'limitsByPaymentsTypes';
      const endpoint = `rest/v1/${viewName}`;
      const idDoc = doc(limitsByPaymentsTypesCollection).id;
      const reg = {
        id: idDoc,
        enabled: body.enabled,
        subsidiaryid: body.subsidiaryId,
        drivercategory: body.driverCategory,
        paymenttypecontrolled: body.paymentTypeControlled,
        parcentualmaxvalue: body.parcentualMaxValue,
        parcentualminvalue: body.parcentualMinValue ?? 0,
        paymenttypetarget: body.paymentTypeTarget,
        accumulation: body.accumulation,
        environment: environment ?? ''
      };
      return this.api.post(endpoint, [reg], config).then((response) => response?.data).catch((error) => console.warn(error));
    }
}
