/* eslint-disable react/react-in-jsx-scope */
import { TextField } from '@material-ui/core';
import {
  Grid, InputAdornment, IconButton, Button
} from '@mui/material';
import { Field } from 'formik';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { FixedValue } from '../../../models/interfaces/tariff';
import { NumberFormatCustom } from '../../../components/mask';

interface props {
  fixedValues: FixedValue[];
  setFixedValues: any;
}

const DynamicRows = ({ fixedValues, setFixedValues }: props) => {
  const addRow = () => {
    const result = [...fixedValues];
    const lastDistance = _.last(fixedValues)?.distance ?? 0;
    result.push({
      id: uuidv4(),
      distance: lastDistance + 1,
      value: undefined,
      valueBack: undefined
    });
    setFixedValues(result);
  };

  const updateRow = (row: FixedValue) => {
    const result = [...fixedValues];
    const elementsIndex = fixedValues.findIndex((x: any) => x.id === row.id);
    result[elementsIndex] = {
      ...result[elementsIndex],
      distance: row.distance,
      value: row.value,
      valueBack: row.valueBack
    };
    setFixedValues(result);
  };

  const removeRow = (id: string) => {
    const result = [...fixedValues];
    _.remove(result, (x) => x.id === id);
    setFixedValues(result);
  };

  return (
    <Grid item xs={12}>
      <Grid container rowSpacing={3} columnSpacing={1}>
        {fixedValues.map((x: any, i: number) => (
          <>
            <Grid item xs={3} sm={4} md={4}>
              <Field
                as={TextField}
                fullWidth
                required
                type="number"
                value={x.distance}
                onChange={(value: any) => updateRow({ ...x, distance: parseFloat(value.target.value) })}
                variant="outlined"
                label="Distancia"
                data-test="distance"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">Km</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Field
                as={TextField}
                fullWidth
                required
                value={x.value}
                onChange={(value: any) => updateRow({ ...x, value: value.target.value })}
                variant="outlined"
                label="Valor fixo"
                data-test="fixedValue"
                InputProps={{
                  inputComponent: NumberFormatCustom as any
                }}
              />
            </Grid>
            <Grid item xs={5} sm={4} md={4}>
              <Field
                as={TextField}
                fullWidth
                required
                value={x.valueBack}
                onChange={(value: any) => updateRow({ ...x, valueBack: value.target.value })}
                variant="outlined"
                label="Valor de volta"
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  endAdornment: (
                    <IconButton
                      aria-label="delete"
                      className="m-2"
                      size="small"
                      disabled={fixedValues.length === 1}
                      onClick={() => removeRow(x.id)}
                      data-test="removeRow"
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  )
                }}
              />
            </Grid>
            {fixedValues.length === i + 1 && (
              <Grid item xs={12} sm={12} md={4}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => addRow()}
                  data-test="dynamicGridButton"
                  fullWidth
                  startIcon={<AddIcon />}
                >
                  Adicionar valor
                </Button>
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </Grid>
  );
};

export default DynamicRows;
