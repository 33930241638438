import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import MapIcon from '@mui/icons-material/Map';
import AssignmentIcon from '@mui/icons-material/Assignment';

const iconsMap: any = {
  DashboardTwoToneIcon,
  SwapVerticalCircleIcon,
  MapIcon,
  AssignmentIcon
};

export default [
  {
    label: 'Área gerencial',
    content: JSON.parse(
      `[
        {
          "label": "Dashboards",
          "icon": "DashboardTwoToneIcon",
          "to": "/dashboard"
        },
        {
          "label": "Chamadas do dia",
          "icon": "SwapVerticalCircleIcon",
          "to": "/deliveries"
        },
        {
          "label": "Relatório de entregas",
          "icon": "AssignmentIcon",
          "to": "/deliveries-report"
        },
        {
          "label": "Mapa",
          "icon": "MapIcon",
          "to": "/map"
        }
      ]`,
      (key, value) => {
        if (key === 'icon') return iconsMap[value];
        return value;
      }
    )
  }
];
