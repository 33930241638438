import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {
  Hidden, IconButton, AppBar, Box, Tooltip
} from '@mui/material';

import { setSidebarToggle as setSidebarToggleByOptions, setSidebarToggleMobile as setSidebarToggleMobileByOptions } from '../../reducers/themeOptions';
import HeaderLogo from '../HeaderLogo';
import HeaderUserbox from '../HeaderUserbox';

import logoCircle from '../../assets/images/logo-icon.png';

const Header = (props: any) => {
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
    isCollapsedLayout
  } = props;
  const toggleSidebar = () => setSidebarToggle(!sidebarToggle);
  const toggleSidebarMobile = () => setSidebarToggleMobile(!sidebarToggleMobile);

  return (
    <AppBar
      color="secondary"
      className={clsx('app-header', { 'app-header-collapsed-sidebar': isCollapsedLayout })}
      position={headerFixed ? 'fixed' : 'absolute'}
      elevation={headerShadow ? 11 : 3}
    >
      {!isCollapsedLayout && <HeaderLogo />}
      <Box className="app-header-toolbar">
        <Hidden lgUp>
          <Box className="app-logo-wrapper" title={process.env.REACT_APP_ADMIN_NAME}>
            <Link to="/dashboard" className="app-logo-link">
              <IconButton color="primary" size="medium" className="app-logo-btn">
                <img className="app-logo-img" alt={process.env.REACT_APP_ADMIN_NAME} src={logoCircle} />
              </IconButton>
            </Link>
            <Hidden smDown>
              <Box className="app-logo-text">{process.env.REACT_APP_ADMIN_SHORT_NAME}</Box>
            </Hidden>
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box className="d-flex align-items-center">
            {!isCollapsedLayout && (
              <Box className={clsx('btn-toggle-collapse', { 'btn-toggle-collapse-closed': sidebarToggle })}>
                <Tooltip title="Toggle Sidebar" placement="right">
                  <IconButton color="inherit" onClick={toggleSidebar} size="medium" className="btn-inverse">
                    {sidebarToggle ? (<MenuRoundedIcon />) : (<MenuOpenRoundedIcon />)}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Hidden>
        <Box className="d-flex align-items-center">
          <HeaderUserbox />
          <Box className="toggle-sidebar-btn-mobile">
            <Tooltip title="Toggle Sidebar" placement="right">
              <IconButton color="inherit" onClick={toggleSidebarMobile} size="medium">
                {sidebarToggleMobile ? (<MenuOpenRoundedIcon />) : (<MenuRoundedIcon />)}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

const mapStateToProps = (state: any) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggle: (enable: any) => dispatch(setSidebarToggleByOptions(enable)),
  setSidebarToggleMobile: (enable: any) => dispatch(setSidebarToggleMobileByOptions(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
