export default class CustomSnapShopDoc {
    id: string = '';

    private docData: any;

    data(): any {
      return this.docData;
    }

    constructor(id: string, data: any) {
      this.id = id;
      this.docData = data;
    }
}
