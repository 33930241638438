/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum CalculationMethod {
  Undefined = 0,
  MileageAndTime = 1,
  MileageAndHourStopped = 2,
  MileageStopped = 3,
  Ifood = 4,
}

const CalculationMethodList: Dictionary[] = [
  // { key: 1, value: 'Por Km e minuto' },
  // { key: 2, value: 'Por Km e hora parada' },
  { key: 3, value: 'Por tabela de Km' },
  { key: 4, value: 'Por tabela de Km (Ifood)' }
];

const calculationMethodText: EnumDictionary<CalculationMethod, string> = {
  [CalculationMethod.Undefined]: '',
  [CalculationMethod.MileageAndTime]: 'Por Km e minuto',
  [CalculationMethod.MileageAndHourStopped]: 'Por Km e hora parada',
  [CalculationMethod.MileageStopped]: 'Por tabela de Km',
  [CalculationMethod.Ifood]: 'Por tabela de Km (Ifood)'
};

export { CalculationMethod as default, CalculationMethodList, calculationMethodText };
