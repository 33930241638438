import React from 'react';
import {
  Card, Fab, Grid, Tooltip, TextField
} from '@mui/material';
import {
  MapContainer, TileLayer, Marker, Popup
} from 'react-leaflet';
import L from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSnackbar } from 'notistack';

import customerService from '../../../services/customerService';
import customerMarker from '../../../assets/images/customer-marker.png';

interface OwnProps {
  customerId: string;
  location: any;
}

const Page = (props: OwnProps) => {
  const { location } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [markerMap, setMarkermap] = React.useState<any>({
    lat: location.latitude,
    lng: location.longitude
  });
  const [draggable, setDraggable] = React.useState(false);
  const markerRef = React.useRef<L.Marker<any>>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const customerIcon = L.icon({
    iconUrl: customerMarker,
    iconSize: [32, 32],
    iconAnchor: [15, 31],
    popupAnchor: [0, -35]
  });

  const eventHandlers = React.useMemo(() => ({
    dragend() {
      const marker = markerRef.current;
      if (marker != null) {
        const loc = marker.getLatLng();
        setMarkermap({ lat: loc.lat, lng: loc.lng });
      }
    }
  }), []);

  const toggleDraggable = (option: boolean) => {
    setDraggable((d) => !d);
    if (!option) {
      setMarkermap({ lat: location.latitude, lng: location.longitude });
    }
  };

  const handleChangeLocationAsync = async () => {
    setIsLoading(true);
    const { customerId } = props;
    const { lat, lng } = markerMap;
    customerService.updateLocationAsync(customerId, lat, lng).then(() => {
      enqueueSnackbar('Coordenadas alteradas com sucesso', { variant: 'success' });
    }).catch((e: any) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    }).finally(() => {
      toggleDraggable(true);
      setIsLoading(false);
    });
  };

  return (
    <Card className="card-box mb-4">
      <div className="card-header py-3">
        <div className="card-header--title font-size-lg">Localizacão</div>
        <div className="card-header--actions">
          {draggable
            ? (
              <>
                <Tooltip arrow title="Cancelar" onClick={() => toggleDraggable(false)}>
                  <Fab size="small" color="default" className="mr-2" disabled={isLoading}>
                    <FontAwesomeIcon icon={['fas', 'times']} />
                  </Fab>
                </Tooltip>
                <Tooltip arrow title="Salvar" onClick={handleChangeLocationAsync}>
                  <Fab size="small" color="primary" disabled={isLoading}>
                    <FontAwesomeIcon icon={['fas', 'save']} />
                  </Fab>
                </Tooltip>
              </>
            )
            : (
              <Tooltip arrow title="Editar localização" onClick={() => toggleDraggable(true)}>
                <Fab size="small" color="primary" disabled={isLoading}>
                  <FontAwesomeIcon icon={['fas', 'map-marked-alt']} />
                </Fab>
              </Tooltip>
            )}
        </div>
      </div>
      <div className="w-100">
        <MapContainer center={{ lat: location.latitude, lng: location.longitude }} scrollWheelZoom={false} zoom={14} style={{ height: '600px' }}>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={markerMap}
            ref={markerRef}
            icon={customerIcon}
          >
            {!draggable && (
              <Popup>
                Clique no botão editar localização para arrastar o marcador
              </Popup>
            )}
          </Marker>
        </MapContainer>
      </div>
      {
        draggable && (
          <>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  label="Latitude"
                  value={markerMap.lat}
                  onChange={(event) => setMarkermap({ ...markerMap, lat: event.target.value })}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  label="Longitude"
                  value={markerMap.lng}
                  onChange={(event) => setMarkermap({ ...markerMap, lng: event.target.value })}
                />
              </Grid>
            </Grid>
          </>
        )
      }
    </Card>
  );
};

export default Page;
