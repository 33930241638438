/* eslint-disable max-len */
import {
  Button, Dialog, TextField
} from '@material-ui/core';
import React, { useState } from 'react';

export interface OwnProps {
    open: boolean;
    data: any | undefined;
    subsidiary: any;
    item: any;
    handleClose: () => void;
    // eslint-disable-next-line no-unused-vars
    handleSubmit: (title: string, value: string) => void;
}
export default function component(props: OwnProps) {
  const {
    open, handleClose, handleSubmit, item
  } = props;

  const [value, setValue] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  if (props.data === undefined) return <></>;
  const onClose = () => {
    handleClose();
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="md">
      <div className="p-4">
        <h4 className="font-weight-bold mt-4 text-center">
          Punição
        </h4>
        <p>
          O tempo de punição será de acordo com o que estiver definido nas Configurações, Geral opção Tempo de espera para condutor inibido.
        </p>

        <div>
          <b>Condutor:</b>
          {' '}
          {item?.name}
          {' '}
        </div>
        <br />
        <div>
          <TextField
            autoFocus
            fullWidth
            required
            label="Título"
            variant="outlined"
            onChange={(e: any) => setTitle(e.target.value)}
          />
        </div>
        <br />
        <div>
          <TextField
            autoFocus
            multiline
            fullWidth
            required
            label="Justificativa"
            variant="outlined"
            rows="4"
            onChange={(e: any) => setValue(e.target.value)}
          />
        </div>
        <div className="pt-4 p-6" style={{ display: 'flex', flexDirection: 'row-reverse', alignContent: 'right' }}>
          {/* onClick={() => completeCall(props.data!.id)} */}
          <Button onClick={() => handleSubmit(title, value)} color="primary" variant="contained" className="mx-1">
            <span className="btn-wrapper--label">Prosseguir com a Punição</span>
          </Button>
          <Button onClick={onClose} color="default" variant="contained" className="mx-1">
            <span className="btn-wrapper--label">Fechar</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
