/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum StatusCall {
  Undefined = 0,
  Waiting = 1,
  Accepted = 2,
  OnGoing = 3,
  Complete = 4,
  Canceled = 5,
  PartialComplete = 6,
  Imported = 7,
  Forwarded = 8
}

const StatusCallText: EnumDictionary<StatusCall, string> = {
  [StatusCall.Undefined]: 'Indefinido',
  [StatusCall.Waiting]: 'Aguardando',
  [StatusCall.Accepted]: 'Aceita',
  [StatusCall.OnGoing]: 'Em trânsito',
  [StatusCall.Complete]: 'Finalizada',
  [StatusCall.Canceled]: 'Cancelada',
  [StatusCall.PartialComplete]: 'Parcialmente finalizada',
  [StatusCall.Imported]: 'Importada',
  [StatusCall.Forwarded]: 'Encaminhada'
};

const StatusCallList: Dictionary[] = [
  { key: 'waiting', value: 'Aguardando' },
  { key: 'accepted', value: 'Aceita' },
  { key: 'ongoing', value: 'Em trânsito' },
  { key: 'complete', value: 'Finalizada' },
  { key: 'canceled', value: 'Cancelada' },
  { key: 'partialComplete', value: 'Parcialmente finalizada' },
  { key: 'imported', value: 'Importada' },
  { key: 'forwarded', value: 'Encaminhada' }
];

export const convertStatusToBrazilian = (status: string) => {
  let result = { color: '', description: '' };
  switch (status) {
    case 'waiting':
      result = {
        color: 'warning',
        description: 'Aguardando'
      };
      break;
    case 'accepted':
      result = {
        color: 'info',
        description: 'Aceita'
      };
      break;
    case 'ongoing':
      result = {
        color: 'success',
        description: 'Em trânsito'
      };
      break;
    case 'partialComplete':
      result = {
        color: 'dark',
        description: 'Parcialmente finalizada'
      };
      break;
    case 'complete':
      result = {
        color: 'dark',
        description: 'Finalizada'
      };
      break;
    case 'canceled':
      result = {
        color: 'danger',
        description: 'Cancelada'
      };
      break;
    case 'imported':
      result = {
        color: 'warning',
        description: 'Importada'
      };
      break;
    case 'forwarded':
      result = {
        color: 'warning',
        description: 'Encaminhada'
      };
      break;
    default:
      result = {
        color: 'danger',
        description: '?'
      };
      break;
  }
  return result;
};

export { StatusCall as default, StatusCallText, StatusCallList };
