import React, { Suspense } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@mui/material/styles';
import { ScaleLoader } from 'react-spinners';
import {
  Switch, Route, Redirect, useLocation
} from 'react-router-dom';

import PrivateRoute from './private';
import PublicRoutes from './public';
import AdminRoutes from '../pages/areas/admin/routes';
import ReadOnlyRoutes from '../pages/areas/read-only/routes';
import ManagerRoutes from '../pages/areas/manager/routes';
import DefaultRoutes from '../pages/areas/default/routes';
import PagesError404 from '../pages/status/404';
import { LeftSidebar, MinimalLayout } from '../layouts';
import customTheme from '../theme';
import { useAuth } from '../contexts/auth';

const Routes = () => {
  const location = useLocation();
  const { user, subsidiaryId, isReadOnly } = useAuth();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => (
    <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
      <div className="d-flex align-items-center flex-column px-4">
        <ScaleLoader loading />
      </div>
    </div>
  );

  const handleAreas = () => {
    if (subsidiaryId) {
      if (user?.claims?.manager) return <ManagerRoutes />;

      return <DefaultRoutes />;
    }

    if (isReadOnly) return <ReadOnlyRoutes />;

    return <AdminRoutes />;
  };

  return (
    <ThemeProvider theme={customTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/login" />

            <Route path={[
              '/login',
              '/recover-password',
              '/403',
              '/404',
              '/500',
              '/503'
            ]}
            >
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <PublicRoutes />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            <Route path={[
              '/profile',
              '/dashboard',
              '/chart',
              '/deliveries',
              '/deliveries-report',
              '/map',
              '/companies',
              '/subsidiaries',
              '/drivers',
              '/administrators',
              '/managers',
              '/reports',
              '/users',
              '/settings'
            ]}
            >
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    <PrivateRoute path="/" component={handleAreas} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route path="*" component={PagesError404} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
