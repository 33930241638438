// import { parse } from 'date-fns';

import { DocumentData, Query, getCountFromServer } from 'firebase/firestore';
import {
  doc, GeoPoint, getDateNow, getDocs, query,
  createDocAsync, updateDoc,
  userCollection, where, getDoc, orderBy, limit as limitRows, startAt, endAt
} from '../config/firebase';
import Address from '../models/interfaces/address';
import authService from './authService';
import { getGeocoding } from './geolocateService';
import { firstLastName, titleCase } from '../utils/stringHelper';
import { DetailUser, UpdateUser } from '../models/interfaces/user';
import Paginate from '../models/interfaces/deliveryApi/paginate';
import StringsService from './stringsService';
// import { forEach } from 'lodash';

export default {
  async getEmployeesByPaginationAsync(subsidiaryId: string | null | undefined, offset: number, limit: number, search: string | null = null): Promise<Paginate> {
    let q = query(userCollection);
    if (subsidiaryId) {
      q = query(q, where('subsidiaryId', '==', subsidiaryId));
    }

    if (search && search.length > 3) {
      const searchCapitalize = StringsService.capitalizeFrase(search);
      q = query(userCollection, orderBy('name', 'asc'), startAt(searchCapitalize), endAt(`${searchCapitalize}\uf8ff`), limitRows(limit));
    } else {
      q = query(q, orderBy('name', 'asc'), limitRows(limit));
    }
    const resp = await this.usersToPaginate(q);
    return resp;
  },

  async getAllCount(id: string | null | undefined) {
    const subsidiaryId = id;
    let total;
    const q = query(userCollection);
    const c = query(q);

    if (subsidiaryId) {
      total = await getCountFromServer(query(c, where('subsidiary', '==', subsidiaryId)));
    } else {
      total = await getCountFromServer(c);
    }

    return { total: total?.data().count };
  },

  async getAllManagerAdminCount(claim: string | null) {
    const q = query(userCollection);
    let c = query(q);

    if (claim === 'manager') {
      c = query(q, where('claims.manager', '==', true));
    }
    if (claim === 'admin') {
      c = query(q, where('claims.admin', '==', true));
    }

    const total = await getDocs(c);

    return { total: total?.size };
  },

  // eslint-disable-next-line no-shadow
  async usersToPaginate(query: Query<DocumentData>): Promise<Paginate> {
    const snap = await getDocs(query);
    const users: any = [];
    snap.forEach((document: any) => {
      users.push({
        id: document.id,
        uid: document.id,
        name: document.data()?.name,
        email: document.data()?.email,
        phoneNumber: document.data()?.phoneNumber,
        photoURL: document.data()?.photoURL,
        address: document.data()?.address,
        claims: document.data()?.claims,
        cpf: document.data()?.cpf,
        createdAt: document.data()?.createdAt ? document.data().createdAt.toDate() : null,
        disabledAt: document.data()?.disabledAt ? document.data().disabledAt.toDate() : null,
        dtBirth: document.data()?.dtBirth ? document.data()?.dtBirth.toDate() : null,
        emailVerified: null,
        lastSignInTime: document.data()?.updatedAt ? document.data().updatedAt.toDate() : null,
        subsidiaryId: document.data()?.subsidiaryId
      });
    });
    const resp: Paginate = {
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 0,
      total: users.length,
      totalPages: 0,
      data: users
    };
    return resp;
  },

  async getByPaginationAsync(offset: number, limit: number, claim: string | null, search: string | null = null): Promise<Paginate> {
    let q = query(userCollection);
    if (claim === 'manager') {
      q = query(q, where('claims.manager', '==', true));
    }
    if (claim === 'admin') {
      q = query(q, where('claims.admin', '==', true));
    }

    if (search && search.length > 3) {
      const searchCapitalize = StringsService.capitalizeFrase(search);
      q = query(userCollection, orderBy('name', 'asc'), startAt(searchCapitalize), endAt(`${searchCapitalize}\uf8ff`), limitRows(limit));
    } else {
      q = query(userCollection, orderBy('name', 'asc'), limitRows(limit));
    }
    const resp = await this.usersToPaginate(q);
    return resp;
  },

  async getByIdAsync(uid: string): Promise<DetailUser> {
    let key = uid;
    if (uid?.includes('@')) {
      const userFirebase = await getDocs(query(userCollection, where('email', '==', uid)));
      if (userFirebase.size !== 1) throw new Error('User not found');
      key = userFirebase.docs[0].id;
    }
    const docRef = doc(userCollection, key);
    const userFirebase = await getDoc(docRef);
    if (!userFirebase.exists) throw new Error('User not found');

    const data: any = userFirebase.data();
    return {
      id: uid,
      uid,
      email: data?.email,
      phoneNumber: data?.phoneNumber,
      photoURL: data?.photoURL,
      address: {
        zipCode: data?.address.zipCode,
        street: data?.address.street,
        neighborhood: data?.address.neighborhood,
        number: data?.address.number,
        complement: data?.address.complement,
        city: data?.address.city,
        state: data?.address.state,
        ibge: data?.address.ibge,
        location: new GeoPoint(data?.address?.location.latitude, data?.address.location.longitude)
      },
      cpf: data.cpf,
      dtBirth: data!.dtBirth?.toDate(),
      disabledAt: data!.disabledAt?.toDate(),
      disabled: data?.disabledAt !== null,
      name: data?.name,
      subsidiaryId: data.subsidiaryId,
      claims: {
        admin: data.claims?.admin ? data.claims?.admin : false,
        manager: data.claims?.manager ? data.claims?.manager : false
      },
      displayName: data.displayName ? data.displayName : null,
      emailVerified: data.emailVerified,
      metadata: {
        creationTime: data!.createdAt?.toDate(),
        lastSignInTime: data!.updatedAt?.toDate()
      }
    };
  },

  async getFirebaseIdAsync(email: string): Promise<string> {
    const result = await getDocs(query(userCollection, where('email', '==', email), where('disabledAt', '==', null)));
    if (result.size !== 1) throw new Error('Responsável com email em duplicidade ou não encontrado.');
    return result.docs[0].id;
  },

  async createAsync(email: string, name: string, cpf: string, dtBirth: Date,
    phoneNumber: string | null, address: Address, claims: any | null,
    subsidiaryId: string | null) {
    const location = await getGeocoding(address);
    if (location === null || address.ibge === null) {
      throw new Error('Endereço inválido, verifique o CEP');
    }

    // TODO: Remove reference to all of them
    // const subsidiary = subsidiaryId ? doc(subsidiaryCollection, subsidiaryId) : null;
    const nameNormalize = titleCase(name);

    const cpfUnmask = cpf?.replace(/[^0-9]/g, '');

    return authService.create(email, firstLastName(nameNormalize)).then(async (response) => {
      const data = {
        name: nameNormalize,
        cpf: cpfUnmask,
        dtBirth: new Date(dtBirth),
        address: { ...address, location },
        email,
        phoneNumber: phoneNumber ? `+${phoneNumber?.replace(/[^0-9]/g, '')}` : null,
        photoURL: null,
        claims,
        subsidiaryId,
        createdAt: getDateNow(),
        updatedAt: null,
        disabledAt: null
      };

      // const jsonDeliveryApi: Create = {
      //   name: data.name,
      //   cpf: data.cpf,
      //   dtBirth: format(data.dtBirth, 'yyyy-MM-dd'),
      //   email: data.email,
      //   phoneNumber: data.phoneNumber,
      //   photoURL: data.photoURL,
      //   subsidiaryId: subsidiaryGuid,
      //   address: {
      //     city: data.address.city,
      //     complement: data.address.complement,
      //     neighborhood: data.address.neighborhood,
      //     number: data.address.number,
      //     state: data.address.state!,
      //     street: data.address.street,
      //     zipCode: data.address.zipCode,
      //     ibgeCod: data.address.ibge ?? '0000000',
      //     location: {
      //       latitude: data.address.location.latitude,
      //       longitude: data.address.location.longitude
      //     }
      //   },
      //   claims: {
      //     isAdmin: claims?.admin ?? false,
      //     isManager: claims?.manager ?? false
      //   }
      // };

      // await deliveryApi.post('users', jsonDeliveryApi).catch(() => {
      //   throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
      // });

      await createDocAsync('users', response.data, data);
    });
  },

  async updateAsync(user: UpdateUser, oldEmail: string) {
    const location = await getGeocoding(user.address);
    if (location === null || user.address.ibge === null) {
      throw new Error('Endereço inválido, verifique o CEP');
    }

    const nameNormalize = titleCase(user.name);

    const data = {
      name: nameNormalize,
      dtBirth: user.dtBirth,
      email: user.email,
      phoneNumber: `+${user.phoneNumber?.replace(/[^0-9]/g, '')}`,
      address: { ...user.address, location },
      updatedAt: getDateNow()
    };

    // const jsonDeliveryApi: Update = {
    //   name: data.name,
    //   dtBirth: format(data.dtBirth, 'yyyy-MM-dd'),
    //   email: data.email,
    //   phoneNumber: data.phoneNumber,
    //   address: {
    //     city: data.address.city,
    //     complement: data.address.complement,
    //     neighborhood: data.address.neighborhood,
    //     number: data.address.number,
    //     state: data.address.state!,
    //     street: data.address.street,
    //     zipCode: data.address.zipCode,
    //     ibgeCod: data.address.ibge ?? '0000000',
    //     location: {
    //       latitude: data.address.location.latitude,
    //       longitude: data.address.location.longitude
    //     }
    //   }
    // };

    // await deliveryApi.put(`users/${id}`, jsonDeliveryApi).catch((e) => {
    //   if (e.response) {
    //     if (e.response.status !== 404) {
    //       throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
    //     }
    //   }
    //   console.warn('Not found on API');
    // });

    const firebaseAuthUser = await authService.getByIdOrEmailAsync(oldEmail);
    console.log('firebaseAuthUser', firebaseAuthUser);
    if (firebaseAuthUser === null) throw new Error('Firebase user not found');

    console.log('data', data);

    const userRef = doc(userCollection, firebaseAuthUser.uid);
    return updateDoc(userRef, data);
  },

  async disableAsync(disabled: boolean, firebaseId: string) {
    // const model = await this.getByIdAsync(userId);
    // if (model) {
    //   await deliveryApi.patch(`users/${model.id}/disable`).catch((e) => {
    //     if (e.response) {
    //       if (e.response.status !== 404) {
    //         throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
    //       }
    //     }
    //     console.warn('Not found on API');
    //   });
    // }
    const userRef = doc(userCollection, firebaseId);
    return updateDoc(userRef, { disabledAt: disabled ? getDateNow() : null });
    // return authService.disableAsync(disabled, firebaseId).then((data) => {
    //   if (data.status === 204) {
    //     const userRef = doc(userCollection, firebaseId);
    //     updateDoc(userRef, { disabledAt: disabled ? getDateNow() : null });
    //   }
    // });
  },

  async updatePhotoURLAsync(photoURL: string, firebaseId: string) {
    // const model = await this.getByIdAsync(userId);
    // if (model) {
    //   await deliveryApi.patch(`users/${model.id}`, [
    //     {
    //       path: '/photoURL',
    //       op: 'replace',
    //       value: photoURL
    //     }
    //   ]).catch((e) => {
    //     if (e.response) {
    //       if (e.response.status !== 404) {
    //         throw new Error('Serviço indisponível, por favor tente novamente em alguns minutos');
    //       }
    //     }
    //     console.warn('Not found on API');
    //   });
    // }

    const userRef = doc(userCollection, firebaseId);

    return updateDoc(userRef, { photoURL });
  }
};
