import React, { useCallback, useEffect } from 'react';

import {
  Field, Form, Formik, FormikProps
} from 'formik';

import {
  CircularProgress,
  Fab,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import { TextField } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import CategoryDriver, {
  CategoryDriverList
} from '../../../../models/enums/categoryDriver';
import UseStyles from '../../../../components/styles/loadingButton';
import { getErrorMessage } from '../../../../config/firebase';
import { useAuth } from '../../../../contexts/auth';
import SupabaseService from '../../../../services/supabase';
import ControllCallByPaymentTypes from '../../../../models/interfaces/callByPaymentTypes';
import PaymentType, { PaymentTypeList } from '../../../../models/enums/paymentType';
import Periodicity from '../../../../models/enums/periodicity';
import { ActiveBoleanList } from '../../../../models/enums/statusType';
import Dictionary from '../../../../models/interfaces/dictionary';

export default function create() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = UseStyles();
  const [success, setSuccess] = React.useState(false);
  // const [customers, setCustomers] = React.useState<any>([]);
  const buttonClassname = clsx({ [classes.buttonSuccess]: success });
  const { subsidiaryId } = useAuth();
  const supabaseService = new SupabaseService();

  useEffect(() => {
    // customerService.getAll(subsidiaryId, true).then((result) => {
    //   const items = result.map((item: any) => ({
    //     value: item.id,
    //     label: item.displayName
    //   }));
    //   setCustomers(items);
    // });
  }, []);

  // const getPaymentList = paymentList() => {

  // }

  const onSave = useCallback(async (values: ControllCallByPaymentTypes, actions) => {
    if (values.paymentTypeControlled === values.paymentTypeTarget) {
      enqueueSnackbar('O tipo de pagamento alternativo deve ser diferente do que você pretende limitar', { variant: 'warning' });
      setSuccess(false);
      return;
    }

    if (values.parcentualMaxValue === 0) {
      const paymentType = PaymentTypeList.find((row: Dictionary) => row.key === values.paymentTypeControlled);
      enqueueSnackbar(`Informe o percentual máximo permitido para chamadas com o tipo ${paymentType?.value}.`, { variant: 'warning' });
      setSuccess(false);
      return;
    }

    await supabaseService.createLimitsByPaymentsTypes(values)
      .then(() => {
        setSuccess(true);
        setTimeout(() => {
          history.push('/settings/limits-paymenttypes');
        }, 1000);
      })
      .catch((e) => {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
        setSuccess(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }, []);

  return (
    <WrapperSimple sectionHeading="Criar limite por tipo de pagamento">
      <Formik
        initialValues={{
          enabled: true,
          subsidiaryId: subsidiaryId ?? '',
          driverCategory: CategoryDriver.Undefined,
          paymentTypeControlled: PaymentType.Undefined,
          parcentualMaxValue: 0,
          parcentualMinValue: 50,
          paymentTypeTarget: PaymentType.Undefined,
          accumulation: 'weekly'
        }}
        onSubmit={onSave}
      >
        {(props: FormikProps<ControllCallByPaymentTypes>) => {
          const {
            errors, touched, isSubmitting, values, handleChange, handleBlur
          } = props;
          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Categoria"
                      name="driverCategory"
                      error={Boolean(
                        errors.driverCategory && touched.driverCategory
                      )}
                    >
                      {CategoryDriverList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Chamadas com pagamento tipo</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Chamadas com pagamento tipo"
                      name="paymentTypeControlled"
                      error={Boolean(errors.paymentTypeControlled && touched.paymentTypeControlled)}
                    >
                      {PaymentTypeList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel>Ao alcançar o perc. de</InputLabel> */}
                    <Field
                      as={TextField}
                      required
                      value={values.parcentualMaxValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      label="Limitadas até (%)"
                      type="number"
                      name="parcentualMaxValue"
                      error={Boolean(errors.parcentualMaxValue && touched.parcentualMaxValue)}
                      helperText={errors.parcentualMaxValue && touched.parcentualMaxValue && errors.parcentualMaxValue}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4} sm={4} md={3}>
                  <FormControl variant="outlined" fullWidth>
                    <Field
                      as={TextField}
                      required
                      value={values.parcentualMinValue}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      variant="outlined"
                      label="Permitida a partir de (%)"
                      type="number"
                      name="parcentualMinValue"
                      error={Boolean(errors.parcentualMinValue && touched.parcentualMinValue)}
                      helperText={errors.parcentualMinValue && touched.parcentualMinValue && errors.parcentualMinValue}
                    />
                  </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Tipo de pagto alternativo</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Tipo de pagto alternativo"
                      name="paymentTypeTarget"
                      error={Boolean(errors.paymentTypeTarget && touched.paymentTypeTarget)}
                    >
                      {PaymentTypeList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Periodicidade</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Periodicidade"
                      name="accumulation"
                      error={Boolean(errors.accumulation && touched.accumulation)}
                    >
                      {Periodicity.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Ativo?</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Ativo"
                      name="enabled"
                      error={Boolean(errors.enabled && touched.enabled)}
                    >
                      {ActiveBoleanList.map((item: any) => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="d-block text-black-50 font-size-sm mr-4">
                    <p>
                      Essa funcionalidade busca reduzir a inadimplência que envolvem condutores e franquias, utilizando a lógica de
                      <strong> priorização dinâmica de tipos de pagamento</strong>
                      . O objetivo é assegurar que as taxas incidentes sobre valores recebidos a vista sejam compensadas pelos condutores.
                    </p>
                    {/* <h6>Benefícios</h6>
                    <ul>
                      <li>
                        <strong>Redução da inadimplência:</strong>
                        <p>Controla automaticamente a compensação de valores, evitando atrasos ou inadimplência por parte do condutor.</p>
                      </li>
                      <li>
                        <strong>Automação e eficiência:</strong>
                        <p>Evita o trabalho manual de monitoramento e cobrança, reduzindo erros e atrasos.</p>
                      </li>
                    </ul> */}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress
                          size={68}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
