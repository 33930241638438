import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Hidden, Drawer, Paper, MenuItem, Input, Select
} from '@mui/material';
import { connect } from 'react-redux';

import { useAuth } from '../../contexts/auth';
import SidebarHeader from '../SidebarHeader';
import SidebarMenu from '../SidebarMenu';
import navItemsAdmin from '../../pages/areas/admin/navItems';
import navItemsManager from '../../pages/areas/manager/navItems';
import navItemsReadOnly from '../../pages/areas/read-only/navItems';
import navItems from '../../pages/areas/default/navItems';

import {
  setSidebarToggleMobile as setSidebarToggleMobileByOptions,
  setSidebarHover as setSidebarHoverByOptions,
  setSidebarToggle,
  setSidebarFooter,
  setSidebarUserbox
} from '../../reducers/themeOptions';

const Sidebar = (props) => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,

    sidebarHover,
    setSidebarHover,
    sidebarToggle,
    sidebarShadow,
    selectSubsidiary
  } = props;

  const {
    user, subsidiaries, subsidiaryId, isReadOnly
  } = useAuth();

  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const handleMenu = () => {
    let items = navItems;
    let dropdown = (<></>);
    if (user && user?.claims) {
      const claims = user?.claims;
      if (claims.admin) {
        items = navItemsAdmin;
      }
      if (claims.manager) {
        if (subsidiaryId || isReadOnly) {
          const mainSubsidiary = _.find(subsidiaries, (x) => x.key === subsidiaryId);
          dropdown = (
            <Select
              className="MuiTypography-root app-sidebar-heading MuiTypography-body1"
              fullWidth
              variant="outlined"
              value={mainSubsidiary.value}
              onChange={(e, item) => {
                const key = item.key === '.$null' ? null : item.key.substr(2);
                selectSubsidiary({ key, value: item.props.value });
              }}
              input={<Input />}
            >
              {subsidiaries.map((item) => (<MenuItem key={item.key} value={item.value}>{item.value}</MenuItem>))}
            </Select>
          );

          navItemsManager[0] = { ...navItemsManager[0], label: '' };
        }
        items = navItemsManager;

        if (!subsidiaryId && isReadOnly) items = navItemsReadOnly;
      }
    }

    if (user) {
      return (
        <>
          {dropdown}
          {items.map((list) => (
            <SidebarMenu
              component="div"
              key={list.label}
              pages={list.content}
              title={list.label}
            />
          ))}
        </>
      );
    }

    return (
      <></>
    );
  };

  const sidebarMenuContent = (
    <div className={clsx({ 'app-sidebar-nav-close': sidebarToggle && !sidebarHover })}>
      {handleMenu()}
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg"
        >
          <SidebarHeader />
          <PerfectScrollbar>
            {sidebarMenuContent}
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}
        >
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}
          >
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter,
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobileByOptions(enable)),
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarHover: (enable) => dispatch(setSidebarHoverByOptions(enable)),
  setSidebarFooter: (enable) => dispatch(setSidebarFooter(enable)),
  setSidebarUserbox: (enable) => dispatch(setSidebarUserbox(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
