import IAddress from './interfaces/address';
import { zipCode as zipCodeMask } from '../components/mask';
import { GeoPoint } from '../config/firebase';

export default class Address implements IAddress {
  zipCode: string;

  street: string;

  neighborhood: string;

  number: string;

  complement: string | null;

  city: string;

  state: string | null;

  ibge: string | null;

  location: GeoPoint | null;

  constructor(model: IAddress) {
    if (model === undefined) throw new Error('Address is required');

    this.zipCode = model.zipCode;
    this.street = model.street;
    this.neighborhood = model.neighborhood;
    this.number = model.number;
    this.complement = model.complement;
    this.city = model.city;
    this.state = model.state;
    this.ibge = model.ibge;
    this.location = model.location;
  }

  toString(): string {
    return `${this.street}, ${this.number}, ${this.neighborhood}, ${this.city}, ${zipCodeMask(this.zipCode)}`;
  }
}
