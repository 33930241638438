import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import {
  Fab, CircularProgress, Grid, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';

import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../../../components/styles/loadingButton';
import { getErrorMessage } from '../../../../config/firebase';
import tariffService from '../../../../services/tariffService';
import CalculationMethod, { CalculationMethodList } from '../../../../models/enums/calculationMethod';
import categoryDriver, { CategoryDriverList } from '../../../../models/enums/categoryDriver';
import { FixedValue } from '../../../../models/interfaces/tariff';
import DynamicRows from '../../../tariffs/components/dynamicRows';

interface FormValues {
  categoryDriver: categoryDriver;
  description: string;
  calculationMethod: CalculationMethod;
  valuePerAdditionalStop: number;
  valueFlag: number;
  valueByKm: number;
  valueByStop: number;
  valueMinimum: number;
  disabledAt: Date | null;
  companyName: string | null
}

interface ParamTypes {
  subsidiaryId: string,
  tariffId: string
}

export default function edit() {
  const history = useHistory();
  const { tariffId, subsidiaryId } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const classes = UseStyles();
  const [fixedValues, setFixedValues] = React.useState<FixedValue[]>([]);

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  return (
    <WrapperSimple sectionHeading="Editar tarifa">
      <Formik
        initialValues={{
          categoryDriver: categoryDriver.Undefined,
          description: '',
          calculationMethod: CalculationMethod.Undefined,
          valuePerAdditionalStop: 0,
          valueFlag: 0,
          valueByKm: 0,
          valueByStop: 0,
          valueMinimum: 0,
          disabledAt: null,
          companyName: null
        }}
        validationSchema={Yup.object().shape({
          categoryDriver: Yup.number().required(),
          description: Yup.string().required(),
          calculationMethod: Yup.number().required(),
          valuePerAdditionalStop: Yup.number().required(),
          valueFlag: Yup.number().required(),
          valueByKm: Yup.number().required(),
          valueByStop: Yup.number().required(),
          valueMinimum: Yup.number().required()
        })}
        onSubmit={async (values: FormValues, actions) => {
          await tariffService.update({
            categoryDriver: values.categoryDriver!,
            description: values.description,
            calculationMethod: values.calculationMethod!,
            valuePerAdditionalStop: values.valuePerAdditionalStop!,
            valueFlag: values.valueFlag!,
            valueByKm: values.valueByKm!,
            valueByStop: values.valueByStop!,
            valueMinimum: values.valueMinimum!,
            disabledAt: values.disabledAt,
            fixedValues,
            companyName: values.companyName
          }, tariffId).then(() => {
            setSuccess(true);
            if (subsidiaryId) { // user is god
              history.push(`/subsidiaries/${subsidiaryId}`);
            } else { // user is manager
              history.push('/settings/tariffs');
            }
          }).catch((e) => {
            enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
            setSuccess(false);
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const {
            errors, touched, isSubmitting, setFieldValue
          } = props;
          const [customerName, setCustomerName] = React.useState('');

          useEffect(() => {
            tariffService.getById(tariffId).then((result: any) => {
              const fields = ['categoryDriver', 'description', 'calculationMethod', 'valuePerAdditionalStop',
                'valueFlag', 'valueByKm', 'valueByStop', 'valueMinimum', 'disabledAt'];
              fields.forEach((field) => setFieldValue(field, result[field], false));

              if (result.company) {
                setCustomerName(result.company.displayName);
              }

              if (result.fixedValues?.length) {
                setFixedValues(result.fixedValues);
              } else {
                setFixedValues([{
                  id: uuidv4(), distance: 1, value: undefined, valueBack: undefined
                }]);
              }
            });
          }, []);

          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                  {customerName && (
                    <Field
                      as={TextField}
                      fullWidth
                      variant="outlined"
                      value={customerName}
                      label="Cliente"
                      disabled
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Categoria</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Categoria"
                      name="categoryDriver"
                      error={Boolean(errors.categoryDriver && touched.categoryDriver)}
                      disabled
                    >
                      {CategoryDriverList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    autoFocus
                    variant="outlined"
                    label="Descrição"
                    name="description"
                    error={Boolean(errors.description && touched.description)}
                    helperText={errors.description && touched.description && errors.description}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Método de cálculo</InputLabel>
                    <Field
                      as={Select}
                      required
                      label="Método de cálculo"
                      name="calculationMethod"
                      error={Boolean(errors.calculationMethod && touched.calculationMethod)}
                    >
                      {CalculationMethodList.map((item: any) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)}
                    </Field>
                  </FormControl>
                </Grid>

                <DynamicRows fixedValues={fixedValues} setFixedValues={setFixedValues} />

                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && <CircularProgress size={68} className={classes.fabProgress} />}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
