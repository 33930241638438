import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import subsidiaryService from '../../../../services/subsidiaryService';
import CustomersPage from '../../../customers';

interface ParamTypes {
  id: string
}

export default function Page() {
  const [name, setName] = React.useState<string | undefined>(undefined);
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    subsidiaryService.getByIdAsync(id).then((result) => setName(result.description));
  }, []);

  return <CustomersPage subsidiaryId={id} title={`Clientes / ${name}`} />;
}
