import React from 'react';
import {
  StyleSheet, Document, Page, View, Text
} from '@react-pdf/renderer';

import { toBrazilDate, toBrazilDateTime } from '../../../../components/mask';
import { Row } from './prepago';

interface OwnProps {
  data: Row[],
  startDate: Date | null | undefined,
  endDate: Date | null | undefined
}

export default function print(props: OwnProps) {
  const toDecimal = (value: number) => value?.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  const styles = StyleSheet.create({
    body: {
      padding: 10,
      paddingBottom: 60
    },
    table: {
      width: 'auto',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row'
    },
    colSmallHeader: {
      width: '10%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colLargeHeader: {
      width: '70%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderBottomColor: '#000',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colSmall: {
      width: '10%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    colLarge: {
      width: '70%',
      borderStyle: 'solid',
      borderColor: '#bfbfbf',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCellHeader: {
      margin: 'auto',
      fontSize: 12,
      fontWeight: 500
    },
    tableCell: {
      fontSize: 10
    },
    tableMoneyCell: {
      fontSize: 10,
      textAlign: 'right'
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey'
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey'
    }
  });

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <Text style={styles.header} fixed>
          {`${toBrazilDate(props.startDate)} a ${toBrazilDate(props.endDate)}`}
        </Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Data</Text>
            </View>
            <View style={styles.colLargeHeader}>
              <Text style={styles.tableCellHeader}>Cliente</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Status</Text>
            </View>
            <View style={styles.colSmallHeader}>
              <Text style={styles.tableCellHeader}>Valor</Text>
            </View>
          </View>
          {props.data.map((x) => (
            <View style={styles.tableRow} key={x.id} wrap={false}>
              <View style={styles.colSmall}>
                <Text style={styles.tableCell}>{toBrazilDateTime(x.createdAt)}</Text>
              </View>
              <View style={styles.colLarge}>
                <Text style={styles.tableCell}>{x.description}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{x.status}</Text>
              </View>
              <View style={styles.colSmall}>
                <Text style={styles.tableMoneyCell}>{toDecimal(x.value)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}\n${toBrazilDateTime(new Date())}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
}
