/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, Link, Breadcrumbs
} from '@mui/material';
import { connect } from 'react-redux';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

function TabPanel(props: any) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function PageTitle(props: any) {
  const { enableBreadcrumb } = props;
  return (
    <>
      {enableBreadcrumb && (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link color="inherit" href="/">
            Dashboard
          </Link>
          <Link color="inherit" href="/getting-started/installation/">
            Franquias
          </Link>
          <Typography color="textPrimary">Nova</Typography>
        </Breadcrumbs>
      )}
    </>
  );
}
const mapStateToProps = (state: any) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);
