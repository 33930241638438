import React from 'react';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDFDownloadLink } from '@react-pdf/renderer';

interface OwnProps {
  fileName?: string;
  document: React.ReactElement;
}

const component = (props: OwnProps) => (
  <PDFDownloadLink document={props.document} fileName={props.fileName}>
    {({ loading }) => (loading ? 'Carregando...' : (
      <IconButton
        size="small"
        color="primary"
        className="text-primary"
        title="Exportar para PDF"
      >
        <FontAwesomeIcon icon={['far', 'file-pdf']} className="font-size-lg" />
      </IconButton>
    ))}
  </PDFDownloadLink>
);

export default component;
