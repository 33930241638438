import { createTheme } from '@mui/material/styles';

import typography from './typography';
import colors from './colors';

const customTheme = createTheme({
  palette: {
    primary: {
      main: colors.primary
    },
    grey: {
      300: colors.inheritDefault1,
      A100: colors.inheritDefault2
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: colors.red
    },
    success: {
      main: colors.green
    },
    warning: {
      main: colors.orange
    },
    contrastThreshold: 3,
    tonalOffset: 0.1
  },
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px'
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.second,
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: colors.second
        }
      }
    }
  },
  typography
});

export default customTheme;
