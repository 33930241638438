import {
  MutableRefObject, useEffect, useReducer, useRef
} from 'react';
import {
  Query,
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentSnapshot,
  queryEqual,
  snapshotEqual,
  endBefore,
  limit,
  limitToLast,
  onSnapshot,
  query,
  startAfter
} from 'firebase/firestore';

export interface PaginationOptions {
      limit?: number;
      params?: string;
    }

    interface State<T extends DocumentData> {
      query: Query | undefined;
      queryRef: undefined | MutableRefObject<Query | undefined>;
      lastQuery: Query | undefined;
      firstDocRef: undefined | MutableRefObject<QueryDocumentSnapshot | undefined>;
      docs: QueryDocumentSnapshot[];
      firstDoc: QueryDocumentSnapshot | undefined;
      lastDoc: QueryDocumentSnapshot | undefined;
      prevQuery: Query | undefined;
      nextQuery: Query | undefined;
      items: (T & Pick<DocumentSnapshot, 'id'>)[];
      isLoading: boolean;
      isStart: boolean;
      isEnd: boolean;
      limit: number;
      total: number;
      current: number;
      currentTotal: number;
      params: string;
    }

    type ActionBase<K, V = void> = V extends void ? { type: K } : { type: K } & V;

    type Action =
      | ActionBase<
          'SET-QUERY',
          {
            payload: {
              query: Query;
              queryRef: MutableRefObject<Query | undefined>;
              firstDocRef: MutableRefObject<QueryDocumentSnapshot | undefined>;
              limit: number;
              params: string;
            };
          }
        >
      | ActionBase<
          'LOAD',
          {
            payload: {
              value: QuerySnapshot;
              query: Query;
            };
          }
        >
      | ActionBase<'PREV'>
      | ActionBase<'NEXT'>
      | ActionBase<
                  'SEARCH',
                  {
                    payload: {
                      params: string;
                    };
                  }
        >;

const getReducer = <T extends DocumentData>() => (state: State<T>, action: Action): State<T> => {
  switch (action.type) {
    case 'SET-QUERY': {
      const {
        query: queryObj, queryRef, firstDocRef, limit: limitNum, params
      } = action.payload;
      return {
        ...state,
        query: query(queryObj, limit(limitNum)),
        queryRef,
        firstDocRef,
        limit: limitNum,
        isLoading: true,
        params
      };
    }

    case 'LOAD': {
      const { value } = action.payload;
      const { docs } = value;

      const items = docs.map((doc) => {
        const {
          name, email, phoneNumber, photoURL, claims, address, cpf, createdAt, disabledAt, dtBirth, updatedAt
        } = doc.data();

        return {
          ...(doc.data() as T),
          id: doc.id,
          uid: doc.id,
          name,
          email,
          phoneNumber,
          photoURL,
          address,
          claims,
          cpf,
          createdAt: createdAt ? createdAt.toDate() : null,
          disabledAt: disabledAt ? disabledAt.toDate() : null,
          dtBirth: dtBirth ? dtBirth.toDate() : null,
          emailVerified: null,
          lastSignInTime: updatedAt ? updatedAt.toDate() : null,
          subsidiaryId: doc.data().subsidiaryId
        };
      });

      const firstDoc = docs[0];
      const lastDoc = docs[docs.length - 1];
      const queryFromRef = state.queryRef ? state.queryRef.current : undefined;
      const prevQuery = queryFromRef && firstDoc ? query(queryFromRef, endBefore(firstDoc), limitToLast(state.limit)) : state.lastQuery;
      const nextQuery = queryFromRef && lastDoc ? query(queryFromRef, startAfter(lastDoc), limit(state.limit)) : state.nextQuery;

      // eslint-disable-next-line prefer-destructuring
      const firstDocRef = state.firstDocRef;
      if (firstDocRef && firstDocRef.current === undefined) {
        firstDocRef.current = firstDoc;
      }

      return {
        ...state,
        params: state.params,
        docs,
        lastQuery: items.length > 0 ? state.query : undefined,
        isLoading: false,
        firstDoc,
        firstDocRef,
        lastDoc,
        prevQuery,
        nextQuery,
        items,
        isStart: (firstDoc && firstDocRef?.current && snapshotEqual(firstDoc, firstDocRef.current)) || false,
        isEnd: items.length < state.limit,
        total: items.length,
        currentTotal: (items.length + state.current) - 1
      };
    }

    case 'NEXT': {
      return {
        ...state,
        isLoading: true,
        query: state.nextQuery,
        current: state.current + 10
      };
    }

    case 'PREV': {
      return {
        ...state,
        isLoading: true,
        query: state.prevQuery,
        current: state.current - 10
      };
    }

    case 'SEARCH': {
      return {
        ...state,
        isLoading: true,
        params: action.payload.params
      };
    }

    default: {
      return defaultGuard(state, action);
    }
  }
};

const initialState = {
  query: undefined,
  queryRef: undefined,
  lastQuery: undefined,
  firstDocRef: undefined,
  docs: [],
  firstDoc: undefined,
  lastDoc: undefined,
  prevQuery: undefined,
  nextQuery: undefined,
  items: [],
  isLoading: true,
  isStart: true,
  isEnd: false,
  limit: 10,
  total: 0,
  current: 1,
  currentTotal: 0,
  params: ''
};

// eslint-disable-next-line no-unused-vars
const defaultGuard = <S>(state: S, a: never) => state;

export const usePagination = <T extends DocumentData>(firestoreQuery: Query, options: PaginationOptions) => {
  const [state, dispatch] = useReducer(getReducer<T>(), initialState);
  const queryRef = useRef<Query | undefined>(undefined);
  const firstDocRef = useRef<QueryDocumentSnapshot | undefined>(undefined);

  const { limit: limitOpt = 10, params: paramsText = '' } = options;

  useEffect(() => {
    if (firestoreQuery !== undefined) {
      if (queryRef?.current && queryEqual(firestoreQuery, queryRef.current) && limitOpt === state.limit) {
        return;
      }

      queryRef.current = firestoreQuery;
      firstDocRef.current = undefined;
      dispatch({
        type: 'SET-QUERY',
        payload: {
          query: firestoreQuery,
          queryRef,
          firstDocRef,
          limit: limitOpt,
          params: paramsText
        }
      });
    }
  }, [firestoreQuery, limitOpt, state.limit]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (state.query !== undefined) {
      const unsubscribe = onSnapshot(state.query, (snap) => {
        if (state.query) {
          dispatch({
            type: 'LOAD',
            payload: { value: snap, query: state.query }
          });
        }
      });

      return () => unsubscribe();
    }
  }, [state.query]);

  return {
    docs: state.docs,
    items: state.items,
    isLoading: state.isLoading,
    isStart: state.isStart,
    isEnd: state.isEnd,
    total: state.total,
    current: state.current,
    currentTotal: state.currentTotal,
    getPrev: () => dispatch({ type: 'PREV' }),
    getNext: () => dispatch({ type: 'NEXT' }),
    setSearch: (paramsSearch: string) => dispatch({ type: 'SEARCH', payload: { params: paramsSearch } })
  };
};
