/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum PaymentType {
  Undefined = 0,
  Money = 1,
  Debit = 2,
  Credit = 3,
  Pix = 4,
  Picpay = 5,
  Faturado = 6,
  Prepago = 7
}

const PaymentTypeText: EnumDictionary<PaymentType, string> = {
  [PaymentType.Undefined]: '',
  [PaymentType.Money]: 'Dinheiro',
  [PaymentType.Debit]: 'Débito',
  [PaymentType.Credit]: 'Crédito',
  [PaymentType.Pix]: 'Pix',
  [PaymentType.Picpay]: 'Picpay',
  [PaymentType.Faturado]: 'Faturado',
  [PaymentType.Prepago]: 'Prépago'
};

const PaymentTypeList: Dictionary[] = [
  { key: 1, value: 'Dinheiro' },
  { key: 2, value: 'Débito' },
  { key: 3, value: 'Crédito' },
  { key: 4, value: 'Pix' },
  { key: 5, value: 'Picpay' },
  { key: 6, value: 'Faturado' },
  { key: 7, value: 'Prépago' }
];

export { PaymentType as default, PaymentTypeText, PaymentTypeList };
