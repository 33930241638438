/* eslint-disable max-len */
import { Button, Dialog, Grid } from '@material-ui/core';
import React from 'react';
import Driver from '../../../models/interfaces/driver';
import Subsidiary from '../../../models/interfaces/subsidiary';
import TermService from '../../../services/termService';

export interface OwnProps {
    open: boolean;
    data: any | undefined;
    subsidiary: Subsidiary | undefined;
    driver: Driver;
    handleClose: () => void;
}
export default function component(props: OwnProps) {
  const {
    open, handleClose
  } = props;
  if (props.data === undefined) return <></>;
  const onClose = () => {
    handleClose();
  };
  const onPrint = () => {
    window.print();
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>

        <div className="p-4">
          <h6 className="font-weight-bold mt-4 text-center">
            {props.data.header}
          </h6>
          <p className="mb-3 justify-content-left mt-4">
            {TermService.setParams(props.data?.title, props.driver, props.subsidiary)}
          </p>
          <Grid container spacing={3}>
            <>
              {props.data.paragraphs.map((x: any) => (
                <Grid key={x.title} item xs={12}>
                  <div className="d-flex align-items-center flex-column flex-sm-row">
                    <span className="font-size-md font-weight-bold">{x.title}</span>
                  </div>
                  <div className="d-flex flex-column flex-sm-row">
                    <p className="mb-0 align-items-start justify-content-between flex-sm-row">
                      {x.text}
                    </p>
                  </div>
                </Grid>
              ))}
            </>
          </Grid>
          <p className="mb-3 justify-content-left mt-4">
            {`${props.subsidiary?.address.city}-${props.subsidiary?.address?.state}, ${props.driver?.createdAt?.getDate()} de ${TermService.getMonthName(props.driver?.createdAt?.getMonth())} de ${props.driver?.createdAt?.getFullYear()}.`}
          </p>
          <div className="pt-4 p-6">
            {/* onClick={() => completeCall(props.data!.id)} */}
            <Button onClick={onPrint} color="primary" variant="contained" className="mx-1">
              <span className="btn-wrapper--label">Imprimir</span>
            </Button>
            <Button onClick={onClose} color="primary" variant="contained" className="mx-1">
              <span className="btn-wrapper--label">Fechar</span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
