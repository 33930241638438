import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import Table from '../../../../components/table';
import subsidiaryService from '../../../../services/subsidiaryService';
import {
  orderBy, query, subsidiaryCollection
} from '../../../../config/firebase';
import { usePagination } from './usePaginationList';
import FilterHelper from '../../../../utils/filterHelper';

export default function index() {
  const history = useHistory();
  const [total, setTotal] = React.useState(0);
  const [enableSearch, setEnableSearch] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<any>([]);

  const {
    items: itemsPag,
    isLoading,
    getPrev,
    getNext,
    current,
    currentTotal,
    isStart,
    isEnd
  } = usePagination(
    query(subsidiaryCollection, orderBy('description', 'asc')),
    {
      limit: 10
    }
  );

  useEffect(() => {
    getTotal();
  }, []);

  const getTotal = async () => {
    await subsidiaryService.getAllCount().then((item: any) => {
      setTotal(item.total);
    });
  };

  const searchItem = useCallback(async (params: string) => {
    // const q = query(subsidiaryCollection, orderBy('description', 'asc'), where('description', '==', params));
    // const snapshot = await getDocs(q);
    const snapshot = await FilterHelper.prepareSnapshot('description', params, subsidiaryCollection, null, null, null, null);

    const results = snapshot?.docs.map((snap) => {
      const {
        description, owner, address, disabledAt
      } = snap.data();

      return {
        id: snap.id,
        owner: owner.name,
        description,
        active: !disabledAt,
        lat: address.location.latitude,
        lng: address.location.longitude
      };
    });

    const count = results?.length ? results.length : 0;

    setEnableSearch(true);
    setItems(results);
    setTotal(count);
  }, []);

  const onTableChange = useCallback((action: string, state: any) => {
    if (action === 'search') {
      if (state.searchText !== null) {
        searchItem(state.searchText);
      }
    }

    if (action === 'onSearchClose') {
      setEnableSearch(false);
      setItems([]);
      getTotal();
    }

    if (action === 'filterChange') {
      // getFilter(state.filterList);
    }
  }, []);

  const columns = [
    {
      name: 'id',
      options: { display: false }
    },
    {
      name: 'owner',
      options: { display: false }
    },
    {
      name: 'description',
      label: 'Nome',
      options: {
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                <div>
                  {value}
                  <span className="text-black-50 d-block">{tableMeta.rowData[1]}</span>
                </div>
              </div>
            );
          }
          return (<Skeleton />);
        }
      }
    },
    {
      name: 'active',
      label: 'Status',
      options: {
        customBodyRender: (value: string) => {
          if (value !== undefined) {
            return (
              <div className={`badge badge-${value ? 'success' : 'danger'}`}>
                {value ? 'Ativo' : 'Inativo'}
              </div>
            );
          }
          return (<Skeleton />);
        }
      }
    }
  ];

  const onRowClick = (rowData: string[]) => {
    const id = rowData[0];
    if (id) {
      history.push(`/subsidiaries/${id}`);
    }
  };

  return (
    <Table
      title="Franquias"
      createUrl="subsidiaries/create"
      data={enableSearch ? items : itemsPag}
      columns={columns}
      filter={false}
      onRowClick={onRowClick}
      count={total}
      isLoading={isLoading}
      pagination={false}
      serverSide
      onTableChange={onTableChange}
      getPrev={getPrev}
      getNext={getNext}
      current={current}
      currentTotal={currentTotal}
      isStart={isStart}
      isEnd={isEnd}
      enableSearch={enableSearch}
    />
  );
}
