import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar, Skeleton } from '@mui/material';

import Table from '../../../../components/table';
import userService from '../../../../services/userService';
import {
  orderBy, query, userCollection, where
} from '../../../../config/firebase';
import { usePagination } from './usePaginationList';
import FilterHelper from '../../../../utils/filterHelper';

export default function index() {
  const history = useHistory();
  const [total, setTotal] = React.useState(0);
  const [enableSearch, setEnableSearch] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<any>([]);

  const {
    items: itemsPag,
    isLoading,
    getPrev,
    getNext,
    current,
    currentTotal,
    isStart,
    isEnd
  } = usePagination(
    query(userCollection, orderBy('claims.manager'), where('claims.manager', '==', true)),
    {
      limit: 10
    }
  );

  useEffect(() => {
    getTotal();
  }, []);

  const getTotal = async () => {
    await userService.getAllManagerAdminCount('manager').then((item: any) => {
      setTotal(item.total);
    });
  };

  const searchItem = useCallback(async (params: string) => {
    // const q = query(userCollection, orderBy('name', 'asc'), where('name', '==', params), where('claims.manager', '==', true));
    // const snapshot = await getDocs(q);
    const snapshot = await FilterHelper.prepareSnapshot('name', params, userCollection, 'claims.manager', true);

    const results = snapshot?.docs.map((snap) => {
      const {
        name, email, phoneNumber, photoURL, claims, address, cpf, createdAt, disabledAt, dtBirth, updatedAt
      } = snap.data();

      return {
        id: snap.id,
        uid: snap.id,
        name,
        email,
        phoneNumber,
        photoURL,
        address,
        claims,
        cpf,
        createdAt: createdAt ? createdAt.toDate() : null,
        disabledAt: disabledAt ? disabledAt.toDate() : null,
        dtBirth: dtBirth ? dtBirth.toDate() : null,
        emailVerified: null,
        lastSignInTime: updatedAt ? updatedAt.toDate() : null,
        subsidiaryId: snap.data().subsidiaryId
      };
    });

    const count = results?.length ? results.length : 0;

    setEnableSearch(true);
    setItems(results);
    setTotal(count);
  }, []);

  const onTableChange = useCallback((action: string, state: any) => {
    if (action === 'search') {
      if (state.searchText !== null) {
        searchItem(state.searchText);
      }
    }

    if (action === 'onSearchClose') {
      setEnableSearch(false);
      setItems([]);
      getTotal();
    }

    if (action === 'filterChange') {
      // getFilter(state.filterList);
    }
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'photoURL',
      label: 'Foto',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'name',
      label: 'Nome',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string, tableMeta: any) => {
          if (value) {
            return (
              <div className="d-flex align-items-center">
                <Avatar alt="" className="mr-2" src={tableMeta.rowData[1]}>{value.substr(0, 1)}</Avatar>
                <div>{value}</div>
              </div>
            );
          }
          return (<Skeleton />);
        }
      }
    }
  ];

  const onRowClick = (rowData: string[]) => {
    const id = rowData[0];
    if (id) {
      history.push(`/users/${id}`);
    }
  };

  return (
    <Table
      title="Gerentes"
      createUrl="managers/create"
      data={enableSearch ? items : itemsPag}
      columns={columns}
      filter={false}
      onRowClick={onRowClick}
      count={total}
      isLoading={isLoading}
      serverSide
      onTableChange={onTableChange}
      pagination={false}
      getPrev={getPrev}
      getNext={getNext}
      current={current}
      currentTotal={currentTotal}
      isStart={isStart}
      isEnd={isEnd}
      enableSearch={enableSearch}
    />
  );
}
