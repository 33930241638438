/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum StatusDriver {
  Undefined = 0,
  Pending = 1,
  Denied = 2,
  Active = 3,
  Inactive = 4
}

enum EnableDriver {
  Yes = 1,
  Not = 0
}

const StatusDriverText: EnumDictionary<StatusDriver, string> = {
  [StatusDriver.Undefined]: 'Indefinido',
  [StatusDriver.Pending]: 'Pendente',
  [StatusDriver.Denied]: 'Negado',
  [StatusDriver.Active]: 'Ativo',
  [StatusDriver.Inactive]: 'Inativo'
};

const EnableDriverText: EnumDictionary<EnableDriver, string> = {
  [EnableDriver.Yes]: 'Ativo',
  [EnableDriver.Not]: 'Inativo'
};

const StatusDriverList: Dictionary[] = [
  { key: 0, value: 'Indefinido' },
  { key: 1, value: 'Pendente' },
  { key: 2, value: 'Negado' },
  { key: 3, value: 'Ativo' },
  { key: 4, value: 'Inativo' }
];

const EnableDriverList: Dictionary[] = [
  { key: 1, value: 'Ativo' },
  { key: 2, value: 'Inativo' }
];

export {
  StatusDriver as default, StatusDriverText, EnableDriver, EnableDriverText, StatusDriverList, EnableDriverList
};
