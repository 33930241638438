/* eslint-disable no-underscore-dangle */
import _ from 'lodash';
import { subDays } from 'date-fns';
import { validate as uuidValidate } from 'uuid';

import { DocumentData, Query, getCountFromServer } from 'firebase/firestore';
import {
  customerCollection, doc, getDateNow, getDoc,
  getDocs, limit, query, setDoc, startAfter, orderBy, subsidiaryCollection, updateDoc, where, startAt, endAt
} from '../config/firebase';
import Address from '../models/address';
import Customer, { Create, IntegrationApi, Update } from '../models/interfaces/customer';
import { titleCase } from '../utils/stringHelper';
import authService from './authService';
import { getGeocoding, getGeoPoint } from './geolocateService';
import subsidiaryService from './subsidiaryService';
import api from '../config/functionsApi';
import apiCallMicroservice, { BoazRemoteCallService } from '../config/callMicroserviceApi';
import { ISimpleRow } from '../pages/dashboard/components/listSimpleCard';
import StringsService from './stringsService';
import SearchService from './searchService';
// import SupabaseService from './supabase';

// const supabaseService = new SupabaseService();
const countTotal = async (subsidiaryId: string | undefined | null) => {
  let total;
  const q = query(customerCollection);
  const c = query(q);
  if (subsidiaryId) {
    total = await getCountFromServer(query(c, where('subsidiary', '==', subsidiaryId), where('disabledAt', '==', null)));
  } else {
    total = await getCountFromServer(query(c, where('disabledAt', '==', null)));
  }
  return total?.data().count;
};

const countTotalWithMemoize = _.memoize(countTotal, (...args) => _.values(args).join('_'));
const searchService = new SearchService();

export default {
  async execQuery(q: Query<DocumentData>) {
    return getDocs(q).then(async (querySnapshot) => {
      const result = querySnapshot.docs.map((snap) => {
        const model = snap.data() as Customer;
        model.address = model.address != null ? new Address(model.address) : null;
        return {
          ...model,
          id: snap.id,
          disabled: model.disabledAt !== null,
          displayName: model.name,
          photoURL: model.photoURL ?? 'https://www.gasso.com/wp-content/uploads/2017/04/noimage.jpg',
          createdAt: snap.data().createdAt?.toDate()
        };
      });
      const res = _.sortBy(result, [(x) => x.displayName?.toLowerCase()]);
      return res;
    });
  },

  async getAll(subsidiaryId: string | undefined | null = null, getAll = true, search: string | null = null, action: string | null = null, field: string | null = null) {
    let q = query(customerCollection);
    if (!action && !getAll) {
      const createdAt = subDays(new Date(), 7);
      q = query(q, orderBy('createdAt'), startAt(createdAt));
    } else {
      q = query(q, orderBy('name'));
    }

    if (field) {
      q = query(customerCollection, where(field, 'array-contains', search));
    } else if (search) {
      if (search.length < 3) return [];
      q = query(q, startAt(search), endAt(`${search}\uf8ff`));
    }

    // if (!getAll || action === 'search') q = query(q, limit(10));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }
    return this.execQuery(q);
  },

  async getAllCount(subsidiaryId: string | null | undefined) {
    let total;
    const q = query(customerCollection);
    const c = query(q);

    if (subsidiaryId) {
      total = await getCountFromServer(query(c, where('subsidiary', '==', subsidiaryId)));
    } else {
      total = await getCountFromServer(c);
    }

    return { total: total?.data().count };
  },

  async getByPhone(subsidiaryId: string | undefined | null = null, phoneNumber: string | null) {
    let q = query(customerCollection);
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }
    const phone = `+55${phoneNumber}`;

    q = query(q, where('phoneNumber', '==', phone));
    return this.execQuery(q);
  },

  async search(search: string, hitsPerPage: number, currentPage: number, filters: string | null = null, cities: any[] | null) {
    const param = search?.length ? search : null;
    const indexName: string = process.env.REACT_APP_ADMIN_ALGOLIA_API_INDEX_CUSTOMER_NAME || 'clients';
    console.log('REACT_APP_ADMIN_ALGOLIA_API_INDEX_CUSTOMER_NAME', indexName);
    // const filters = 'available = 1 AND (category:Book OR NOT category:Ebook) AND _tags:published AND publication_date:1441745506 TO 1441755506 AND inStock > 0 AND author:"John Doe"';
    let resp: any = null;
    resp = await searchService.libSearch(indexName, param, hitsPerPage, currentPage, filters);
    if (resp?.hits.length) {
      const results: any = resp?.hits.map((hit: any) => {
        const {
          businessName, name, email, phoneNumber, photoURL, registryNumber, responsible, subsidiary, createdAt, disabledAt
        } = hit;

        return {
          id: hit.objectID,
          businessName,
          name: hit._highlightResult?.name?.matchedWords?.length ? hit._highlightResult.name.value : name,
          email: hit._highlightResult?.email?.matchedWords?.length ? hit._highlightResult.email.value : email,
          phoneNumber: hit._highlightResult?.phoneNumber?.matchedWords?.length ? hit._highlightResult.phoneNumber.value : phoneNumber,
          photoURL: photoURL ?? 'https://www.gasso.com/wp-content/uploads/2017/04/noimage.jpg',
          registryNumber,
          disabledAt: disabledAt ? new Date(disabledAt) : null,
          createdAt: createdAt ? new Date(createdAt) : null,
          subsidiary,
          subsidiaryName: cities?.find((row: any) => row.id === subsidiary)?.description ?? null,
          responsible
        };
      });

      return {
        items: results,
        isStart: resp?.page === 0,
        isEnd: resp?.nbPages === resp?.page,
        totalHits: resp?.nbHits,
        currentPage: resp?.page,
        hitsPerPage: resp?.hitsPerPage,
        nbPages: resp?.nbPages
      };
    }
    return {
      items: [],
      isStart: true,
      isEnd: true,
      totalHits: 0,
      current: 0,
      currentPage: 0,
      nbPages: 0
    };
  },

  async getById(id: string): Promise<Customer> {
    const docRef = doc(customerCollection, id);
    const result = await getDoc(docRef);
    if (result.exists()) {
      const model = result.data() as Customer;
      const userAuth = await authService.getByIdOrEmailAsync(id);
      if (userAuth === null) throw new Error('Firebase user not found');

      const subsidiary = await subsidiaryService.getByIdAsync(model.subsidiary);

      model.id = id;
      model.disabled = model.disabledAt !== null;

      model.metadata = userAuth.metadata;
      model.displayName = userAuth.displayName;

      model.disabledAt = result.data()?.disabledAt?.toDate();
      model.address = model.address != null ? new Address(model.address) : null;

      model.subsidiary = subsidiary;
      if (model.ifood) {
        model.ifood.createdAt = result.data()?.ifood.createdAt.toDate();
        model.ifood.disabledAt = result.data()?.ifood.disabledAt?.toDate();
      }

      if (model.integration && model.integration.registered) {
        model.integration.registeredAt = result.data()?.integration?.registeredAt.toDate();
        model.integration.disabledAt = result.data()?.integration.disabledAt?.toDate();
      }

      return model;
    }

    return Promise.reject();
  },

  async create(model: Create, subsidiaryId: string) {
    const location = await getGeocoding(model.address);
    if (location === null || model.address.ibge === null) {
      throw new Error('Problemas ao tentar obter coordenadas, verifique o endereço e se digitou um CEP válido');
    }

    return authService.create(model.email, model.name).then((response) => {
      const nameNormalized = StringsService.removeAccent(model.name.toLowerCase());
      const nameIndexed = nameNormalized.split(' ')?.filter((row: any) => row.length > 0);
      const data = {
        subsidiary: subsidiaryId,
        businessName: titleCase(model.businessName),
        responsible: titleCase(model.responsible),
        contract: model.contract,
        registryNumber: model.registryNumber?.replace(/[^0-9]/g, ''),
        categories: model.categories.map((x) => Number(x)),
        paymentTypes: model.paymentTypes.map((x) => Number(x)),
        observations: model.observations,
        observationsToDriver: model.observationsToDriver,
        email: model.email,
        phoneNumber: `+${model.phoneNumber?.replace(/[^0-9]/g, '')}`,
        photoURL: null,
        address: { ...model.address, location },
        createdAt: getDateNow(),
        disabledAt: null,
        document: null,
        name: model.name,
        nameIndexed
      };
      setDoc(doc(customerCollection, response.data), data);
    });
  },

  async update(model: Update, id: string) {
    const customerRef = doc(customerCollection, id);
    const location = await getGeocoding(model.address);
    if (location === null || model.address.ibge === null) {
      throw new Error('Problemas ao tentar obter coordenadas, verifique o endereço e se digitou um CEP válido');
    }

    const nameNormalized = StringsService.removeAccent(model.name.toLowerCase());
    const nameIndexed = nameNormalized.split(' ')?.filter((row: any) => row.length > 0);

    await updateDoc(customerRef, {
      subsidiary: model.subsidiary.key ? model.subsidiary.key : model.subsidiary,
      businessName: titleCase(model.businessName),
      responsible: titleCase(model.responsible),
      contract: model.contract,
      registryNumber: model.registryNumber?.replace(/[^0-9]/g, ''),
      categories: model.categories.map((x) => Number(x)),
      paymentTypes: model.paymentTypes.map((x) => Number(x)),
      observations: model.observations,
      observationsToDriver: model.observationsToDriver,
      email: model.email,
      phoneNumber: `+${model.phoneNumber?.replace(/[^0-9]/g, '')}`,
      name: model.name,
      nameIndexed,
      address: { ...model.address, location }
    });

    return authService.updateAsync(model.email, model.name, model.phoneNumber, id);
  },

  async updateLocationAsync(id: string, latitude: number, longitude: number) {
    const model = await this.getById(id);
    if (!model) throw new Error('Customer not found');

    const customerRef = doc(customerCollection, id);

    return updateDoc(customerRef, {
      address: {
        ...model.address,
        location: getGeoPoint(latitude, longitude)
      }
    });
  },

  async changeStatus(id: string) {
    const modelRef = doc(customerCollection, id);
    const result = await getDoc(modelRef);
    if (result.exists()) {
      const { disabledAt } = result.data() as Customer;
      const disabled = disabledAt === null || disabledAt === undefined;
      return authService.disableAsync(disabled, id).then((data) => {
        if (data.status === 204) {
          updateDoc(modelRef, {
            disabledAt: disabled ? getDateNow() : null
          });
        }
      });
    }

    return Promise.reject();
  },

  async onOffIfood(id: string) {
    const modelRef = doc(customerCollection, id);
    const result = await getDoc(modelRef);
    if (result.exists()) {
      const customer = result.data() as Customer;

      const newIfood = {
        ...customer.ifood,
        disabledAt: customer.ifood?.disabledAt ? null : getDateNow(),
        disabledByAdmin: !customer.ifood?.disabledAt
      };
      // eslint-disable-next-line no-return-await
      updateDoc(modelRef, {
        ifood: newIfood
      });
      return newIfood.disabledAt;
    }

    return Promise.reject();
  },

  async onOffIntegration(id: string) {
    const modelRef = doc(customerCollection, id);
    const result = await getDoc(modelRef);
    if (result.exists()) {
      const customer = result.data() as Customer;
      const newIntegration: IntegrationApi = {
        activated: !customer.integration?.activated,
        disabledAt: !customer.integration?.activated ? null : getDateNow(),
        registeredAt: customer.integration?.registeredAt,
        registered: customer.integration?.registered,
        system: customer.integration?.system,
        secret: customer.integration?.secret,
        defaultPayment: customer.integration?.defaultPayment ?? null,
        webhookLocationParams: {
          url: customer.integration?.webhookLocationParams?.url,
          headers: customer.integration?.webhookLocationParams?.headers,
          params: customer.integration?.webhookLocationParams?.params,
          method: customer.integration?.webhookLocationParams?.method
        },
        webhookStatusParams: {
          url: customer.integration?.webhookStatusParams?.url,
          headers: customer.integration?.webhookStatusParams?.headers,
          params: customer.integration?.webhookStatusParams?.params,
          method: customer.integration?.webhookStatusParams?.method
        }
      };
      // eslint-disable-next-line no-return-await
      updateDoc(modelRef, {
        integration: newIntegration
      });
      return newIntegration;
    }

    return Promise.reject();
  },

  async count(subsidiaryId: string | undefined | null) {
    return countTotalWithMemoize(subsidiaryId);
  },

  async paginate(limitMax: number = 50) {
    const result = await getDocs(query(customerCollection, limit(limitMax)));

    const data: Customer[] = [];
    let lastKey;
    result.forEach((snap) => {
      const item = snap.data();
      data.push({ ...item as Customer, id: snap.id, createdAt: item.createdAt.toDate() });
      lastKey = snap.data().createdAt;
    });

    return { data, lastKey };
  },

  async fetchNextData(key: any, limitMax: number = 50) {
    const q = query(customerCollection, startAfter(key), limit(limitMax));
    const result = await getDocs(q);

    const data: Customer[] = [];
    let lastKey;
    result.forEach((snap) => {
      const item = snap.data();
      data.push({ ...item as Customer, id: snap.id, createdAt: item.createdAt.toDate() });
      lastKey = snap.data().createdAt;
    });

    return { data, lastKey };
  },

  async changePhoto(photoURL: string, id: string) {
    return authService.changePhoto(photoURL, id).then((data) => {
      if (data.status === 204) {
        const customerRef = doc(customerCollection, id);
        return updateDoc(customerRef, { photoURL });
      }
      throw new Error('Image not updated');
    });
  },

  async getLastDays(days: number, subsidiaryId: string | undefined | null) {
    if (!subsidiaryId) return [];
    const maxDate = new Date();
    const minDate = subDays(new Date(maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate(), 0, 0, 0), days - 1);
    // Use index in firebase
    let q = query(customerCollection, where('createdAt', '>=', minDate));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }

    const result = await getDocs(q);

    const data: Customer[] = [];
    result.forEach((snap: any) => {
      const item = snap.data();
      data.push({ ...item as Customer, id: snap.id, createdAt: item.createdAt.toDate() });
    });

    return _.sortBy(data, (x) => x.createdAt);
  },

  async delete(id: string) {
    return api.delete(`clients/${id}`).then(() => true).catch((e) => {
      if (e.response.status === 409) return false;

      throw new Error(e.response?.data?.message ?? 'Ops, aconteceu algo inesperado, por favor tente novamente');
    });
  },

  async countByCurrentMonth(subId: string | undefined | null = null) {
    let subsidiaryId = subId;
    if (subsidiaryId && uuidValidate(subsidiaryId)) {
      const newSub = await subsidiaryService.getByIdAsync(subsidiaryId);

      const subsidiariesByCNPJ = await getDocs(query(subsidiaryCollection, where('cnpj', '==', newSub.cnpj)));
      if (subsidiariesByCNPJ.size === 1) {
        subsidiaryId = subsidiariesByCNPJ.docs[0].id;
      }
    }

    const maxDate = new Date();
    const minDate = new Date(maxDate.getFullYear(), maxDate.getMonth(), 1, 0, 0, 0);
    let q = query(customerCollection, where('createdAt', '>=', minDate));
    if (subsidiaryId) {
      q = query(q, where('subsidiary', '==', subsidiaryId));
    }
    const result = await getDocs(q);

    return result.size;
  },

  async getTop(subsidiary: string | undefined | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiary || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID
      }
    };
    const response = await apiCallMicroservice.get('calls/topclients', config);
    // const response = await supabaseService.getTopClients(subsidiary);
    const data: ISimpleRow[] = [];
    response?.data.forEach((item: any) => {
      data.push({
        id: item.clientid,
        name: item.clientname,
        photoURL: item.imageurl,
        extraInfo: subsidiary ? '' : item.subsidiaryname
      });
    });
    return data;
  },

  async getTopRefuseds(subsidiary: string | undefined | null) {
    const auth = await BoazRemoteCallService.getNewToken();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      },
      params: {
        subsidiaryId: subsidiary || '',
        environment: process.env.REACT_APP_ADMIN_PROJECT_ID
      }
    };

    const response = await apiCallMicroservice.get('calls/topclientsrefused', config);
    const data: ISimpleRow[] = [];
    response.data.forEach((item: any) => {
      data.push({
        id: item.clientid,
        name: item.clientname,
        photoURL: item.photourl,
        extraInfo: subsidiary ? `Qtd. de chamadas recusadas ${item.refuses}` : item.subsidiaryname
      });
    });
    return data;
  }
};
