/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import Dictionary from '../interfaces/dictionary';
import { EnumDictionary } from '../interfaces/enumDictionary';

enum DeviceType {
  Undefined = 0,
  Web = 1,
  Mobile = 2,
  Watch = 3,
  Integration = 4,
  IntegrationOwnerApi = 5
}

const DeviceTypeText: EnumDictionary<DeviceType, string> = {
  [DeviceType.Undefined]: '',
  [DeviceType.Web]: 'Web',
  [DeviceType.Mobile]: 'Mobile',
  [DeviceType.Watch]: 'Watch',
  [DeviceType.Integration]: 'Integração',
  [DeviceType.IntegrationOwnerApi]: 'Integração API'
};

const DeviceTypeList: Dictionary[] = [
  { key: 1, value: 'Web' },
  { key: 2, value: 'Mobile' },
  // { key: 3, value: 'Watch' },
  { key: 4, value: 'Integracão' }
];

export { DeviceType as default, DeviceTypeText, DeviceTypeList };
