import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import {
  MapContainer, TileLayer, Marker, Popup
} from 'react-leaflet';
import L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';

import Loading from '../../components/loading';
import callService from '../../services/callService';
import unknownMarker from '../../assets/images/customer-marker.png';
import officeMarker from '../../assets/images/office-marker.png';
import refusedMarker from '../../assets/images/refused-marker.png';
import acceptedMarker from '../../assets/images/accepted-marker.png';
import ongoingMarker from '../../assets/images/ongoing-marker.png';
import canceledMarker from '../../assets/images/canceled-marker.png';
import completeMarker from '../../assets/images/complete-marker.png';
import { toBrazilDateTime } from '../../components/mask';
import { convertStatusToBrazilian } from '../../models/enums/statusCallHistory';
import logoIcon from '../../assets/images/logo-icon.png';

interface Row {
  id: string;
  description: string;
  html: string;
  iconUrl: string;
  lat: number | null;
  lng: number | null;
  photoURL: string;
  phoneNumber: string | null;
}
interface Origin {
  id: string;
  description: string;
  html: string;
  iconUrl: string;
  lat: number | null;
  lng: number | null;
  photoURL: string;
  phoneNumber: string | null;
}
interface ParamTypes {
  id: string
}

const Page = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [origin, setOrigin] = React.useState<Origin | undefined>();
  const [points, setPoints] = React.useState<Row[]>([]);
  const [zoom] = React.useState(15);
  const { id } = useParams<ParamTypes>();
  const [center, setCenter] = React.useState({ lat: -11.840096772124648, lng: -51.95084271385832 });

  const getCall = async () => callService.getById(id).then((data) => {
    if (!data) {
      setPoints([]);
      return;
    }

    setOrigin({
      description: data.origin?.responsible,
      lat: data.origin?.address?.location?.latitude,
      lng: data.origin?.address?.location?.longitude,
      html: `
          ${toBrazilDateTime(data.statusHistory[0].date.toDate())}
      `,
      iconUrl: officeMarker,
      id: data.origin.clientId,
      phoneNumber: data.origin.phoneNumber,
      photoURL: data.origin.imageUrl ?? logoIcon
    });
    setCenter({ lat: data.origin.address.location.latitude, lng: data.origin.address.location.longitude });

    const callWithDriver = data.statusHistory.filter((x: any) => x.status !== 'waiting');
    const result = callWithDriver.map((item: any, i: number) => {
      let customIconUrl = '';
      switch (item.status) {
        case 'accepted':
          customIconUrl = acceptedMarker;
          break;
        case 'refused':
          customIconUrl = refusedMarker;
          break;
        case 'ongoing':
          customIconUrl = ongoingMarker;
          break;
        case 'canceled':
          customIconUrl = canceledMarker;
          break;
        case 'complete':
          customIconUrl = completeMarker;
          break;
        default:
          customIconUrl = unknownMarker;
          break;
      }

      return {
        id: i,
        description: `${i + 1}º) ${item.by.name}`,
        html: `
          ${toBrazilDateTime(item.date.toDate())}<br />
          ${convertStatusToBrazilian(item.status).description}
        `,
        iconUrl: customIconUrl,
        lat: item.location?.latitude ?? null,
        lng: item.location?.longitude ?? null,
        photoURL: item.by.photoURL,
        phoneNumber: ''
      };
    });
    setPoints(result);
  });

  useEffect(() => {
    Promise.all([getCall()]).then(() => setIsLoading(false));
  }, []);

  return (
    <Card className="card-box mb-4">
      <div className="card-header py-3">
        <div className="card-header--title font-size-lg">
          Histórico da entrega
        </div>
      </div>
      <div className="w-100" style={{ height: '600px' }}>
        {isLoading ? <Loading />
          : (
            <MapContainer center={center} scrollWheelZoom={false} zoom={zoom} style={{ height: '600px' }}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <MarkerClusterGroup>
                {origin && (
                  <Marker
                    key={origin.id}
                    position={{ lat: origin.lat ?? 0, lng: origin.lng ?? 0 }}
                    icon={L.icon({
                      iconUrl: origin.iconUrl ?? '',
                      iconSize: [32, 32],
                      iconAnchor: [15, 31],
                      popupAnchor: [0, -35]
                    })}
                    title={origin.description}
                  >
                    <Popup>
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper avatar-icon-lg  mr-2">
                          <div className="avatar-icon">
                            <img alt={origin.phoneNumber ?? ''} src={origin.photoURL} />
                          </div>
                        </div>
                        <div>
                          <span className="font-weight-bold text-black" title="">
                            {origin.description}
                          </span>
                          <span className="text-black-50 d-block">
                            <div dangerouslySetInnerHTML={{ __html: origin.html }} />
                          </span>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                )}

                {points.map((x: Row) => (
                  <Marker
                    key={x.id}
                    position={{ lat: x.lat ?? 0, lng: x.lng ?? 0 }}
                    icon={L.icon({
                      iconUrl: x.iconUrl,
                      iconSize: [32, 32],
                      iconAnchor: [15, 31],
                      popupAnchor: [0, -35]
                    })}
                    title={x.description}
                  >
                    <Popup>
                      <div className="d-flex align-items-center">
                        <div className="avatar-icon-wrapper avatar-icon-lg  mr-2">
                          <div className="avatar-icon">
                            <img alt={x.phoneNumber ?? ''} src={x.photoURL} />
                          </div>
                        </div>
                        <div>
                          <span className="font-weight-bold text-black" title="">
                            {x.description}
                          </span>
                          <span className="text-black-50 d-block">
                            <div dangerouslySetInnerHTML={{ __html: x.html }} />
                          </span>
                        </div>
                      </div>
                    </Popup>
                  </Marker>
                ))}
              </MarkerClusterGroup>
            </MapContainer>
          )}
      </div>
    </Card>
  );
};

export default Page;
