import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import subsidiaryService from '../../../../services/subsidiaryService';
import DriversPage from '../../../drivers';

interface ParamTypes {
  id: string
}

export default function Drivers() {
  const [name, setName] = React.useState<string | undefined>(undefined);
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    subsidiaryService.getByIdAsync(id).then((result) => setName(result.description));
  }, []);

  return <DriversPage subsidiaryId={id} title={`Condutores / ${name}`} />;
}
