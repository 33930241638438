import _ from 'lodash';

import IUserAuth from './interfaces/userAuth';
import Address from './address';
import { User } from '../config/firebase';

export default class UserAuth {
  uid: string;

  displayName: string;

  phoneNumber: string;

  email: string;

  photoURL: string;

  name: string;

  address: Address;

  createdAt: Date;

  claims: any;

  emailVerified: boolean;

  constructor(auth: User, user: IUserAuth) {
    this.uid = auth.uid;
    this.displayName = auth.displayName!;
    this.phoneNumber = auth.phoneNumber!;
    this.email = auth.email!;
    this.photoURL = auth.photoURL!;
    this.emailVerified = auth.emailVerified;
    this.createdAt = new Date(auth.metadata.creationTime!);

    this.name = user.name;
    this.claims = user.claims;
    this.address = new Address(user.address);
  }

  getInitials(): string {
    let initials = '';
    if (this.displayName) {
      initials = `${this.displayName.split(' ')[0]?.substr(0, 1)} ${this.displayName.split(' ')[1]?.substr(0, 1)}`;
    } else if (this.name) {
      initials = `${_.first(this.name.split(' '))?.substr(0, 1)} ${_.last(this.name.split(' '))?.substr(0, 1)}`;
    }

    return initials;
  }
}
