/* eslint-disable no-underscore-dangle */
/* eslint-disable lines-between-class-members */
import { isAfter } from 'date-fns';
import BoazMicroserviceBase from './boazMicroserviceBase';

// eslint-disable-next-line import/prefer-default-export
export class BoazTrackerService extends BoazMicroserviceBase {
    secretkey = '';
    access: any = null;
    constructor() {
      const URL = process.env.BOAZ_TRACKER_URL || 'https://boaztech-delivery-tracker.herokuapp.com';
      super(URL, null, null);
      this.secretkey = process.env.BOAZ_TRACKER_SECRET_KEY || '5166546A576E5A7234753778214125432A462D4A614E645267556B5870327335';
    }

    async getOnlineDrivers(subsidiary: string | null, userId: string | null, latitude: number, longitude: number, radius: number | null = null) {
      if (!subsidiary || !userId) return [];
      const currentDateTime = new Date();
      const halfDayMinutes = 720;
      if (!this.access || isAfter(currentDateTime, this.access?.expiresAt)) {
        // eslint-disable-next-line no-console
        console.log('Getting new token...');
        this.access = await this.getToken({ userId, secret: this.secretkey }, halfDayMinutes);
      }
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.access.token}`
        },
        params: {
          subsidiaryId: subsidiary,
          radius: radius || 30,
          latitude,
          longitude
        }
      };
      return this._api.get('position/nears', config);
    }
}
