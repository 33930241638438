/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */
import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";

import Customers from "../../customers";
import CustomersCreate from "../../customers/create";
import CustomersDetails from "../../customers/details";
import CustomersEdit from "../../customers/edit";

import Deliveries from "../../deliveries";
import DeliveriesReport from "../../deliveries/report";
import Map from "../../drivers/map-real-time";
import DeliveriesMap from "../../deliveries/map";

import Drivers from "../../drivers";
import DriverDetails from "../../drivers/details";
import DriverEdit from "../../drivers/edit";

import Users from "../../users";
import UsersCreate from "../../users/create";
import UserDetails from "../../users/details";
import UserEdit from "../../users/edit";

import Settings from "./settings";
import Revenue from "./financial/revenue";
import Billing from "./financial/billing";
import PrePago from "./financial/prepago";

import Places from './places';
// import Placess from './placess';
import PlacesCreate from './places/create';
import PlacesEdit from "./places/edit";

import Tariffs from "./tariffs";
import TariffsEdit from "./tariffs/edit";
import TariffsCreate from "../../tariffs/create";

import TariffsDynamic from "./tariffsDynamic";
import TariffsDynamicCreate from "./tariffsDynamic/create";
import TariffsDynamicEdit from "./tariffsDynamic/edit";

import LimitsByPaymentType from "./limitsByPaymentTypes";
import LimitsByPaymentTypeCreate from "./limitsByPaymentTypes/create";
// import TariffsDynamicEdit from "./tariffsDynamic/edit";

const Dashboard = lazy(() => import("../../dashboard"));
const Chart = lazy(() => import("../../dashboard/chart"));
const Profile = lazy(() => import("../../auth/profile"));

const ManagerRoutes = () => (
  <Switch>
    <Route exact path="/profile" component={Profile} />

    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/chart" component={Chart} />

    <Route exact path="/companies" component={Customers} />
    <Route path="/companies/create" component={CustomersCreate} />
    <Route exact path="/companies/:id" component={CustomersDetails} />
    <Route path="/companies/:id/edit" component={CustomersEdit} />

    <Route exact path="/deliveries" component={Deliveries} />
    <Route exact path="/deliveries-report" component={DeliveriesReport} />
    <Route exact path="/map" component={Map} />

    <Route exact path="/deliveries/:id/map" component={DeliveriesMap} />

    <Route exact path="/drivers" component={Drivers} />
    <Route exact path="/drivers/:id" component={DriverDetails} />
    <Route path="/drivers/:id/edit" component={DriverEdit} />

    <Route exact path="/users" component={Users} />
    <Route path="/users/create" component={UsersCreate} />
    <Route exact path="/users/:id" component={UserDetails} />
    <Route path="/users/:id/edit" component={UserEdit} />

    <Route exact path="/settings" component={Settings} />
    <Route exact path="/settings/tariffs" component={Tariffs} />
    <Route path="/subsidiaries/:subsidiaryId/tariffs/create" component={TariffsCreate} />
    <Route path="/settings/tariffs/:tariffId/edit" component={TariffsEdit} />

    <Route exact path="/settings/tariffs-dynamic" component={TariffsDynamic} />
    <Route
      path="/settings/tariffs-dynamic/create"
      component={TariffsDynamicCreate}
    />
    <Route
      path="/settings/tariffs-dynamic/:tariffDynamicId/edit"
      component={TariffsDynamicEdit}
    />

    <Route exact path="/settings/limits-paymenttypes" component={LimitsByPaymentType} />
    <Route exact path="/settings/limits-paymenttypes/create" component={LimitsByPaymentTypeCreate} />

    <Route exact path="/settings/places" component={Places} />
    <Route
      path="/settings/places/create"
      component={PlacesCreate}
    />
    <Route
      path="/settings/places/:placeId/edit"
      component={PlacesEdit}
    />

    <Route exact path="/reports/revenue" component={Revenue} />
    <Route exact path="/reports/billing" component={Billing} />
    <Route exact path="/reports/prepago" component={PrePago} />
  </Switch>
);
export default ManagerRoutes;
