/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, Card, CircularProgress, Button, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, SpeedDial, SpeedDialAction
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { OptionsObject, useSnackbar } from 'notistack';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { getErrorMessage, getDownloadURL, getMetadata } from '../../config/firebase';
import {
  cpfOrCnpj, phoneNumber, toBrazilDate, toBrazilDateTime
} from '../../components/mask';
import driverService from '../../services/driverService';
import subsidiaryService from '../../services/subsidiaryService';
import Driver from '../../models/interfaces/driver';
import UseStyles from '../../components/styles/loadingButton';
import { StatusDriverText } from '../../models/enums/statusDriver';
import { CategoryDriverText } from '../../models/enums/categoryDriver';
import NoData from '../../components/noData';

import avatarImg from '../../assets/images/avatar.png';
import { useAuth } from '../../contexts/auth';
import ConfirmDialog from '../../components/confirmDialog';
import TermModalComponent from './components/termModalComponent';
import Subsidiary from '../../models/interfaces/subsidiary';

interface ParamTypes {
  id: string
}

interface File {
  id: string;
  url: string;
  name: string;
  size: number;
  icon: IconProp;
  updated: Date;
}

export default function page() {
  const history = useHistory();
  const { user } = useAuth();
  const { id } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = React.useState<Driver>();
  const [docs, setDocs] = React.useState<File[]>([]);
  const [terms, setTerms] = React.useState<any[]>([]);
  // const [enableButtons, setEnableButtons] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [openModalJustification, setOpenModalJustification] = React.useState(false);
  const [justification, setJustification] = React.useState<string>('');
  const classes = UseStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [countClicks, setCountClicks] = React.useState<number>();
  const [modalData, setModalData] = React.useState<any>();
  const [openModal, setOpenModal] = React.useState(false);
  const [subsidiary, setSubsidiary] = React.useState<Subsidiary>();

  useEffect(() => {
    driverService.getById(id).then(async (item: Driver) => {
      setCountClicks(3);
      setModel(item);
      // setEnableButtons(item.document.approved === undefined || item.document.approved === null || item.document.approved === false || item.document.analyzedAt! < item.document.sendedAt!);
      subsidiaryService.getByIdAsync(item.subsidiary).then((res) => {
        const sub = res as Subsidiary;
        setSubsidiary(sub);
      });

      driverService.getDocs(id).then(async (res) => {
        const promisses = res.map(async (x: any) => {
          const url = await getDownloadURL(x);
          const metaData = await getMetadata(x);
          let icon: IconProp;
          switch (metaData.contentType) {
            case 'application/pdf':
              icon = ['far', 'file-pdf'];
              break;
            case 'image/jpeg':
              icon = ['far', 'file-image'];
              break;
            default:
              icon = ['far', 'file'];
              break;
          }
          return {
            id: metaData.generation,
            url,
            name: x.name,
            size: metaData.size,
            icon,
            updated: new Date(metaData.updated)
          };
        });
        Promise.all(promisses).then((resultDocs) => {
          setDocs(resultDocs);
        });
      });

      driverService.getTerms().then((res) => {
        setTerms(res);
      });
    }).catch(() => {
      history.push('/404');
    });
  }, []);

  if (!model) return (<></>);

  const changeStatus = () => {
    driverService.changeStatus(id).then(() => {
      setModel({ ...model, disabledAt: model.disabledAt ? null : new Date() });
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    });
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
  };

  const handleModalJustification = (e: any) => {
    e.preventDefault();
    handleAnalyseDocuments(false);
    setOpenModalJustification(false);
  };

  const unlockDriver = (clicks: number | undefined) => {
    const count = clicks === undefined ? 3 : clicks;
    if (count > 1) {
      setCountClicks(count - 1);
    } else {
      driverService.unLockDriver(id).then(async () => {
        model.lockedUntil = null;

        const params = {
          title: 'Aceitar novas chamadas',
          // eslint-disable-next-line max-len
          text: `Olá ${user?.displayName} tudo bem? Seguinte, a gente deseja mesmo é que você continue fazendo suas entregas sem problemas ou impedimentos, assim cancelei o tempo de espera e você já pode aceitar novas chamadas 👍🏼 👊🏼.`,
          driverId: id,
          driverName: model.name
        };
        await driverService.sendNotification(params.title, params.text, params.driverId, params.driverName);
        setCountClicks(3);
      });
    }
  };

  const handleAnalyseDocuments = (status: boolean) => {
    setIsSubmitting(true);
    driverService.analyseDocs(id, status, user?.uid!, justification).then((data: string | null) => {
      let option: OptionsObject = { variant: 'success' };
      let message = `Documentos ${status ? 'aprovados' : 'recusados'} com sucesso`;
      if (data) {
        option = { variant: 'warning' };
        message = data.toString();
      }

      const result = { ...model };
      result.document.approved = status;
      setModel(result);
      // setEnableButtons(false);
      enqueueSnackbar(message, option);
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    }).finally(() => {
      setIsSubmitting(false);
    });
  };

  const handleCancel = () => { setOpen(false); };
  const handleCloseModal = () => { setOpenModal(false); };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    await driverService.delete(id).then((result) => {
      if (result) {
        handleCancel();
        enqueueSnackbar('Condutor removido com sucesso', { variant: 'success' });
        history.push('/drivers');
      } else {
        enqueueSnackbar('Não é possivel remover este condutor pois ele já participou de chamadas', { variant: 'warning' });
      }
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    }).finally(() => setIsLoading(false));
  };

  const redirectToEdit = (): React.MouseEventHandler<HTMLDivElement> => () => history.push(`${id}/edit`);
  const deleteAction = (): React.MouseEventHandler<HTMLDivElement> => () => {
    setOpen(true);
  };

  const actions = [
    { icon: <EditIcon />, name: 'Editar', action: redirectToEdit() },
    { icon: <DeleteForeverIcon />, name: 'Excluir', action: deleteAction() }
  ];

  const handleOpenModal = (item: any) => {
    if (item) {
      setModalData(item);
      setOpenModal(true);
    }
  };

  return (
    <>
      <ConfirmDialog open={open} handleClose={handleCancel} handleAction={handleConfirmDelete} isLoading={isLoading} message="Deseja remover este condutor?" />
      <TermModalComponent open={openModal} handleClose={handleCloseModal} data={modalData} subsidiary={subsidiary} driver={model} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} md={6}>
          <div className="bg-midnight-bloom p-3 rounded text-white h-100">
            <div className="d-flex align-items-start justify-content-between">
              <div className="avatar-icon-wrapper d-100">
                <span className={`badge-circle badge badge-${model.available ? 'success' : 'warning'}`}>{model.available ? 'Online' : 'Offline'}</span>
                <div className="avatar-icon d-100">
                  <img alt="" src={model.photoURL ?? avatarImg} />
                </div>
              </div>
              <Switch
                checked={!model.disabledAt}
                onChange={changeStatus}
                color="primary"
                title={model.disabledAt ? 'Inativo' : 'Ativo'}
              />
            </div>
            <div className="font-weight-bold font-size-lg d-flex align-items-center mt-2 mb-0">
              <span>{model.name}</span>
            </div>
            <p className="mb-4 font-size-md text-white-50">{model.email}</p>
            <div className="divider opacity-3 my-4" />
            <p className="font-weight-bold">{model.address.toString()}</p>
            <p>
              Categoria:
              {' '}
              <span className="font-weight-bold">{CategoryDriverText[model.category]}</span>
            </p>
            <p>
              Data de aniversário:
              {' '}
              <span className="font-weight-bold">{toBrazilDate(model.dtBirth)}</span>
            </p>
            <p>
              CPF:
              {' '}
              <span className="font-weight-bold">{cpfOrCnpj(model.cpf)}</span>
            </p>
            <p>
              RG:
              {' '}
              <span className="font-weight-bold">{model.rg}</span>
            </p>
            <p>
              PIX:
              {' '}
              <span
                className="font-weight-bold"
              >
                {model.pix ? `${model.pix.split('|')[0]}: ${model.pix.split('|')[1]} ` : 'Não cadastrado ' }
                <Button
                  style={{ width: '40px', height: '21px' }}
                  hidden={!model?.pix}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => navigator.clipboard.writeText(model?.pix ? model.pix.split('|')[1].toString() : '')}
                >
                  Copiar
                </Button>
              </span>
            </p>

            <p>
              Confirmação do email:
              {' '}
              <span className="font-weight-bold">{model.emailVerified ? 'Ok' : 'Pendente'}</span>
            </p>
            <p>
              Status do cadastro:
              {' '}
              <span className="font-weight-bold">{StatusDriverText[model.status]}</span>
            </p>
            <p>
              Criado em:
              {' '}
              <span className="font-weight-bold">{toBrazilDateTime(model.createdAt)}</span>
            </p>
            <p>
              Última interacão com o sistema:
              {' '}
              <span className="font-weight-bold">
                {model.updatedAt ? toBrazilDateTime(model.updatedAt) : 'Indefinido'}
              </span>
            </p>
            <p>
              Dispositivo:
              {' '}
              <span className="font-weight-bold">
                {model.device ? `${model?.device?.manufacturer} ${model?.device?.model}
                ${model?.device?.platform} ${model?.device?.osVersion} | versão app ${model?.device?.appVersion}` : 'Indefinido'}
              </span>
            </p>
            <p className="warning">
              Inibido até:
              {' '}
              <span className="font-weight-bold">
                {model.lockedUntil ? `${toBrazilDateTime(model.lockedUntil)} ` : 'Sem inibição'}
                <Button
                  style={{ height: '21px' }}
                  hidden={!model?.lockedUntil}
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => unlockDriver(countClicks)}
                >
                  {countClicks}
                  {' '}
                  cliques para liberar
                </Button>
              </span>
            </p>
            {docs.length > 0
              ? (
                <Grid container spacing={3}>
                  <Grid item xs>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      disabled={isSubmitting || model.document.approved === false}
                      onClick={() => setOpenModalJustification(true)}
                    >
                      Recusar documentos
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}

                    <Dialog
                      open={openModalJustification}
                      onClose={() => setOpenModalJustification(false)}
                      aria-labelledby="form-dialog-title"
                    >
                      <form autoComplete="off" onSubmit={handleModalJustification}>
                        <DialogTitle id="form-dialog-title">Justificativa do cancelamento</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Informe para o condutor qual foi o motivo para não aprovar o cadastro dele na plataforma.
                          </DialogContentText>
                          <TextField
                            autoFocus
                            multiline
                            fullWidth
                            required
                            label="Justificativa"
                            variant="outlined"
                            rows="4"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setJustification(e.target.value)}
                          />
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setOpenModalJustification(false)} color="primary">Cancelar</Button>
                          <Button type="submit" color="primary">Recusar</Button>
                        </DialogActions>
                      </form>
                    </Dialog>
                  </Grid>
                  <Grid item xs>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      disabled={isSubmitting || model?.document?.approved === true}
                      onClick={() => handleAnalyseDocuments(true)}
                    >
                      Aprovar documentos
                    </Button>
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </Grid>
                </Grid>
              )
              : (
                <p>
                  Situação dos documentos enviados:
                  {' '}
                  <span className="font-weight-bold">{model.document.approved ? 'Aprovados' : 'Recusados'}</span>
                </p>
              )}
            {model.disabledAt && (
              <p>
                {`Desabilitado em: ${toBrazilDateTime(model.disabledAt)}`}
              </p>
            )}

            <div className="divider opacity-3 my-4" />
            <Grid container spacing={3} className="font-size-xs">
              <Grid item xs={12} sm={12} md={12}>
                <a target="_blank" href={`https://wa.me/${model.phoneNumber}`} rel="noreferrer">
                  <Card className="text-center my-2 p-3">
                    <div>
                      <FontAwesomeIcon icon={['fab', 'whatsapp']} className="font-size-xxl text-success" />
                    </div>
                    <div className="mt-2 line-height-sm">
                      <b className="font-size-lg text-black">{phoneNumber(model.phoneNumber!)}</b>
                      <span className="text-black-50 d-block">whatsapp</span>
                    </div>
                  </Card>
                </a>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6}>
                <Card className="text-center my-2 p-3">
                  <div>
                    <FontAwesomeIcon icon={['far', 'chart-bar']} className="font-size-xxl text-danger" />
                  </div>
                  <div className="mt-2 line-height-sm">
                    <b className="font-size-lg text-black">{model.rating?.quantity ?? 0}</b>
                    <span className="text-black-50 d-block">entregas</span>
                  </div>
                </Card>
              </Grid> */}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} md={6}>
          <h5 className="font-size-sm text-uppercase text-black-50 font-weight-bold my-4">
            {`Documentos enviados em ${toBrazilDateTime(model.document?.sendedAt)}`}
          </h5>
          <Grid container spacing={3}>
            {docs.length
              ? (
                <>
                  {docs.map((x) => (
                    <Grid key={x.id} item xs={12}>
                      <a
                        href={x.url}
                        rel="noreferrer"
                        target="_blank"
                        className="card card-box btn rounded text-left mb-4 p-3"
                      >
                        <div className="d-flex align-items-center flex-column flex-sm-row">
                          <div>
                            <div className="text-center text-warning display-2 d-50 rounded-circle mb-3 mb-sm-2">
                              <FontAwesomeIcon icon={x.icon} />
                            </div>
                          </div>
                          <div className="pl-0 pl-sm-3">
                            <div className="d-block text-center d-sm-flex align-items-center">
                              <span className="font-size-md">{x.name}</span>
                            </div>
                            <p className="text-black-50 opacity-8 mb-0" title="Data de modificação">
                              {`${toBrazilDateTime(x.updated)} | ${formatBytes(x.size)}`}
                            </p>
                          </div>
                        </div>
                      </a>
                    </Grid>
                  ))}
                </>
              )
              : <NoData message="Nenhum documento encontrado." />}
          </Grid>
          <h5 className="font-size-sm text-uppercase text-black-50 font-weight-bold my-4">
            Termos e Contratos
          </h5>
          <Grid container spacing={3}>
            {terms.length
              ? (
                <>
                  {terms.map((x) => (
                    <Grid key={x.id} item xs={12} onClick={() => handleOpenModal(x)}>
                      <a href="javascript:void(0);" className="card card-box btn rounded text-left mb-4 p-3">
                        <div className="d-flex align-items-center flex-column flex-sm-row">
                          <div>
                            <div className="text-center text-warning display-2 d-50 rounded-circle mb-3 mb-sm-2">
                              <FontAwesomeIcon icon={['fas', 'file-signature']} />
                            </div>
                          </div>
                          <div className="pl-0 pl-sm-3">
                            <div className="d-block text-left d-sm-flex align-items-left">
                              <span className="font-size-md">{x.description}</span>
                            </div>
                            <p className="text-black-50 opacity-8 mb-0" title="Data da aceitação">
                              {`Data e hora do aceite ${toBrazilDateTime(model.createdAt)}`}
                            </p>
                          </div>
                        </div>
                      </a>
                    </Grid>
                  ))}
                </>
              )
              : <NoData message="Nenhum termo de uso encontrado." />}
          </Grid>
          <SpeedDial
            ariaLabel="Opções"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            direction="left"
            icon={<SettingsIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </SpeedDial>
        </Grid>
      </Grid>
    </>
  );
}
