import _ from 'lodash';
import { validate as uuidValidate } from 'uuid';

import { getCountFromServer } from 'firebase/firestore';
import {
  doc,
  getDateNow,
  getDoc,
  getDocs,
  query,
  updateDoc,
  deleteDoc,
  where,
  subsidiaryCollection,
  addDoc,
  tariffDynamicCollection
} from '../config/firebase';
import { DynamicTariff } from '../models/interfaces/tariff';
import { titleCase } from '../utils/stringHelper';
import customerService from './customerService';
import subsidiaryService from './subsidiaryService';
import { toBrazilDateTime } from '../components/mask';

export default {
  async getAllAsync(subId: string | undefined | null) {
    let subsidiaryId = subId;
    if (subsidiaryId && uuidValidate(subsidiaryId)) {
      const newSub = await subsidiaryService.getByIdAsync(subsidiaryId);

      const subsidiariesByCNPJ = await getDocs(
        query(subsidiaryCollection, where('cnpj', '==', newSub.cnpj))
      );
      if (subsidiariesByCNPJ.size === 1) {
        subsidiaryId = subsidiariesByCNPJ.docs[0].id;
      }
    }

    let customQuery = query(
      tariffDynamicCollection,
      where('subsidiary', '!=', null)
    );
    if (subsidiaryId) {
      customQuery = query(
        tariffDynamicCollection,
        where('subsidiary', '==', subsidiaryId)
      );
    }

    return getDocs(customQuery).then(async (querySnapshot) => {
      let result: any[] = [];
      const promisses = querySnapshot.docs.map(async (snap) => {
        const {
          description,
          activated,
          categoryDriver,
          type,
          value,
          activateUntil
        } = snap.data();
        return {
          id: snap.id,
          description,
          categoryDriver,
          type,
          value:
            type === 1
              ? `${value}X`
              : value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL'
              }),
          activated,
          activateUntil:
            activated === 1 || activated === 0
              ? ' - '
              : toBrazilDateTime(activateUntil.toDate())
        };
      });

      await Promise.all(promisses).then((resultDocs) => {
        result = resultDocs;
      });

      return _.sortBy(result, [(x) => x.description.toLowerCase()]);
    });
  },

  async getAllCount(id: string | null | undefined) {
    const subsidiaryId = id;
    let total;
    const q = query(tariffDynamicCollection);
    const c = query(q);

    if (subsidiaryId) {
      total = await getCountFromServer(query(c, where('subsidiary', '==', subsidiaryId)));
    } else {
      total = await getCountFromServer(c);
    }

    return { total: total?.data().count };
  },

  async getById(id: string) {
    const result = await getDoc(doc(tariffDynamicCollection, id));
    if (!result.exists()) throw new Error('Tariff not found');

    const model = result.data();
    if (model?.company) {
      model.company = await customerService.getById(model.company);
    }
    return model;
  },

  async createAsync(model: DynamicTariff) {
    return addDoc(tariffDynamicCollection, {
      ...model,
      createdAt: getDateNow()
    });
  },

  async update(model: DynamicTariff, id: string) {
    const modelRef = doc(tariffDynamicCollection, id);
    return updateDoc(modelRef, {
      ...model,
      description: titleCase(model.description)
    });
  },

  async delete(id: string) {
    const modelRef = doc(tariffDynamicCollection, id);
    return deleteDoc(modelRef);
  }
};
