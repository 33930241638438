import axios from 'axios';

import { getAuth } from './firebase';

const api = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_FUNCTION_URL
});

api.interceptors.request.use(async (config) => {
  const token = await getAuth().currentUser?.getIdToken();
  return {
    ...config,
    headers: {
      ...config.headers,
      authorization: `Bearer ${token}`
    }
  };
}, (error) => Promise.reject(error));

export default api;
