import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Customers from '../../customers';
import CustomersCreate from '../../customers/create';
import CustomersDetails from '../../customers/details';
import CustomersEdit from '../../customers/edit';

import Drivers from '../../drivers';
import DriverDetails from '../../drivers/details';
import DriverEdit from '../../drivers/edit';

import Deliveries from '../../deliveries';
import DeliveriesReport from '../../deliveries/report';
import Map from '../../drivers/map-real-time';
import DeliveriesMap from '../../deliveries/map';

const Dashboard = lazy(() => import('../../dashboard'));
const Chart = lazy(() => import('../../dashboard/chart'));
const Profile = lazy(() => import('../../auth/profile'));

const ManagerRoutes = () => (
  <Switch>
    <Route exact path="/profile" component={Profile} />

    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/chart" component={Chart} />

    <Route exact path="/deliveries" component={Deliveries} />
    <Route exact path="/deliveries-report" component={DeliveriesReport} />
    <Route exact path="/map" component={Map} />

    <Route exact path="/deliveries/:id/map" component={DeliveriesMap} />

    <Route exact path="/companies" component={Customers} />
    <Route path="/companies/create" component={CustomersCreate} />
    <Route exact path="/companies/:id" component={CustomersDetails} />
    <Route path="/companies/:id/edit" component={CustomersEdit} />

    <Route exact path="/drivers" component={Drivers} />
    <Route exact path="/drivers/:id" component={DriverDetails} />
    <Route path="/drivers/:id/edit" component={DriverEdit} />
  </Switch>
);

export default ManagerRoutes;
