import React from 'react';
import {
  Dialog,
  Divider,
  List,
  ListItem
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Dictionary from '../../models/interfaces/dictionary';
// TODO: Avoid show modal when click F5
export default function subsidiariesDialog(props: any) {
  const { onClose, open, items } = props;

  const handleListItemClick = (value: Dictionary) => {
    onClose(value);
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open}>
      <div className="bg-composed-wrapper bg-second">
        <div className="bg-composed-wrapper--image bg-composed-img-5" />
        <div className="bg-composed-wrapper--content text-light p-5">
          <h5 className="mb-1">Selecione a filial</h5>
          <p className="mb-0 opacity-7">
            Qualquer dúvida entre em contato conosco.
          </p>
        </div>
      </div>
      <List className="py-0">
        {items.map((item: Dictionary, index: number) => (
          <div key={item.key}>
            <ListItem button className="align-box-row" onClick={() => handleListItemClick(item)} key={item.key}>
              <div className="align-box-row w-100">
                <div className="mr-3">
                  <div className="bg-primary text-center text-white font-size-xl d-50 rounded-circle">
                    <FontAwesomeIcon icon={['fas', 'building']} />
                  </div>
                </div>
                <div>
                  <div className="font-weight-bold text-primary d-block">{item.value}</div>
                  {/* <small className="text-success">
                    <FontAwesomeIcon icon={['fas', 'chevron-up']} className="text-success mr-1" />
                    <span>15.4% increase</span>
                  </small> */}
                </div>
                <div className="ml-auto card-hover-indicator align-self-center">
                  <FontAwesomeIcon icon={['fas', 'chevron-right']} className="font-size-xl" />
                </div>
              </div>
            </ListItem>
            {(index + 1) < items.length && (<Divider />)}
          </div>
        ))}
      </List>
    </Dialog>
  );
}
