import React from 'react';
import { TextField } from '@material-ui/core';
import { Fab, CircularProgress, Grid } from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { KeyboardDatePicker } from '@material-ui/pickers';

import WrapperSimple from '../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../components/styles/loadingButton';
import { setAddressByCEP } from '../../services/geolocateService';
import { CepMask, CpfMask, PhoneMask } from '../../components/mask';
import { getErrorMessage } from '../../config/firebase';
import { useAuth } from '../../contexts/auth';
import Address from '../../models/interfaces/address';
import userService from '../../services/userService';

interface FormValues {
  address: Address,
  name: string,
  cpf: string,
  email: string,
  dtBirth: Date | null,
  phoneNumber: string
}

export default function page() {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const classes = UseStyles();
  const { subsidiaryId } = useAuth();

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  const onBlurCep = (ev: any, setFieldValue: any): void => {
    setAddressByCEP(ev, setFieldValue).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'warning' });
    });
  };

  return (
    <WrapperSimple sectionHeading="Novo usuário">
      <Formik
        initialValues={{
          name: '',
          cpf: '',
          email: '',
          dtBirth: null,
          address: {
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: null,
            ibge: null,
            location: null
          },
          phoneNumber: ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          cpf: Yup.string().required().min(14),
          email: Yup.string().email().required(),
          phoneNumber: Yup.string().required()
            .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Telefone inválido'),
          dtBirth: Yup.date().required().nullable(),
          address: Yup.object().shape({
            zipCode: Yup.string().required(),
            street: Yup.string().required(),
            neighborhood: Yup.string().required(),
            city: Yup.string().required(),
            number: Yup.string().required()
          })
        })}
        onSubmit={async (values: FormValues, actions) => {
          await userService.createAsync(values.email, values.name, values.cpf, values.dtBirth!, null, values.address, null, subsidiaryId!).then(() => {
            setSuccess(true);
            setTimeout(() => {
              history.push('/users');
            }, 1000);
          }).catch((e) => {
            enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<FormValues>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;
          return (
            <Form noValidate autoComplete="off">
              <Field type="hidden" name="address.ibge" value="" />
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={5}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Nome"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    error={Boolean(errors.name && touched.name)}
                    helperText={errors.name && touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="CPF"
                    name="cpf"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.cpf && touched.cpf)}
                    helperText={errors.cpf && touched.cpf && errors.cpf}
                    InputProps={{ inputComponent: CpfMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <KeyboardDatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label="Data de nascimento"
                    format="dd/MM/yyyy"
                    InputLabelProps={{ shrink: true }}
                    name="dtBirth"
                    onChange={(date: Date | null) => { setFieldValue('dtBirth', date); }}
                    onBlur={handleBlur}
                    value={values.dtBirth}
                    error={Boolean(errors.dtBirth && touched.dtBirth)}
                    helperText={errors.dtBirth && touched.dtBirth && errors.dtBirth}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.email && touched.email)}
                    helperText={errors.email && touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="CEP"
                    name="address.zipCode"
                    onChange={handleChange}
                    onBlur={(e: any) => onBlurCep(e, setFieldValue)}
                    error={Boolean(errors.address?.zipCode && touched.address?.zipCode)}
                    helperText={errors.address?.zipCode && touched.address?.zipCode && errors.address?.zipCode}
                    InputProps={{ inputComponent: CepMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Endereço"
                    name="address.street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.street && touched.address?.street)}
                    helperText={errors.address?.street && touched.address?.street && errors.address?.street}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Bairro"
                    name="address.neighborhood"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.neighborhood && touched.address?.neighborhood)}
                    helperText={errors.address?.neighborhood && touched.address?.neighborhood && errors.address?.neighborhood}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Cidade"
                    name="address.city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.city && touched.address?.city)}
                    helperText={errors.address?.city && touched.address?.city && errors.address?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Nº"
                    name="address.number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.number && touched.address?.number)}
                    helperText={errors.address?.number && touched.address?.number && errors.address?.number}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    label="Complemento"
                    name="address.complement"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.complement && touched.address?.complement)}
                    helperText={errors.address?.complement && touched.address?.complement && errors.address?.complement}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Telefone"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                    helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                    InputProps={{ inputComponent: PhoneMask as any }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress size={68} className={classes.fabProgress} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
