import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../contexts/auth';

const PrivateRoute: React.FC<any> = ({ component: RouteComponent, ...rest }) => {
  const { user, subsidiaryId } = useAuth();

  if (user) {
    // If user not is admin and not have subsidiaries
    if (user.claims?.admin === undefined && subsidiaryId === undefined) {
      return (<Redirect to={{ pathname: '/403', state: { prevPath: rest.path } }} />);
    }
  }

  return (
    <Route
      {...rest}
      render={(routeProps) => (user
        ? (<RouteComponent {...routeProps} />)
        : (<Redirect to={{ pathname: '/login', state: { prevPath: rest.path } }} />
        ))}
    />
  );
};

export default PrivateRoute;
