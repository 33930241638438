import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid, Card, Switch, SpeedDialAction, SpeedDial, Button, Skeleton
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useSnackbar } from 'notistack';
import Dropzone from 'react-dropzone';
import CountUp from 'react-countup';

import {
  getDownloadURL, getErrorMessage, ref, storage, uploadBytesResumable
} from '../../config/firebase';
import { cpfOrCnpj, phoneNumber, toBrazilDateTime } from '../../components/mask';
import customerService from '../../services/customerService';
import Customer from '../../models/interfaces/customer';
import avatarImg from '../../assets/images/avatar.png';
import { useAuth } from '../../contexts/auth';
import { PaymentTypeText } from '../../models/enums/paymentType';
import { CategoryDriverText } from '../../models/enums/categoryDriver';
import Map from './components/map';
import ConfirmDialog from '../../components/confirmDialog';
import balanceService from '../../services/balanceService';

interface ParamTypes {
  id: string
}

const details = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { id } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = React.useState<Customer>();
  const [pictureUrl, setPictureUrl] = React.useState(avatarImg);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentBalance, setCurrentBalance] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    customerService.getById(id).then((item) => {
      setModel(item);
      if (item.photoURL) {
        setPictureUrl(item.photoURL);
      }
    }).catch(() => {
      history.push('/404');
    });

    balanceService.getCurrentBalanceAsync(id).then((balance) => {
      setCurrentBalance(balance);
    });
  }, []);

  if (!model) return (<></>);

  const changeStatus = () => {
    customerService.changeStatus(id).then(() => {
      setModel({ ...model, disabledAt: model.disabledAt ? null : new Date() });
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    });
  };

  const onOffIfood = () => {
    customerService.onOffIfood(id).then((res) => {
      const newifood = {
        createdAt: model.ifood?.createdAt ? model.ifood.createdAt : new Date(),
        merchantId: model.ifood?.merchantId ? model.ifood.merchantId : '',
        disabledAt: res ? new Date() : null,
        disabledByAdmin: !res
      };
      setModel({ ...model, ifood: newifood });
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    });
  };

  const onOffIntegration = () => {
    customerService.onOffIntegration(id).then((res) => {
      setModel({ ...model, integration: { ...res, disabledAt: !res.activated ? new Date() : null, registeredAt: res?.registeredAt.toDate() } });
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    });
  };

  const handleImageUpload = (acceptedFiles: any) => {
    const file = acceptedFiles[0] as File;
    handleUpload(file);
  };

  const handleUpload = (file: File) => {
    const path = `clients/${model.id}`;
    const fileName = `photo.${file.name.split('.').pop()}`;

    const storageRef = ref(storage, `${path}/${fileName}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on('state_changed', () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url: string) => {
        customerService.changePhoto(url, model.id).then(() => {
          setPictureUrl(url);
        }).catch((e) => {
          enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
        });
      });
    });
  };

  const handleCancel = () => { setOpen(false); };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    await customerService.delete(model.id).then((result) => {
      if (result) {
        handleCancel();
        enqueueSnackbar('Cliente removido com sucesso', { variant: 'success' });
        history.push('/companies');
      } else {
        enqueueSnackbar('Não é possivel remover este cliente pois ele já realizou chamadas', { variant: 'warning' });
      }
    }).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'error' });
    }).finally(() => setIsLoading(false));
  };

  const redirectToEdit = (): React.MouseEventHandler<HTMLDivElement> => () => history.push(`${model.id}/edit`);
  const deleteAction = (): React.MouseEventHandler<HTMLDivElement> => () => {
    setOpen(true);
  };

  const actions = [
    { icon: <EditIcon />, name: 'Editar', action: redirectToEdit() },
    { icon: <DeleteForeverIcon />, name: 'Excluir', action: deleteAction() }
  ];

  return (
    <>
      <ConfirmDialog open={open} handleClose={handleCancel} handleAction={handleConfirmDelete} isLoading={isLoading} message="Deseja remover este cliente?" />
      <Grid container spacing={4}>
        <Grid item xs={12} lg={4}>
          <div className="bg-midnight-bloom p-3 rounded text-white h-100">
            <div className="d-flex align-items-start justify-content-between">
              <div className="avatar-icon-wrapper d-100">
                <div className="avatar-icon d-100">
                  <Dropzone onDrop={handleImageUpload} multiple={false} accept="image/*">
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-message" style={{ padding: 0 }} title="Clique aqui para atualizar sua imagem">
                          {pictureUrl
                            ? <img alt="" src={pictureUrl} />
                            : user?.getInitials()}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
              <Switch
                checked={!model.disabledAt}
                onChange={changeStatus}
                color="primary"
                title={model.disabledAt ? 'Inativo' : 'Ativo'}
              />
            </div>
            <div className="font-weight-bold font-size-lg d-flex align-items-center mt-2 mb-0">
              <span>{model.name}</span>
            </div>
            <p className="mb-4 font-size-md text-white-50">{model.email}</p>

            <div className="divider opacity-3 my-4" />
            <p className="font-weight-bold">{model.address?.toString() ?? '[Endereço pendente]'}</p>
            <p>
              Responsável:
              <br />
              <span className="font-weight-bold">{model.responsible}</span>
            </p>
            <p>
              Categorias:
              <br />
              {model.categories?.map((x) => <span key={x} className="m-1 badge badge-secondary">{CategoryDriverText[x]}</span>) ?? 'Indefinido'}
            </p>
            <p>
              Métodos de pagamento:
              <br />
              {model.paymentTypes?.map((x) => <span key={x} className="m-1 badge badge-secondary">{PaymentTypeText[x]}</span>) ?? 'Indefinido'}
            </p>
            <p>
              CPF/CNPJ:
              {' '}
              <span className="font-weight-bold">{cpfOrCnpj(model.registryNumber)}</span>
            </p>
            <p>
              Razão social:
              {' '}
              <span className="font-weight-bold">{model.businessName ?? 'Indefinido'}</span>
            </p>
            <p>
              Integrações:
              <br />
              {model.ifood && model.ifood.merchantId
                ? (
                  <span className="m-1 badge badge-secondary" title={`Criado em ${toBrazilDateTime(model.ifood.createdAt)}`}>
                    {`iFood | ${model.ifood.merchantId}`}
                  </span>
                )
                : ' '}
              {model.ifood && model.ifood.merchantId
                ? (
                  <Switch
                    checked={!model.ifood.disabledAt}
                    onChange={onOffIfood}
                    color="primary"
                    title={model.ifood.disabledAt ? 'Inativa' : 'Ativa'}
                  />
                )
                : ' '}
              {model.integration && model.integration?.registered
                ? (
                  <span className="m-1 badge badge-secondary" title={`Criado em ${toBrazilDateTime(model.integration?.registeredAt)}`}>
                    {`API | ${model.integration?.system}`}
                  </span>
                )
                : ' '}
              {model.integration && model.integration?.registered
                ? (
                  <Switch
                    checked={model.integration.activated}
                    onChange={onOffIntegration}
                    color="primary"
                    title={model.integration.disabledAt ? 'Inativa' : 'Ativa'}
                  />
                )
                : ' '}
            </p>
            <p>
              Contrato:
              {' '}
              <span className="font-weight-bold">{model.contract ?? 'Indefinido'}</span>
            </p>
            <p>
              Observações para o condutor:
              <br />
              <span className="font-weight-bold">{model.observationsToDriver ?? 'Indefinido'}</span>
            </p>
            <p>
              Observações:
              <br />
              <span className="font-weight-bold">{model.observations ?? 'Indefinido'}</span>
            </p>
            {model.disabledAt && (
              <p>
                Desativado em:
                {' '}
                <span className="font-weight-bold">{toBrazilDateTime(model.disabledAt)}</span>
              </p>
            )}
            <p>
              Criado em:
              {' '}
              <span className="font-weight-bold">{toBrazilDateTime(new Date(model.metadata.creationTime))}</span>
            </p>
            <p>
              Último acesso:
              {' '}
              <span className="font-weight-bold">
                {model.metadata.lastSignInTime ? toBrazilDateTime(new Date(model.metadata.lastSignInTime)) : 'Indefinido'}
              </span>
            </p>

            <div className="divider opacity-3 my-4" />
            <Grid container spacing={4} className="font-size-xs">
              <Grid item xs={12} sm={12} md={12}>
                <a target="_blank" href={`https://wa.me/${model.phoneNumber}`} rel="noreferrer">
                  <Card className="text-center my-2 p-3">
                    <div>
                      <FontAwesomeIcon icon={['fab', 'whatsapp']} className="font-size-xxl text-success" />
                    </div>
                    <div className="mt-2 line-height-sm">
                      <b className="font-size-lg text-black">{phoneNumber(model.phoneNumber!)}</b>
                      <span className="text-black-50 d-block">whatsapp</span>
                    </div>
                  </Card>
                </a>
              </Grid>
              {/* <Grid item xs={12} lg={6}>
                <Card className="text-center my-2 p-3">
                  <div>
                    <FontAwesomeIcon icon={['far', 'chart-bar']} className="font-size-xxl text-danger" />
                  </div>
                  <div className="mt-2 line-height-sm">
                    <b className="font-size-lg text-black">?</b>
                    <span className="text-black-50 d-block">entregas</span>
                  </div>
                </Card>
              </Grid> */}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Button
            variant="outlined"
            color="inherit"
            className="d-block border-0 w-100 rounded-0 py-4 px-1 text-warning"
            disabled
          >
            <FontAwesomeIcon icon={['fas', 'money-bill']} className="h2 d-block mb-2 mx-auto mt-1 text-warning" />
            <div className="font-weight-bold text-black">
              {currentBalance !== undefined
                ? <CountUp start={0} end={currentBalance} duration={2} separator="." decimals={2} decimal="," prefix="R$ " suffix="" />
                : <Skeleton variant="text" />}
            </div>
            <div className="font-size-sm mb-1 text-black-50" title="Este valor é utilizado nas entregas que usam pré-pago">Saldo atual no pré-pago</div>
          </Button>
          {model.address?.location && <Map customerId={model.id} location={model.address?.location} />}
          <SpeedDial
            ariaLabel="Opções"
            sx={{ position: 'absolute', bottom: 16, right: 16 }}
            direction="left"
            icon={<SettingsIcon />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={action.action}
              />
            ))}
          </SpeedDial>
        </Grid>
      </Grid>
    </>
  );
};

export default details;
