import React, { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { Fab, CircularProgress, Grid } from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';

import WrapperSimple from '../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../components/styles/loadingButton';
import { setAddressByCEP } from '../../services/geolocateService';
import { CepMask, PhoneMask } from '../../components/mask';
import { getErrorMessage } from '../../config/firebase';
import Address from '../../models/interfaces/address';
import userService from '../../services/userService';

interface ParamTypes {
  id: string
}

interface Values {
  id: string;
  name: string;
  dtBirth?: Date;
  email: string;
  address: Address;
  phoneNumber: string | null;
}

export default function page() {
  const history = useHistory();
  const classes = UseStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [success, setSuccess] = React.useState(false);
  const [oldEmail, setOldEmail] = React.useState('');
  const { id } = useParams<ParamTypes>();

  const buttonClassname = clsx({ [classes.buttonSuccess]: success });

  const onBlurCep = (ev: any, setFieldValue: any): void => {
    setAddressByCEP(ev, setFieldValue).catch((e) => {
      enqueueSnackbar(e.message, { variant: 'warning' });
    });
  };

  return (
    <WrapperSimple sectionHeading="Editar usuário">
      <Formik
        initialValues={{
          id,
          name: '',
          email: '',
          address: {
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: null,
            ibge: null,
            location: null
          },
          phoneNumber: ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(),
          email: Yup.string().email().required(),
          phoneNumber: Yup.string().required()
            .matches(/^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/, 'Telefone inválido'),
          address: Yup.object().shape({
            zipCode: Yup.string().required(),
            street: Yup.string().required(),
            neighborhood: Yup.string().required(),
            city: Yup.string().required(),
            number: Yup.string().required()
          })
        })}
        onSubmit={async (values: Values, actions) => {
          await userService.updateAsync({
            name: values.name,
            dtBirth: values.dtBirth!,
            email: values.email,
            address: values.address,
            phoneNumber: values.phoneNumber!
          }, oldEmail).then(() => {
            setSuccess(true);
            history.goBack();
          }).catch((e: any) => {
            enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
            setSuccess(false);
          }).finally(() => {
            actions.setSubmitting(false);
          });
        }}
      >
        {(props: FormikProps<Values>) => {
          const {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue
          } = props;

          useEffect(() => {
            userService.getByIdAsync(id).then((item: any) => {
              const fields = ['name', 'email', 'address', 'phoneNumber'];
              fields.forEach((field) => setFieldValue(field, item[field]));

              setOldEmail(item.email);

              setFieldValue('dtBirth', item.dtBirth);
            }).catch(() => {
              history.push('/404');
            });
          }, []);

          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={9}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Nome"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                    error={Boolean(errors.name && touched.name)}
                    helperText={errors.name && touched.name && errors.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <KeyboardDatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label="Data de Nascimento"
                    name="dtBirth"
                    InputLabelProps={{ shrink: true }}
                    format="dd/MM/yyyy"
                    value={values.dtBirth}
                    onChange={(date: Date | null) => { setFieldValue('dtBirth', date); }}
                    onBlur={handleBlur}
                    error={Boolean(errors.dtBirth && touched.dtBirth)}
                    helperText={errors.dtBirth && touched.dtBirth && errors.dtBirth}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.email && touched.email)}
                    helperText={errors.email && touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="CEP"
                    name="address.zipCode"
                    onChange={handleChange}
                    onBlur={(e: any) => onBlurCep(e, setFieldValue)}
                    error={Boolean(errors.address?.zipCode && touched.address?.zipCode)}
                    helperText={errors.address?.zipCode && touched.address?.zipCode && errors.address?.zipCode}
                    InputProps={{ inputComponent: CepMask as any }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Endereço"
                    name="address.street"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.street && touched.address?.street)}
                    helperText={errors.address?.street && touched.address?.street && errors.address?.street}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Bairro"
                    name="address.neighborhood"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.neighborhood && touched.address?.neighborhood)}
                    helperText={errors.address?.neighborhood && touched.address?.neighborhood && errors.address?.neighborhood}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Cidade"
                    name="address.city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.city && touched.address?.city)}
                    helperText={errors.address?.city && touched.address?.city && errors.address?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Nº"
                    name="address.number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.number && touched.address?.number)}
                    helperText={errors.address?.number && touched.address?.number && errors.address?.number}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <Field
                    as={TextField}
                    fullWidth
                    variant="outlined"
                    label="Complemento"
                    name="address.complement"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.address?.complement && touched.address?.complement)}
                    helperText={errors.address?.complement && touched.address?.complement && errors.address?.complement}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    variant="outlined"
                    label="Telefone"
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.phoneNumber && touched.phoneNumber)}
                    helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                    InputProps={{ inputComponent: PhoneMask as any }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress size={68} className={classes.fabProgress} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
