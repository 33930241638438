import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const component = ({ csvData, fileName }: any) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csv: any, name: string) => {
    const ws = XLSX.utils.json_to_sheet(csv);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + fileExtension);
  };

  const clearData = csvData.map(({ id, photoURL, ...x }: any) => x);

  return (
    <IconButton
      size="small"
      color="primary"
      className="text-primary"
      title="Exportar para excel"
      onClick={() => exportToCSV(clearData, fileName)}
    >
      <FontAwesomeIcon icon={['far', 'file-excel']} className="font-size-lg" />
    </IconButton>
  );
};

export default component;
