import { addDoc, alertCollection, getDateNow } from '../config/firebase';
import api from '../config/functionsApi';
import { PushNotification } from '../models/interfaces/pushNotification';

export default {
  async send(model: PushNotification) {
    return api.post('notifications', model);
  },

  async sendNotificationToDriver(callId: string, header: string, body: string, driverId: string, driverName: string) {
    return addDoc(alertCollection, {
      call: callId,
      createdAt: getDateNow(),
      driver: {
        id: driverId,
        name: driverName
      },
      message: {
        text: body,
        title: header
      },
      type: 'personalnotification'
    });
  },

  async sendNotificationToDriverAboutDocs(header: string, body: string, driverId: string, driverName: string) {
    return addDoc(alertCollection, {
      createdAt: getDateNow(),
      driver: {
        id: driverId,
        name: driverName
      },
      message: {
        text: body,
        title: header
      },
      type: 'personalnotification'
    });
  }
};
