/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance } from 'axios';
import { add, isBefore } from 'date-fns';

export default class BoazMicroserviceBase {
  _api: AxiosInstance;

  private _clientKey: string | null = '';

  private _clientName: string | null = '';

  authEndpoint = 'auth/token';

  constructor(baseUrl: string, clientKey: string | null, clientName: string | null) {
    this._api = axios.create({ baseURL: baseUrl });
    this._clientKey = clientKey;
    this._clientName = clientName;
  }

  async getToken(requestBody: any, minutes: number) {
    return this._api.post(this.authEndpoint, requestBody).then((response) => {
      const currentDateTime = new Date();
      const result = {
        token: response.data.token,
        expiresAt: add(currentDateTime, { minutes })
      };
      return result;
    }).catch((error) => error);
  }

  private async getNewToken() {
    if (!this._clientKey || !this._clientName) return;
    const requestBody = {
      username: this._clientName,
      password: this._clientKey
    };
    // eslint-disable-next-line consistent-return
    return this._api.post(this.authEndpoint, requestBody).then((response) => {
      const currentDateTime = new Date();
      const result = {
        token: response.data.token,
        expiresAt: add(currentDateTime, { minutes: 120 })
      };
      return result;
    }).catch((error) => error);
  }

  async getOrRenewToken(currentoken: any | null) {
    if (currentoken) {
      const currentDateTime = new Date();
      if (isBefore(currentDateTime, currentoken.expiresAt)) return currentoken;
      return this.getNewToken();
    }
    const resp = await this.getNewToken();
    return resp;
  }
}
