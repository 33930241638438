import React from 'react';
import {
  Grid, Avatar, Button, Card, Box, CardContent, TextField
} from '@mui/material';
import {
  Formik, Form, FormikProps, Field
} from 'formik';
import * as Yup from 'yup';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { useAuth } from '../../../../contexts/auth';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import Dictionary from '../../../../models/interfaces/dictionary';
import callService from '../../../../services/callService';
import NoData from '../../../../components/noData';
import ExportCSV from '../../../../components/exportExcel';
import ExportPDF from '../../../../components/exportPDF';
import PaymentType, { PaymentTypeList, PaymentTypeText } from '../../../../models/enums/paymentType';
import { getFirstDate, getLastDate } from '../../../../utils/dateHelper';
import Loading from '../../../../components/loading';

import BillingPrint from './billingPrint';

interface Formulario {
  paymentTypeList: Dictionary[];
  tax: number;
  startDate: Date | null;
  endDate: Date | null;
}

export interface Row {
  id: string;
  driverName: string;
  photoURL: string;
  callsTotal: number;
  faturado: number;
  faturadoPorcentagem: number;
  ganhosPresenciais: number;
  ganhosPresenciaisPorcentagem: number;
  total: number;
}

interface OwnProps {
  subsidiaryId: string;
}

const page = (own: OwnProps) => {
  let { subsidiaryId } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [rowsTable, setCalls] = React.useState<Row[]>([]);
  const [valuesForm, setValuesForm] = React.useState<Formulario>();
  const [isLoading, setIsLoading] = React.useState(false);
  if (own.subsidiaryId) subsidiaryId = own.subsidiaryId;

  const getDataByDriver = (report: any[], options: Formulario) => {
    const reportByDriver = _.groupBy(report, 'driver.id');
    const result = _.map(reportByDriver, (calls, driverId) => {
      const callsByPayment = _.groupBy(calls, 'payment.type');
      const group = _.map(callsByPayment, (callsP: any[], paymentType: string) => {
        let type = parseInt(paymentType, 10) as PaymentType;
        if (!type) type = PaymentType.Money; // TODO: Remove after fix payment.type as NaN

        const destinationsValueSum = options.tax === 0 ? callService.getDestinationsValueSum(callsP) : { destinationsSumValue: 0, destinationsSumFee: 0 };
        const totalValue = options.tax === 0 ? destinationsValueSum.destinationsSumValue : _.sumBy(callsP, 'payment.value');
        const totalSubsidiaryFee = options.tax === 0 ? destinationsValueSum.destinationsSumFee : 0;

        return {
          type,
          description: PaymentTypeText[type],
          calls: callsP,
          length: callService.getDestinationsCount(callsP),
          totalFee: totalSubsidiaryFee,
          total: totalValue
        };
      });

      let faturado = 0;
      let faturadoPorcentagem = 0;
      let paymentTypeSelected = options.paymentTypeList.map((x) => x.key);
      const callFiltered = _.filter(group, (x: any) => paymentTypeSelected.includes(x.type));
      if (paymentTypeSelected.includes(PaymentType.Faturado) || paymentTypeSelected.includes(PaymentType.Prepago)) {
        const callsFaturado = _.filter(callFiltered, (x: any) => x.type === PaymentType.Faturado || x.type === PaymentType.Prepago);
        faturado = _.sumBy(callsFaturado, (x) => x.total) ?? 0;
        faturadoPorcentagem = options.tax > 0
          ? (faturado * options.tax!) * 0.01
          : _.sumBy(callsFaturado, (x) => x.totalFee) ?? 0;
      }
      paymentTypeSelected = _.filter(paymentTypeSelected, (x) => x !== PaymentType.Faturado && x !== PaymentType.Prepago);
      const callsPresenciais = _.filter(callFiltered, (x: any) => paymentTypeSelected.includes(x.type));
      const ganhosPresenciais = _.sumBy(callsPresenciais, (x) => x.total);
      const ganhosPresenciaisPorcentagem = options.tax > 0
        ? (ganhosPresenciais * options.tax!) * 0.01
        : _.sumBy(callsPresenciais, (x) => x.totalFee) ?? 0;

      if (!calls[0].driver) {
        // TODO: Remover após descobrir o porquê existem calls com driver null
        // console.log(calls[0].id);
      }

      return {
        id: driverId,
        driverName: calls[0].driver?.name ?? '?',
        photoURL: calls[0].driver?.photo ?? '',
        callsTotal: _.sumBy(callFiltered, 'length'),
        faturado,
        faturadoPorcentagem: faturadoPorcentagem ?? 0,
        ganhosPresenciais: ganhosPresenciais ?? 0,
        ganhosPresenciaisPorcentagem,
        total: faturado - (faturadoPorcentagem + ganhosPresenciaisPorcentagem)
      };
    });

    return result;
  };

  const sumTotal = (rows: Row[]): Row[] => {
    const result = _.sortBy(rows, (x) => x.driverName);

    result.push({
      id: uuidv4(),
      driverName: 'Total',
      photoURL: '',
      callsTotal: _.sumBy(result, 'callsTotal'),
      faturado: _.sumBy(result, 'faturado'),
      faturadoPorcentagem: _.sumBy(result, 'faturadoPorcentagem'),
      ganhosPresenciais: _.sumBy(result, 'ganhosPresenciais'),
      ganhosPresenciaisPorcentagem: _.sumBy(result, 'ganhosPresenciaisPorcentagem'),
      total: _.sumBy(result, 'total')
    });

    return result;
  };

  return (
    <>
      <WrapperSimple sectionHeading="Relatório de faturamento">
        <Formik
          initialValues={{
            paymentTypeList: PaymentTypeList,
            tax: 15,
            startDate: getFirstDate(),
            endDate: getLastDate()
          }}
          validationSchema={Yup.object().shape({
            paymentTypeList: Yup.array().required().min(1, 'Campo obrigatório'),
            tax: Yup.number().required().min(0).max(100),
            startDate: Yup.date().required().nullable(),
            endDate: Yup.date().required().nullable()
          })}
          onSubmit={async (values: Formulario, actions) => {
            setIsLoading(true);
            values.endDate?.setSeconds(59);
            setValuesForm(values);

            callService.getReport(subsidiaryId, values.startDate!, values.endDate!, null, null).then((report) => {
              const data = getDataByDriver(report, values);
              setCalls(sumTotal(data));
            }).catch((e) => {
              enqueueSnackbar(e.message, { variant: 'error' });
            }).finally(() => {
              actions.setSubmitting(false);
              setIsLoading(false);
            });
          }}
        >
          {(props: FormikProps<Formulario>) => {
            const {
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue
            } = props;

            return (
              <Form noValidate autoComplete="off">
                <Field type="hidden" name="address.ibge" value="" />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                      multiple
                      options={PaymentTypeList}
                      getOptionLabel={(option: any) => option.value}
                      value={values.paymentTypeList}
                      getOptionSelected={(x) => values.paymentTypeList.map((w) => w.key).includes(x.key)}
                      onChange={(e, value) => setFieldValue('paymentTypeList', value)}
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipos de pagamentos"
                          variant="outlined"
                          placeholder="Selecione..."
                          onChange={handleChange}
                          autoFocus
                          fullWidth
                          error={Boolean(errors.paymentTypeList && touched.paymentTypeList)}
                          helperText={errors.paymentTypeList && touched.paymentTypeList && errors.paymentTypeList}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      name="tax"
                      variant="outlined"
                      label="Percentual para cálculo ou 0 (zero) para taxa dinâmica"
                      type="number"
                      fullWidth
                      defaultValue={values.tax}
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.tax && touched.tax)}
                      helperText={errors.tax && touched.tax && errors.tax}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Início"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="startDate"
                      onChange={(date: Date | null) => { setFieldValue('startDate', date); }}
                      onBlur={handleBlur}
                      value={values.startDate}
                      error={Boolean(errors.startDate && touched.startDate)}
                      helperText={errors.startDate && touched.startDate && errors.startDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <KeyboardDateTimePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label="Fim"
                      format="dd/MM/yyyy HH:mm"
                      ampm={false}
                      InputLabelProps={{ shrink: true }}
                      name="endDate"
                      onChange={(date: Date | null) => { setFieldValue('endDate', date); }}
                      onBlur={handleBlur}
                      value={values.endDate}
                      error={Boolean(errors.endDate && touched.endDate)}
                      helperText={errors.endDate && touched.endDate && errors.endDate}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting} fullWidth>
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={['far', 'keyboard']} />
                      </span>
                      <span className="btn-wrapper--label">Buscar</span>
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </WrapperSimple>
      {isLoading ? <Loading /> : (
        <>
          {
            rowsTable && (
              rowsTable.length > 0 ? (
                <Card className="card-box mb-4">
                  <div className="card-header">
                    <div className="card-header--title" />
                    <Box className="card-header--actions">
                      {' | '}
                      <ExportPDF
                        document={(
                          <BillingPrint
                            data={rowsTable}
                            paymentTypes={valuesForm!.paymentTypeList}
                            percentage={valuesForm!.tax}
                            startDate={valuesForm!.startDate}
                            endDate={valuesForm!.endDate}
                          />
                        )}
                        fileName={format(new Date(), 'yyyyMMdd_hhmmss')}
                      />
                      {' | '}
                      <ExportCSV csvData={rowsTable} fileName={format(new Date(), 'yyyyMMdd_hhmmss')} />
                    </Box>
                  </div>
                  <CardContent className="p-0">
                    <div className="table-responsive">
                      <table className="text-nowrap mb-0 table table-borderless table-hover">
                        <thead>
                          <tr>
                            <th className="text-left" title="Nome do condutor">Condutor</th>
                            <th className="text-right" title="Número de corridas com base no filtro">Corridas</th>
                            <th className="text-right" title="Valor que a franquia deve passar para o condutor (Faturado e pré pago)">Faturado</th>
                            <th className="text-right" title="Valor que a franquia já recebeu">% Franquia</th>
                            <th className="text-right" title="Valor que o condutor já recebeu">Presencial</th>
                            <th className="text-right" title="Valor que a franquia deve receber do condutor">% Franquia</th>
                            <th className="text-right" title="(Faturado - %Franquia) - %Presencial/franquia">Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rowsTable.map((x) => (
                            <tr key={x.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Avatar alt="x.driverName" src={x.photoURL} className="mr-2" />
                                  <div>
                                    <a href="#/" onClick={(e) => e.preventDefault()} className="font-weight-bold text-black">
                                      {x.driverName}
                                    </a>
                                  </div>
                                </div>
                              </td>
                              <td className="text-right">
                                {x.callsTotal}
                              </td>
                              <td className="text-right">
                                {x.faturado?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                              <td className="text-right">
                                {x.faturadoPorcentagem?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                              <td className="text-right">
                                {x.ganhosPresenciais?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                              <td className="text-right">
                                {x.ganhosPresenciaisPorcentagem?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                              <td className={`text-right ${x.total >= 0 ? 'text-success' : 'text-danger'}`}>
                                {x.total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardContent>
                </Card>
              ) : <NoData message="Nenhuma informação encontrada." />
            )
          }
        </>
      )}
    </>
  );
};

export default page;
