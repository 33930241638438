import React from 'react';
import { Route } from 'react-router-dom';

import Login from '../pages/auth/login';
import RecoverPassword from '../pages/auth/recoverPassword';
import Error403 from '../pages/status/403';
import Error404 from '../pages/status/404';
import Error500 from '../pages/status/500';
import Error503 from '../pages/status/503';

const PublicRoutes = () => (
  <>
    <Route exact path="/login" component={Login} />
    <Route exact path="/recover-password" component={RecoverPassword} />
    <Route exact path="/403" component={Error403} />
    <Route exact path="/404" component={Error404} />
    <Route exact path="/500" component={Error500} />
    <Route exact path="/503" component={Error503} />
  </>
);

export default PublicRoutes;
