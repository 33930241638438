import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as RouterLink } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

import svgImage8 from '../../assets/images/illustrations/505.svg';

export default function PagesError503() {
  return (
    <div className="app-wrapper bg-secondary">
      <div className="app-main">
        <div className="app-content p-0">
          <div className="app-inner-content-layout--main">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content">
                <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                  <div className="flex-grow-1 w-100 d-flex align-items-center">
                    <Grid item lg={6} md={9} className="px-4 mx-auto text-center text-black">
                      <img src={svgImage8} className="w-50 mx-auto d-block my-5 img-fluid" alt="" />
                      <h1 className="display-1 mb-3 px-4 font-weight-bold">Serviço indisponível</h1>
                      <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">Algum imprevisto aconteceu.</h3>
                      <p>Fomos alertados automaticamente sobre o problema e trabalharemos para corrigi-lo o mais rápido possível.</p>
                      <Button
                        component={RouterLink}
                        to="/dashboard"
                        size="large"
                        color="primary"
                        variant="contained"
                        className="text-white mt-4"
                      >
                        <span className="btn-wrapper--icon"><FontAwesomeIcon icon={['fas', 'arrow-left']} /></span>
                        <span className="btn-wrapper--label">Voltar para o dashboard</span>
                      </Button>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
