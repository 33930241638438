import React from 'react';
import {
  Grid,
  TextField,
  Container,
  Card,
  CardContent,
  Button,
  CircularProgress,
  Link,
  InputAdornment
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { useHistory } from 'react-router';
import MuiAlert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

import { useAuth } from '../../contexts/auth';

import logo from '../../assets/images/logo.png';

interface Values {
  email: string,
  password: string
}

const Page = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState<string>('');

  return (
    <div className="app-wrapper min-vh-100">
      <div className="app-main flex-column">
        <div className="app-content p-0">
          <div className="app-content--inner d-flex align-items-center">
            <div className="flex-grow-1 w-100 d-flex align-items-center">
              <div className="bg-composed-wrapper--content py-5">
                <Container maxWidth="lg">
                  <Grid container spacing={5}>
                    <Grid item xs={12} lg={5} className="d-lg-flex align-items-center">
                      <img alt="" className="w-100 mx-auto d-block img-fluid" src={logo} data-test="img-logo" />
                    </Grid>
                    <Grid item xs={12} lg={7} className="d-flex flex-column align-items-center">
                      <Card className="m-0 w-100 p-0 border-0">
                        <CardContent className="p-3">
                          <div className="text-center text-black-50 mb-3"><span>Informe suas credenciais</span></div>
                          <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={Yup.object().shape({
                              email: Yup.string().email().required(),
                              password: Yup.string().required()
                            })}
                            onSubmit={async (values: Values) => {
                              setIsLoading(true);

                              await signIn(values.email, values.password).then(() => {
                                history.push('/dashboard');
                              }).catch((e: any) => {
                                switch (e.code) {
                                  case 'auth/user-not-found':
                                    setMessage('Credenciais inválidas');
                                    break;
                                  case 'auth/wrong-password':
                                    setMessage('Credenciais inválidas');
                                    break;
                                  case 'auth/user-disabled':
                                    setMessage('Conta desativada');
                                    break;
                                  default:
                                    setMessage(e.message);
                                }
                                setIsLoading(false);
                              });
                            }}
                          >
                            {(props: FormikProps<Values>) => {
                              const {
                                values, errors, touched, handleChange, handleBlur
                              } = props;
                              return (
                                <Form noValidate autoComplete="off" className="px-3">
                                  <div className="mb-3">
                                    <TextField
                                      fullWidth
                                      required
                                      autoFocus
                                      label="Email"
                                      name="email"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      defaultValue={values.email}
                                      error={Boolean(errors.email && touched.email)}
                                      helperText={errors.email && touched.email && errors.email}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <EmailOutlinedIcon />
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <TextField
                                      fullWidth
                                      required
                                      label="Senha"
                                      name="password"
                                      type="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      defaultValue={values.password}
                                      error={Boolean(errors.password && touched.password)}
                                      helperText={errors.password && touched.password && errors.password}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <LockOutlinedIcon />
                                          </InputAdornment>
                                        )
                                      }}
                                    />
                                  </div>
                                  <Grid container spacing={3}>
                                    <Grid item xs={6} />
                                    <Grid item xs={6} className="text-right">
                                      <Link href="/recover-password">Esqueci minha senha</Link>
                                    </Grid>
                                  </Grid>

                                  {message && (
                                    <div className="text-center">
                                      <MuiAlert className="mb-4" severity="warning">
                                        <div className="d-flex align-items-center align-content-center">
                                          <span>
                                            <strong className="d-block">{message}</strong>
                                          </span>
                                        </div>
                                      </MuiAlert>
                                    </div>
                                  )}

                                  <div className="text-center">
                                    <Button color="primary" variant="contained" size="large" className="my-2" type="submit">
                                      {isLoading ? <CircularProgress size={25} color="secondary" /> : 'Entrar'}
                                    </Button>
                                  </div>
                                </Form>
                              );
                            }}
                          </Formik>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
