/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { EnumDictionary } from '../interfaces/enumDictionary';

enum BalanceStatus {
  Undefined = 0,
  Waiting = 'waiting',
  Approved = 'approved',
  Canceled = 'canceled',
}

const BalanceStatusText: EnumDictionary<BalanceStatus, string> = {
  [BalanceStatus.Undefined]: '',
  [BalanceStatus.Waiting]: 'Aguardando',
  [BalanceStatus.Approved]: 'Aprovado',
  [BalanceStatus.Canceled]: 'Cancelado'
};

export { BalanceStatus as default, BalanceStatusText };
