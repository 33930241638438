import _ from 'lodash';
import { GeoPoint } from '../config/firebase';

import Address from '../models/interfaces/address';
import Region from '../models/interfaces/region';

const regionsJson = require('../assets/data/regions.json');

const googleUrl = 'https://maps.googleapis.com/maps';

export const viacep = (zipCode: string) => fetch(`https://viacep.com.br/ws/${zipCode}/json`)
  .then((response) => response.json())
  .then((data) => data);

export const getGeocoding = (address: Address): Promise<GeoPoint | null> => {
  const rowAddress = `${address.number} ${address.street} ${address.neighborhood} ${address.city}`;
  return fetch(`${googleUrl}/api/geocode/json?address=${rowAddress}&key=${process.env.REACT_APP_ADMIN_MAPS_API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return new GeoPoint(lat, lng);
      }
      return null;
    });
};

export const getPlaceGeocodingPlus = (neighborhood: string, city: string, state: string): Promise<any | null> => {
  const rowAddress = `${neighborhood} ${city} ${state}`;
  return fetch(`${googleUrl}/api/geocode/json?address=${rowAddress}&key=${process.env.REACT_APP_ADMIN_MAPS_API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      console.log('teste google', data);
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        const placeId = data.results[0].place_id;
        const { northeast, southwest } = data.results[0].geometry.bounds || data.results[0].geometry.viewport;
        const coords = new GeoPoint(lat, lng);
        return {
          coords,
          placeId,
          bounds: {
            northeast,
            southwest
          }
        };
      }
      return null;
    });
};

export const getPlaceGeocoding = (neighborhood: string, city: string, state: string): Promise<GeoPoint | null> => {
  const rowAddress = `${neighborhood} ${city} ${state}`;
  return fetch(`${googleUrl}/api/geocode/json?address=${rowAddress}&key=${process.env.REACT_APP_ADMIN_MAPS_API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return new GeoPoint(lat, lng);
      }
      return null;
    });
};

export const getGeoPoint = (lat: number, lng: number): GeoPoint => new GeoPoint(lat, lng);

export const getAddressByGoogle = (address: Address) => {
  const rowAddress = `${address.number} ${address.street} ${address.neighborhood} ${address.city}`;
  return fetch(`${googleUrl}/api/geocode/json?address=${rowAddress}&key=${process.env.REACT_APP_ADMIN_MAPS_API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'OK') {
        return data.results[0];
      }
      return null;
    });
};

export const setAddressByCEP = (ev: any, setFieldValue: any): Promise<any> => {
  const { value } = ev.target;
  const cep = value?.replace(/[^0-9]/g, '');
  if (cep?.length !== 8) return Promise.resolve();

  return viacep(cep).then((data) => {
    if (data.erro) {
      throw new Error('CEP inválido');
    }

    setFieldValue('address.zipCode', cep);
    setFieldValue('address.street', data.logradouro);
    setFieldValue('address.neighborhood', data.bairro);
    setFieldValue('address.city', data.localidade);
    setFieldValue('address.state', data.uf);
    setFieldValue('address.number', '');
    setFieldValue('address.complement', '');
    setFieldValue('address.ibge', data.ibge);
  });
};

export const getRegion = (city: string): Region | null => _.find(regionsJson, (x) => x.MUNICIPIO === city) ?? null;
