import CustomSnapShopDoc from './customSnapShotDoc';

export default class CustomSnapShot {
    docs: CustomSnapShopDoc[];

    public size() {
      return this.docs.length;
    }

    public empty() {
      return this.docs.length === 0;
    }

    constructor() {
      // eslint-disable-next-line no-array-constructor
      this.docs = new Array<CustomSnapShopDoc>();
    }
}
