import _ from 'lodash';

export const removeMask = (number: string | null) => number?.replace(/[^0-9]/g, '');

export const titleCase = (str: string) => {
  if (typeof str !== 'string') return '';

  return _.startCase(_.toLower(str));
};

export const firstLastName = (str: string): string => {
  const nameSplit = str.split(' ');
  if (nameSplit.length < 2) throw new Error('É necessario informar o nome completo');
  return `${nameSplit[0]} ${nameSplit[nameSplit.length - 1]}`;
};
