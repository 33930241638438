// eslint-disable-next-line import/no-extraneous-dependencies
import io from 'socket.io-client';

const URL = process.env.REACT_APP_SOCKET_TRACKER_URL || 'https://boaztech-delivery-tracker.herokuapp.com';
const options = {
  // eslint-disable-next-line no-tabs
  transports: ['websocket']
};
// eslint-disable-next-line import/prefer-default-export
export const socket = io(URL, options);
