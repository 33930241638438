import React from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, withStyles } from '@material-ui/core';
import {
  Avatar,
  TextField,
  Box,
  Badge,
  Button,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { useAuth } from '../../contexts/auth';
import authService from '../../services/authService';
import { getAuth, updatePassword } from '../../config/firebase';

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
})(Badge);

const HeaderUserbox = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPasswordModal, setShowPasswordModal] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');

  const handleClick = (event: any) => { setAnchorEl(event.currentTarget); };

  const handleClose = () => { setAnchorEl(null); };

  const handleSignOut = () => { authService.signOut().then(() => { history.push('/'); }); };

  const closeModal = () => { setShowPasswordModal(false); };

  const changePassword = () => {
    const { currentUser } = getAuth();
    if (currentUser) {
      updatePassword(currentUser, newPassword).then(() => {
        closeModal();
        enqueueSnackbar('Senha alterada com sucesso!', { variant: 'success' });
      }).catch(() => {
        enqueueSnackbar('Ops, aconteceu algo inesperado', { variant: 'error' });
      });
    }
  };

  const getRole = () => {
    const claims = user?.claims;
    if (claims) {
      if (claims.admin) return 'Administrador';
      if (claims.manager) return 'Gerente';
    }

    return '';
  };

  const getPicture = () => {
    const name = user?.displayName ?? '?';
    return <Avatar sizes="44" alt={name} src={user?.photoURL}>{name.substr(0, 1)}</Avatar>;
  };

  return user
    ? (
      <>
        <Button
          color="inherit"
          onClick={handleClick}
          className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center"
        >
          <Box>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              variant="dot"
            >
              {getPicture()}
            </StyledBadge>
          </Box>
          <div className="d-none d-md-block pl-3">
            <div className="font-weight-bold pt-2 line-height-1">
              {user.displayName}
            </div>
            <span className="text-white-50">{getRole()}</span>
          </div>
          <span className="pl-1 pl-xl-3">
            <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
          </span>
        </Button>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
          onClose={handleClose}
          className="ml-2"
        >
          <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
            <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
              <Box>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  variant="dot"
                >
                  {getPicture()}
                </StyledBadge>
              </Box>
              <div className="pl-3 ">
                <div className="font-weight-bold text-center pt-2 line-height-1">
                  {user.displayName}
                </div>
                <span className="text-black-50 text-center">
                  {getRole()}
                </span>
              </div>
              <Divider className="w-100 mt-2" />
              <ListItem button onClick={() => { history.push('/profile'); }}>Perfil</ListItem>
              <ListItem button onClick={() => { setShowPasswordModal(true); }}>Alterar senha</ListItem>
              <ListItem button onClick={handleSignOut}>Sair</ListItem>
            </List>
          </div>
        </Menu>

        <Dialog open={showPasswordModal} onClose={closeModal} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Alteração de senha</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              variant="outlined"
              label="Nova senha"
              type="password"
              onChange={(v) => { setNewPassword(v.target.value); }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">Cancelar</Button>
            <Button onClick={changePassword} color="primary">Alterar</Button>
          </DialogActions>
        </Dialog>
      </>
    )
    : (<></>);
};

export default HeaderUserbox;
