import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import Subsidiaries from './subsidiaries';
import SubsidiariesCreate from './subsidiaries/create';
import SubsidiariesEdit from './subsidiaries/edit';
import SubsidiariesDetails from './subsidiaries/details';

import Customers from '../../customers';
import CustomersCreate from '../../customers/create';
import CustomersDetails from '../../customers/details';
import CustomersEdit from '../../customers/edit';
import CustomersAsAdmin from './customers';

import Deliveries from '../../deliveries';
import DeliveriesReport from '../../deliveries/report';
import Map from '../../dashboard/map';
import DeliveriesMap from '../../deliveries/map';

import Drivers from '../../drivers';
import DriverDetails from '../../drivers/details';
import DriverEdit from '../../drivers/edit';
import DriversAsAdmin from './drivers';
import DriversRealTime from './drivers/map-real-time';
import RevenueAsAdmin from './subsidiaries/financial/revenue';
import BillingAsAdmin from './subsidiaries/financial/billing';
import DeliveriesAsAdmin from './subsidiaries/deliveries';

import Users from '../../users';
import UsersCreate from '../../users/create';
import UserDetails from '../../users/details';
import UserEdit from '../../users/edit';

import Administrators from './administrators';
import AdministratorsCreate from './administrators/create';

import Managers from './managers';
import ManagersCreate from './managers/create';

import Settings from './settings';
import SettingsCreate from './settings/create';

import TariffsCreate from '../../tariffs/create';
import TariffsEdit from '../../tariffs/edit';

const Dashboard = lazy(() => import('../../dashboard'));
const Chart = lazy(() => import('../../dashboard/chart'));
const Profile = lazy(() => import('../../auth/profile'));

const AdminRoutes = () => (
  <Switch>
    <Route exact path="/profile" component={Profile} />

    <Route exact path="/dashboard" component={Dashboard} />
    <Route exact path="/chart" component={Chart} />

    <Route exact path="/subsidiaries" component={Subsidiaries} />
    <Route path="/subsidiaries/create" component={SubsidiariesCreate} />
    <Route exact path="/subsidiaries/:id" component={SubsidiariesDetails} />
    <Route path="/subsidiaries/:id/edit" component={SubsidiariesEdit} />
    <Route path="/subsidiaries/:subsidiaryId/tariffs/:tariffId/edit" component={TariffsEdit} />
    <Route path="/subsidiaries/:subsidiaryId/tariffs/create" component={TariffsCreate} />
    <Route exact path="/subsidiaries/:id/customers" component={CustomersAsAdmin} />
    <Route exact path="/subsidiaries/:id/drivers" component={DriversAsAdmin} />
    <Route exact path="/subsidiaries/:id/revenue" component={RevenueAsAdmin} />
    <Route exact path="/subsidiaries/:id/billing" component={BillingAsAdmin} />
    <Route exact path="/subsidiaries/:id/deliveries" component={DeliveriesAsAdmin} />
    <Route exact path="/subsidiaries/:id/real-time" component={DriversRealTime} />

    <Route exact path="/companies" component={Customers} />
    <Route path="/companies/create" component={CustomersCreate} />
    <Route exact path="/companies/:id" component={CustomersDetails} />
    <Route path="/companies/:id/edit" component={CustomersEdit} />

    <Route exact path="/deliveries" component={Deliveries} />
    <Route exact path="/deliveries-report" component={DeliveriesReport} />
    <Route exact path="/map" component={Map} />

    <Route exact path="/deliveries/:id/map" component={DeliveriesMap} />

    <Route exact path="/drivers" component={Drivers} />
    {/* <Route path="/drivers/create" component={SubsidiariesCreate} /> */}
    <Route exact path="/drivers/:id" component={DriverDetails} />
    <Route path="/drivers/:id/edit" component={DriverEdit} />

    <Route exact path="/administrators" component={Administrators} />
    <Route path="/administrators/create" component={AdministratorsCreate} />

    <Route exact path="/managers" component={Managers} />
    <Route path="/managers/create" component={ManagersCreate} />

    <Route exact path="/users" component={Users} />
    <Route path="/users/create" component={UsersCreate} />
    <Route exact path="/users/:id" component={UserDetails} />
    <Route path="/users/:id/edit" component={UserEdit} />

    <Route exact path="/settings" component={Settings} />
    <Route path="/settings/create" component={SettingsCreate} />
  </Switch>
);

export default AdminRoutes;
