import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Table from '../../../../components/table';
import { useAuth } from '../../../../contexts/auth';
import CategoryDriver, { CategoryDriverText } from '../../../../models/enums/categoryDriver';
import tariffService from '../../../../services/tariffService';
import { usePagination } from './usePaginationList';
import {
  orderBy, query, tariffCollection, where
} from '../../../../config/firebase';
import FilterHelper from '../../../../utils/filterHelper';

export default function index() {
  const history = useHistory();
  const { subsidiaryId } = useAuth();
  const [total, setTotal] = React.useState(0);
  const [enableSearch, setEnableSearch] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<any>([]);

  const queryParams = subsidiaryId === undefined
    ? query(tariffCollection, orderBy('subsidiary', 'asc'))
    : query(tariffCollection, orderBy('subsidiary', 'asc'), where('subsidiary', '==', subsidiaryId));

  const {
    items: itemsPag,
    isLoading,
    getPrev,
    getNext,
    current,
    currentTotal,
    isStart,
    isEnd
  } = usePagination(
    queryParams,
    {
      limit: 10
    }
  );

  useEffect(() => {
    getTotal();
  }, []);

  const getTotal = async () => {
    await tariffService.getAllCount(subsidiaryId).then((item: any) => {
      setTotal(item.total);
    });
  };

  const searchItem = useCallback(async (params: string) => {
    const snapshot = await FilterHelper.prepareSnapshot('description', params, tariffCollection);
    const results = snapshot?.docs.map((snap) => {
      const {
        description, categoryDriver, calculationMethod, disabledAt, companyName
      } = snap.data();

      return {
        id: snap.id,
        description,
        companyName: companyName || 'Todos',
        disabledAt: disabledAt?.toDate(),
        categoryDriver,
        calculationMethod
      };
    });

    setEnableSearch(true);
    setItems(results);
    setTotal(results.length);
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'Id',
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: 'description',
      label: 'Descrição',
      options: {
        filter: true,
        sort: true
      }
    },
    {
      name: 'categoryDriver',
      label: 'Categoria',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: CategoryDriver) => CategoryDriverText[value]
      }
    },
    {
      name: 'companyName',
      label: 'Cliente',
      options: {
        filter: true,
        sort: true
      }
    }
  ];

  const onRowClick = (rowData: string[]) => {
    const id = rowData[0];
    if (id) {
      history.push(`/settings/tariffs/${id}/edit`);
    }
  };

  const tableChange = useCallback((action: string, state: any) => {
    if (action === 'search') {
      if (state.searchText !== null) {
        searchItem(state.searchText);
      }
    }

    if (action === 'onSearchClose') {
      setEnableSearch(false);
      setItems([]);
      getTotal();
    }

    if (action === 'filterChange') {
      // getFilter(state.filterList);
    }
  }, []);

  return (
    <Table
      title="Tarifas"
      createUrl={subsidiaryId ? `/subsidiaries/${subsidiaryId}/tariffs/create` : undefined}
      data={enableSearch ? items : itemsPag}
      columns={columns}
      filter={false}
      onRowClick={onRowClick}
      onTableChange={tableChange}
      serverSide
      count={total}
      pagination={false}
      isLoading={isLoading}
      getPrev={getPrev}
      getNext={getNext}
      current={current}
      currentTotal={currentTotal}
      isStart={isStart}
      isEnd={isEnd}
      enableSearch={enableSearch}
    />
  );
}
