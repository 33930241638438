import React, { useEffect } from 'react';
import {
  Grid,
  Card,
  Button
} from '@mui/material';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { getErrorMessage } from '../../config/firebase';
import userService from '../../services/userService';
import {
  cpfOrCnpj, phoneNumber, toBrazilDate, toBrazilDateTime, zipCode
} from '../../components/mask';
import { DetailUser } from '../../models/interfaces/user';

interface ParamTypes {
  id: string
}

const Details = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [model, setModel] = React.useState<DetailUser>();
  const { id } = useParams<ParamTypes>();

  useEffect(() => {
    userService.getByIdAsync(id).then(async (doc) => {
      setModel(doc);
    }).catch(() => {
      history.push('/404');
    });
  }, []);

  if (model === undefined) {
    return (<></>);
  }

  const disable = (firebaseId: string) => {
    const disabled = !model.disabled;
    userService.disableAsync(disabled, firebaseId).then(() => {
      setModel({ ...model, disabled });
    }).catch((e) => {
      enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className="card-box mb-4 pt-4">
          {model.disabled && (
            <span className="ribbon-angle ribbon-angle--top-left ribbon-danger">
              <small>Desativado</small>
            </span>
          )}
          <div className="d-flex align-items-center px-4 mb-3">
            <div className="avatar-icon-wrapper rounded mr-3">
              <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                {model.photoURL
                  ? (
                    <div className="rounded overflow-hidden">
                      <img alt="" className="img-fluid" src={model.photoURL} />
                    </div>
                  )
                  : (
                    <div className="rounded overflow-hidden d-100 bg-neutral-first font-size-lg text-center font-weight-bold text-first d-flex justify-content-center flex-column">
                      <span>{model.name.substring(0, 1)}</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="w-100">
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="font-weight-bold font-size-lg"
                title=""
              >
                {model.name}
              </a>
              <span className="text-black-50 d-block pb-1">
                {phoneNumber(model.phoneNumber!)}
              </span>
              <div className="d-flex align-items-center pt-2">
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className="mr-3"
                  component={Link}
                  to={`${model.id}/edit`}
                >
                  Editar
                </Button>
                <Button size="small" variant="outlined" color="secondary" onClick={() => disable(model.uid)}>
                  {model.disabled ? 'Ativar' : 'Desativar'}
                </Button>
              </div>
            </div>
          </div>
          <div className="my-3 font-size-sm p-3 mx-4 bg-secondary rounded-sm">
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">Email:</span>
              <span className="text-black-50">{model.email}</span>
            </div>
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">CPF:</span>
              <span className="text-black-50">{cpfOrCnpj(model.cpf)}</span>
            </div>
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">Data de nascimento:</span>
              <span className="text-black-50">{toBrazilDate(model.dtBirth)}</span>
            </div>
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">Endereço:</span>
              <span className="text-black-50">
                {model.address.street}
                {' '}
                {model.address.number}
                {', '}
                {model.address.neighborhood}
                {', '}
                {zipCode(model.address.zipCode)}
              </span>
            </div>
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">Criado em:</span>
              <span className="text-black-50">{toBrazilDateTime(model.metadata.creationTime)}</span>
            </div>
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">Último acesso:</span>
              <span className="text-black-50">{model.metadata.lastSignInTime != null ? toBrazilDateTime(model.metadata.lastSignInTime) : '?'}</span>
            </div>
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Details;
