export default {

  setParams(text: string, user: any, subsidiary: any) {
    let newtext = text.replace(/{{driverName}}/g, user.name);
    newtext = newtext.replace(/{{driverDoc}}/g, this.formatCPF(user.cpf));
    newtext = newtext.replace(/{{driverCity}}/g, user.address.city);
    newtext = newtext.replace(/{{driverState}}/g, user.address.state);
    newtext = newtext.replace(/{{driverStreet}}/g, user.address.street);
    newtext = newtext.replace(/{{driverNumber}}/g, user.address.number);
    newtext = newtext.replace(/{{driveNeighborhood}}/g, user.address.neighborhood);
    newtext = newtext.replace(/{{driverZipcode}}/g, this.formatCEP(user.address.zipCode));
    newtext = newtext.replace(/{{driverId}}/g, user.rg);
    newtext = newtext.replace(/{{userinfo}}/g, `${user.name} CPF: ${this.formatCPF(user.cpf)}`);
    const state = subsidiary?.address?.state ? subsidiary.address.state : '';
    newtext = newtext.replace(/{{subsidiaryunit}}/g, subsidiary.description);
    newtext = newtext.replace(/{{subsidiarylocal}}/g, `${subsidiary.address.city}-${state}`);
    return newtext;
  },

  setParamsSubsidiary(text: string, subsidiary: any) {
    const state = subsidiary?.address?.state ? subsidiary.address.state : '';
    let newtext = text.replace(/{{subsidiaryunit}}/g, subsidiary.description);
    newtext = newtext.replace(/{{subsidiarylocal}}/g, `${subsidiary.address.city}-${state}`);

    return newtext;
  },

  formatCPF(value: string) {
    let cpf = value;
    cpf = cpf.replace(/[^\d]/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  },

  formatCEP(str: string) {
    const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *
    if (re.test(str)) {
      return str.replace(re, '$1.$2-$3');
    }
    console.log('CEP inválido!');
    return '';
  },

  getMonthName(monthNumber: number) {
    const monthNames = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'Julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'];
    return monthNames[monthNumber];
  },

  replaceAll(str: string, find: string, replace: string) {
    if (str) return str.replace(new RegExp(find, 'g'), replace);
    return '';
  }
};
