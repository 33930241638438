import React, { useCallback, useEffect } from 'react';

import {
  Field, Form, Formik, FormikProps
} from 'formik';

import {
  Button,
  CircularProgress,
  Fab,
  Grid,
  Switch
} from '@mui/material';
import { TextField } from '@material-ui/core';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import WrapperSimple from '../../../../layout-components/ExampleWrapperSimple';
import UseStyles from '../../../../components/styles/loadingButton';
import { useAuth } from '../../../../contexts/auth';
import placesService from '../../../../services/placesService';
import { getErrorMessage } from '../../../../config/firebase';
import Place from '../../../../models/interfaces/place';

interface ParamTypes {
  placeId: string;
}

export default function create() {
  const history = useHistory();
  const { placeId } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const classes = UseStyles();
  const [success, setSuccess] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const buttonClassname = clsx({ [classes.buttonSuccess]: success });
  const { subsidiaryId } = useAuth();
  const [initialState, setInitialState] = React.useState({
    subsidiaryId: '',
    name: '',
    description: '',
    status: null,
    disabledat: '',
    autocompletename: '',
    city: '',
    createdat: '',
    lat: 0,
    lng: 0,
    neighborhood: '',
    placeid: '',
    state: '',
    subsidiaryid: '',
    updatedat: 0
  });

  const populate = async (setFieldValue: any) => {
    await placesService.readOne(placeId).then((result: any) => {
      console.log('resposta', result);
      if (result.status === 200 && result.data !== '') {
        const fields = [
          'subsidiaryId',
          'name',
          'description',
          'status',
          'autocompletename',
          'city',
          'createdat',
          'lat',
          'lng',
          'neighborhood',
          'placeid',
          'state',
          'subsidiaryid',
          'updatedat'
        ];

        const disabledatValue = (result.data.disabledat == null);
        // setFieldValue('status', disabledatValue);
        setStatus(disabledatValue);

        fields.forEach((field) => setFieldValue(field, result.data[field], false));
      }
    });
  };

  const onSave = useCallback(async (values: Place) => {
    const payload: Place = {
      subsidiaryId: subsidiaryId ?? '',
      name: values.name,
      description: values.description,
      disabledat: values.status ? null : new Date(Date.now()),
      status: true
    };

    const statusItem = values.status !== undefined ? values.status : status;

    console.log('statusItem', statusItem);

    if (statusItem) {
      const change = await placesService.enable(placeId);
      console.log('change hab.', change);
    } else {
      const change = await placesService.disable(placeId);
      console.log('change desab.', change);
    }

    await placesService.updateConsulta(payload).then(() => {
      setSuccess(true);
      console.log('Deu certo');
      enqueueSnackbar('Cadastro realizado com sucesso!', { variant: 'success' });
      setTimeout(() => {
        history.push('/settings/places');
      }, 1000);
    })
      .catch((e) => {
        setSuccess(false);
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
      });
  }, []);

  const onDelete = useCallback(async () => {
    setDeleting(true);
    await placesService
      .delete(placeId)
      .then(() => {
        setDeleting(false);
        setTimeout(() => {
          history.push('/settings/places');
        }, 1000);
      })
      .catch((e) => {
        enqueueSnackbar(getErrorMessage(e), { variant: 'error' });
        setSuccess(false);
      });
  }, []);

  return (
    <WrapperSimple sectionHeading="Editar localidade">
      <Formik
        initialValues={initialState}
        onSubmit={onSave}
      >
        {(props: FormikProps<Place>) => {
          const {
            errors, touched, isSubmitting, setFieldValue, values
          } = props;

          useEffect(() => {
            populate(setFieldValue);
          }, []);

          console.log('status', status);
          console.log('values.status', values.status);

          return (
            <Form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    autoFocus
                    variant="outlined"
                    label="Nome"
                    name="name"
                    error={Boolean(errors.name && touched.name)}
                    helperText={
                      errors.name
                      && touched.name
                      && errors.name
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Field
                    as={TextField}
                    fullWidth
                    required
                    autoFocus
                    variant="outlined"
                    label="Observação"
                    name="description"
                    error={Boolean(errors.description && touched.description)}
                    helperText={
                      errors.description
                      && touched.description
                      && errors.description
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Switch
                    checked={status}
                    color="primary"
                    edge="start"
                    name="status"
                    onChange={(v: any) => {
                      setStatus(v.target.checked);
                      setFieldValue('status', v.target.checked);
                      setInitialState({ ...initialState, status: v.target.checked });
                    }}
                    value={status || false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end" spacing={3}>
                    <div className={classes.wrapper}>
                      <Fab
                        aria-label="save"
                        color="primary"
                        className={buttonClassname}
                        type="submit"
                      >
                        {success ? <CheckIcon /> : <SaveIcon />}
                      </Fab>
                      {isSubmitting && (
                        <CircularProgress
                          size={68}
                          className={classes.fabProgress}
                        />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    onClick={() => onDelete()}
                    color="error"
                    variant="contained"
                    className="mx-1"
                    disabled={deleting}
                  >
                    <span className="btn-wrapper--label">Excluir</span>
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </WrapperSimple>
  );
}
