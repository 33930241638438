import * as React from 'react';
import {
  Button, Dialog
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from './loading';

interface OwnProps {
  message: string;
  open: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleAction: () => void;
}

export default function ConfirmDialog(props: OwnProps) {
  const {
    message, open, handleClose, handleAction, isLoading
  } = props;

  const onClose = (event: any, reason: any) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} disableEscapeKeyDown>
      <div className="text-center p-5">
        {isLoading ? <Loading height="130px" /> : (
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon
                icon={['fas', 'times']}
                className="d-flex align-self-center display-3"
              />
            </div>
          </div>
        )}
        <h4 className="font-weight-bold mt-4">
          {message}
        </h4>
        <p className="mb-0 font-size-lg text-muted">
          Esta operacão não poderá ser desfeita.
        </p>
        <div className="pt-4">
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            className="mx-1"
            disabled={isLoading}
          >
            <span className="btn-wrapper--label">Cancelar</span>
          </Button>
          <Button
            onClick={handleAction}
            color="primary"
            variant="contained"
            className="mx-1"
            disabled={isLoading}
          >
            <span className="btn-wrapper--label">Excluir</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
