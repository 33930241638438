import axios from 'axios';
import { add } from 'date-fns';

const apiPlacesMicroservice = axios.create({
  baseURL: process.env.REACT_APP_PLACES_MICROSERVICE_API_URL || 'https://boaztech-places-autocomplete.herokuapp.com'
});

export class BoazRemotePlacesService {
  static async getNewToken() {
    const clientKey = process.env.REACT_APP_PLACES_MICROSERVICE_API_KEY || 'A+gOa+K0yX3dOLpoqfU0LPIMrw8NIfHEBJWhwl1Hsqs=';
    if (!clientKey) return;
    const clientName = process.env.REACT_APP_PLACES_MICROSERVICE_CLIENT_NAME || 'remoteSystem';
    if (!clientName) return;

    const requestBody = {
      username: clientName,
      password: clientKey
    };
    // eslint-disable-next-line consistent-return
    return apiPlacesMicroservice.post('auth/token', requestBody).then((response) => {
      const currentDateTime = new Date();
      const result = {
        token: response.data.token,
        expiresAt: add(currentDateTime, { minutes: 120 })
      };
      return result;
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn(err);
      return null;
    });
  }
}

export default apiPlacesMicroservice;
