/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React from 'react';
import { ScaleLoader } from 'react-spinners';

interface OwnProps {
  height?: string;
}

export default function Loading(props: OwnProps) {
  const heightStyle = props.height === undefined ? '360px' : props.height;

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: heightStyle }}>
      <ScaleLoader color="var(--primary)" loading />
    </div>
  );
}
