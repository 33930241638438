/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import MUIDataTable, {
  debounceSearchRender, FilterType, MUIDataTableColumnDef, Responsive, SelectableRows
} from 'mui-datatables';
import {
  Button, CircularProgress, IconButton, Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

export interface MUIDataTableProps {
  columns: MUIDataTableColumnDef[];
  data: Array<object | number[] | string[]>;
  title: string;
  filter: boolean;
  // eslint-disable-next-line no-unused-vars
  onRowClick?: (rowData: string[], rowMeta: { dataIndex: number; rowIndex: number }) => void;
  onTableChange?: (action: string, tableState: any) => void;
  createUrl?: string;
  desactiveOptions?: boolean;
  pagination?: boolean;
  serverSide?: boolean;
  isLoading?: boolean;
  count?: number;
  getPrev?: () => void;
  getNext?: () => void;
  customFilterDialogFooter?: (currentFilterList: any, applyNewFilters: any) => void;
  onRowsDelete?: (rowsDeleted: any, newData: any) => void;
  customToolbarSelect?: (rowsDeleted: any, newData: any, setSelected: any) => void;
  onRowSelectionChange?: (currentRowsSelected: any, allRowsSelected: any, rowsSelected: any) => void;
  confirmFilters?: boolean;
  current?: number;
  currentTotal?: number;
  isEnd?: boolean;
  isStart?: boolean;
  enableSearch?: boolean;
  onlyTotal?: boolean;
  selectableRowsCondition?: any;
}

function table(props: MUIDataTableProps) {
  const {
    title,
    columns,
    data,
    createUrl,
    filter,
    pagination,
    count,
    serverSide,
    isLoading,
    confirmFilters,
    selectableRowsCondition,
    desactiveOptions,
    customToolbarSelect,
    onRowsDelete, onTableChange, onRowSelectionChange, onRowClick, getPrev, getNext, customFilterDialogFooter, onlyTotal, current, currentTotal, isEnd, isStart, enableSearch
  } = props;
  const shimmerRows = () => Array.from(Array(10).keys());
  const history = useHistory();

  const options = {
    tableBodyHeight: '550px',
    tableBodyMaxHeight: '',
    selectableRows: selectableRowsCondition || undefined,
    responsive: 'standard' as Responsive,
    filterType: 'dropdown' as FilterType,
    filter,
    pagination: pagination ?? true,
    serverSide: serverSide ?? false,
    confirmFilters,
    customFilterDialogFooter,
    print: false,
    download: false,
    viewColumns: false,
    rowsPerPage: 10,
    page: 1,
    rowsPerPageOptions: [10],
    count: count ?? undefined,
    customToolbar: () => {
      if (createUrl) {
        return (
          <IconButton style={{ order: -1 }} onClick={() => history.push(createUrl)}>
            <FontAwesomeIcon icon={['fas', 'plus-circle']} />
          </IconButton>
        );
      }
      return undefined;
    },
    customToolbarSelect,
    onRowSelectionChange,
    onRowClick,
    textLabels: {
      body: {
        noMatch: 'Nenhuma informação encontrada. \n 💡 Na pesquisa, digite no mínimo 3 caracteres 💡',
        toolTip: 'Ordenar',
        columnHeaderTooltip: (column: any) => `Ordenar por ${column.label}`
      },
      pagination: {
        next: 'Página posterior',
        previous: 'Página anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de'
      },
      toolbar: {
        search: 'Buscar',
        print: 'Imprimir',
        filterTable: 'Filtrar'
      },
      filter: {
        all: 'Todos',
        title: 'Filtros',
        reset: 'Limpar'
      }
    },
    customSearchRender: debounceSearchRender(500),
    onTableChange,
    onRowsDelete
  };

  return (
    <>
      <MUIDataTable
        title={(
          <Typography variant="h3">
            {title}
            {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
          </Typography>
        )}
        data={data ?? shimmerRows()}
        columns={columns}
        options={options}
      />
      {!pagination && (
        <>
          <div style={{
            display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 10, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10
          }}
          >
            {!onlyTotal
            && (
            <>
              <div style={{ marginTop: 10, paddingLeft: 10 }}>
                {current}
                {' '}
                -
                {' '}
                {currentTotal}
                {' '}
                -
                {' de '}
                {count}
              </div>
              <div>
                <IconButton style={{ order: -1 }} onClick={getPrev} disabled={isStart || enableSearch}>
                  <FontAwesomeIcon icon={['fas', 'angle-left']} />
                </IconButton>
                <IconButton style={{ order: -1 }} onClick={getNext} disabled={isEnd || enableSearch}>
                  <FontAwesomeIcon icon={['fas', 'angle-right']} />
                </IconButton>
              </div>
            </>
            )}

            {onlyTotal
              && (
              <div style={{ marginTop: 10, paddingLeft: 10 }}>
                {count}
                {' '}
                registro(s) encontrados.
              </div>
              )}
          </div>
        </>
      )}
    </>
  );
}

export default memo(table);
